import axios from "axios";

let request={};

request.deleteMediaBox= async (mediabox)=>{
    return await axios.post('/api/v2/permission/delete/mediabox',{
        mediabox: mediabox
    })
        .then(resp=>{
            return true;
        })
        .catch(err=>{
            return false;
        })
};

export default request;