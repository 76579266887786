import React from 'react';

const LeftArrowIcon= ({color, width, height, className = null,lineColor})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 28 28"
            className={className}
        >
            <path 
                fill={color}
                d="M0 8c0-4.418 3.582-8 8-8h12c4.418 0 8 3.582 8 8v12c0 4.418-3.582 8-8 8H8c-4.418 0-8-3.582-8-8V8z"
                opacity=".9"
            />
            <path 
                stroke="#fff" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="1.5" 
                d="M16 20l-5.8-5.293c-.39-.39-.39-1.024 0-1.414L16 8"
            />
        </svg>

    )
}
export default LeftArrowIcon;
