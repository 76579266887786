import React, { Component } from 'react';
import userIcon from "../../../../img/generic-user-icon.jpg";
import Donation from "../../../../components/Coin/NewDonation/Donation";
import CoinsIcon from '../../../../components/Svg/CoinsIcon';
import ModalRequireMedia from "../ChatSender/ModalRequireMedia";
import LeftArrowIcon from '../../../../components/Svg/LeftArrowIcon';
import Icon from "react-ionicons";

class ChatTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.onClickBack = this.onClickBack.bind(this);
    }

    onClickBack() {
        this.props.toElevate.showConversation(this.props.filterConversation);
    }

    componentDidMount() {}

    render() {
        let profileImg = this.props.image || userIcon;
        let profileName = this.props.name || "username";
        let btnDonateStyle = {
            backgroundColor: this.props.config.color.secondary,
        };
        let titleHeaderStyle = {
            borderBottomColor: this.props.config.color.secondary,
        };
        let txtStyle = {
            color: this.props.config.color.principalText
        };
        let btnBack = this.props.showBackBtn ? (
            <button
                onClick={this.onClickBack}
                className={"v2-chat-back-btn"}
            >
                <LeftArrowIcon
                    color={this.props.config.color.secondary}
                    width={"29px"}
                    height={"29px"}
                />
            </button>
        ) : (
            ""
        );
        let btnDonate = this.props.showDonateBtn ? (
            <button
                style={btnDonateStyle}
                className={"v2-chat-donate-btn"}
                onClick={e=>this.props.donate()}
            >
                <CoinsIcon
                    color={this.props.config.color.secondary}
                    colorPrincipal={this.props.config.color.principal}
                    width={"25px"}
                    height={"25px"}
                />
                <div
                    className="titleAction"
                    style={txtStyle}
                >
                    {this.props.dictionary.donate}
                </div>
            </button>
        ) : (
            ""
        );

        let reqMessages =
            this.props.showModule["audio-message-req"]
            || this.props.showModule["image-message-req"]
            || this.props.showModule["video-message-req"];
        let btnRequire = (!this.props.isModel && (reqMessages || this.props.showModule["media-messages"])) ?
            <button
                className={"v2-chat-require-btn"}
                onClick={(e) =>this.props.openRequireMedia(e)}
            >
                <Icon
                    icon="ios-images-outline"
                    fontSize="24px"
                    color={this.props.config.color.secondary}
                />
                <div
                    className="titleAction"
                    style={txtStyle}
                >
                    {this.props.dictionary.buy}
                </div>
            </button>
            : null;
        return (

            <div style={titleHeaderStyle} className={"v2-chat-title-container"}>
                <Donation
                    config={this.props.config}
                    dictionary={this.props.dictionary}
                    donate={this.props.donate}
                    showModalDonate={this.props.showModalDonate}
                    getCoins={this.props.toElevate.getCoins}
                    modalBuy={this.props.modalBuy}
                    coinstHandler={this.props.coinstHandler}
                    doDonation={this.props.doDonation}
                    type={"message"}
                    coins={this.props.coins}
                    modalBuyHandler={this.props.modalBuyHandler}
                />
                {btnRequire}
                {btnBack}
                {btnDonate}
                <img alt={"profileImage"} src={profileImg} className={"v2-image-usrname"}/>
                <div style={txtStyle} className={"v2-title-username"}>
                    {profileName}
                </div>
                {!this.props.isModel ?
                    <ModalRequireMedia
                        config={this.props.config}
                        dictionary={this.props.dictionary}
                        open={this.props.requireMedia}
                        close={this.props.closeRequireMedia}
                        showModule={this.props.showModule}
                        canSellCoins={this.props.canSellCoins}
                        typeMultimediaToBuy={this.props.typeMultimediaToBuy}
                        requireMedia={this.props.sendRequireMedia}
                        setRequestMedia={this.props.setRequestMedia}
                        pricesmodal={this.props.pricesmodal}
                    />
                    :
                    null
                }
            </div>
        );
    }
}

export default ChatTitle;
