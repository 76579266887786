import React from 'react';
import { Link } from 'react-router-dom';
//import LanguageModal from '../../../LanguageModal';
import MessagesIcon from '../../../Svg/MessagesIcon';

const HeadItemsLogged = ({logOut, logo, dictionary, config, toElevate}) => {
    let modalLogo;
    if(config.secondLogo){
        modalLogo = config.secondLogo;
    }
    else{
        modalLogo =  config.logo;
    }
    return(
        <div>
            <button
                title={dictionary.profile}
                className="loginMenu-btn"
            >
                <Link to="/messages">
                    <MessagesIcon
                        color="#fff"
                        width={"27px"}
                        height={"27px"}
                    />
                </Link>
            </button>
            <div className="navImgLogged">
                <img alt="navLogo" className="navLogo" src={config.storageURL+modalLogo}/>
            </div>
        </div>
    )
};
export default HeadItemsLogged;
