import React, { Component } from 'react';
import AudioPlayer from "react-h5-audio-player";
import { Button, Input, Col, ProgressBar, Preloader, Icon} from 'react-materialize';
import axios from 'axios';
import SideNav from '../../components/NavBar/SideNav';
import './style.css';
import cookie from 'js-cookie';
import piexif from 'piexifjs';
import Promise from 'bluebird';
import { Carousel } from 'react-responsive-carousel';
import DatePicker from "react-datepicker/es";
import moment from "moment";
// import RegisterModal from '../../components/RegisterModal';
import SubscribeNowButton from '../../components/Buttons/SubscribeNow';
import MediaBox from '../../components/Messages/MediaBox';
//import ModalSendMediabox from '../../components/Messages/ModalSendMediabox';
import ModalConfirm from  '../../components/ModalConfirm';

import request  from "../../components/General/request";

let tries = 0;

const axiosInstance = axios.create({
    baseURL: 'https://socialtechapps.com/'
});

axiosInstance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if(tries < 300) {
            //console.log(tries);
            axiosInstance.request(error.config);
        }
        else{
            //console.log("aqui la bloqueamos");
            alert("Too many petitions, Try later");
            window.location.reload(true);
        }
        tries++;
    }
);
class PublishPost extends Component {
    constructor(props){
        super(props);
        this.state = {
            description: null,
            uploadProgress: [],
            disabled: false,
            selectedFile: [],
            descriptionError:'',
            sectionError:'',
            postSection:[],
            section: null,
            newFile: 1,
            disconected: false,
            postIdIserted: null,
            alreadyUpload: [],
            filesToUpload : [],
            audiosToUpload : null,
            fileFeatures: [],
            publicPost: 0,
            filesUploading : 0,
            showMediaBox: false,
            mediaBox: [],
            pendingMediaBox: [],
            userSendMediaBox: [],
            mediaBoxResponse: null,
            storageImages:[],
            startDate : moment().add(1, 'hours').format("YYYY-MM-DDTHH:00"),
            programPost :false
        }
        this.fileChangedHandler=this.fileChangedHandler.bind(this);
        this.uploadHandler=this.uploadHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.showMediaBox = this.showMediaBox.bind(this);
        this.fileChangedHandlerStorage = this.fileChangedHandlerStorage.bind(this);
        this.makeMediaBoxPost = this.makeMediaBoxPost.bind(this);
    }
    componentDidMount(){
        this.getMediaBox();
        let interval = setInterval(()=>{
            if(this.state.postSection.length===0){
                this.getAllSection();
            }
            else{
                clearInterval(interval);
            }
        }, 2000);

    }
    setDate = async (date) => {
        this.setState({startDate : moment(date).format("YYYY-MM-DDTHH:mm")});
    }
    progamThisPost = async (e) => {
        this.setState({programPost : e.target.checked});
    }
    axiosMedia = (formData, index) => {
        try {
            //console.log("no", formData);
            return axiosInstance.post('/api/v2/post/media_2', formData, {
                    onUploadProgress: progressEvent =>
                    {
                        const uploadProgress = [...this.state.uploadProgress];
                        let progress= Math.floor((progressEvent.loaded / progressEvent.total)*100);
                        uploadProgress[index] = progress;
                        this.setState({uploadProgress : uploadProgress});
                    }

                })
                .then(async (resp)=>{
                    if(resp){
                        formData.delete('file');
                        formData.delete('postId');
                        return true;
                    }
                })
                .catch((err)=>{
                    axios.post('/api/v2/error/message/admin',{
                        error : err
                    });
                    this.setState({disconected: true});
                });
        }
        catch (e) {
            axios.post('/api/v2/error/message/admin',{
                error : e
            });
            this.setState({disconected: true});
            // return true;
        }
    }
    async rotateImage(file, index) {
        let reader = new FileReader();
        try{
            reader.onload = async (e) => {
                //console.log(e);
                let exif = piexif.load(e.target.result);
                let image = new Image();
                //console.log(image);
                image.onload = async () => {
                    var orientation = exif["0th"][piexif.ImageIFD.Orientation];

                    var canvas = document.getElementById("canvasToRotate" + index);
                    //console.log(canvas);
                    canvas.width = image.width;
                    canvas.height = image.height;
                    var ctx = canvas.getContext("2d");
                    var x = 0;
                    var y = 0;
                    ctx.save();
                    if (orientation === 2) {
                        x = -canvas.width;
                        ctx.scale(-1, 1);
                    } else if (orientation === 3) {
                        x = -canvas.width;
                        y = -canvas.height;
                        ctx.scale(-1, -1);
                    } else if (orientation === 4) {
                        y = -canvas.height;
                        ctx.scale(1, -1);
                    } else if (orientation === 5) {
                        canvas.width = image.height;
                        canvas.height = image.width;
                        ctx.translate(canvas.width, canvas.height / canvas.width);
                        ctx.rotate(Math.PI / 2);
                        y = -canvas.width;
                        ctx.scale(1, -1);
                    } else if (orientation === 6) {
                        canvas.width = image.height;
                        canvas.height = image.width;
                        ctx.translate(canvas.width, canvas.height / canvas.width);
                        ctx.rotate(Math.PI / 2);
                    } else if (orientation === 7) {
                        canvas.width = image.height;
                        canvas.height = image.width;
                        ctx.translate(canvas.width, canvas.height / canvas.width);
                        ctx.rotate(Math.PI / 2);
                        x = -canvas.height;
                        ctx.scale(-1, 1);
                    } else if (orientation === 8) {
                        canvas.width = image.height;
                        canvas.height = image.width;
                        ctx.translate(canvas.width, canvas.height / canvas.width);
                        ctx.rotate(Math.PI / 2);
                        x = -canvas.height;
                        y = -canvas.width;
                        ctx.scale(-1, -1);
                    }
                    await new Promise(resolve => {ctx.drawImage(image, x, y);});
                    //console.log("rinter")
                    ctx.restore();


                };

                await new Promise( resolve => {image.src = e.target.result;});
            };
            return await new Promise( async (resolve) => {
                await reader.readAsDataURL(file);
                setTimeout(()=>{
                    const canvas = document.getElementById("canvasToRotate" + index);

                    canvas.toBlob( async (blob) => {
                        return  resolve(blob)
                    });
                },1200);
            });

        }
        catch (e) {
            console.error(e);
            return file;
        }
    }
    getAllSection=async()=>{
        await axios.post('/api/v2/getAllSectionByModel')
        .then(async res => {
            if(res.data)
            {
                this.setState({postSection: res.data});
                if(res.data.length===1){
                    this.setState({section: res.data[0].idSection});
                }
                return res.data
            }
        })
        .catch(err => {
            console.log(err);
        });

    }
    subscribeNowButton() {
        return SubscribeNowButton
    }

    fileChangedHandler = async (event, index ) => {
        var error = document.getElementById('error');
        error.classList.add('hide');
        const fileFeatures=[...this.state.fileFeatures];
        const files = [...this.state.filesToUpload];
        const filesToUpload = event.target.files;
        await this.setState({filesUploading : filesToUpload.length})
        const uploadProgress = [...this.state.uploadProgress];
        this.setState({uploadProgress: uploadProgress});
        await new Promise.map( filesToUpload , async (file, i) => {
            let typeFormat = file ? this.validateFormat(file.type) : '';
            if(typeFormat==='video' || typeFormat==='image') {
                fileFeatures.push({
                    fileSize : file.size,
                    fileType: file.type
                });
                if (file.type.match('image/jpeg.*')) {
                    if(file.size > 10485760)
                    {
                        files.push(file);
                    }
                    else {
                        let blob = await this.rotateImage(file, i);
                        //dataURL = await URL.createObjectURL(blob)
                        files.push(blob)
                    }
                }
                else {
                    //var dataURL = await URL.createObjectURL(file);
                    files.push(file);
                }
            }
        });
        await this.setState({
            filesToUpload : files,
            fileFeatures : fileFeatures
        })
    }

    fileChangedHandlerAudio = async (event, index ) => {
      var error = document.getElementById('error');
      error.classList.add('hide');
      const file = event.target.files[0];
        if(!file){
            return false;
        }
      const uploadProgress = [...this.state.uploadProgress];
      uploadProgress[index]=0;
      await this.setState({uploadProgress: uploadProgress});
      let typeFormat = file ? this.validateFormat(file.type) : '';
      if(typeFormat==='audio') {
          await this.setState({
              audiosToUpload : file
          })
      }
      else{
           error.classList.remove('hide');
      }
    }
    changeHandler(e) {
        if(e.target.name === "section"){
            let currSection = this.state.section;
            if(currSection !== e.target.value && (currSection === "7" || e.target.value === "7")){
                this.setState({
                    filesToUpload : [],
                    audiosToUpload : null
                })
            }
        }
        this.setState({ [e.target.name]: e.target.value});
    }
    validateFormat(format){
        const mediaType = format.split('/')[0];
        if(mediaType === 'video' || mediaType === 'image' || mediaType ==='audio')
        {
            return mediaType;
        }
        else{
            return false
        }

    }
    deleteImgHandler = async(e,index) =>{
        e.preventDefault();
        const files = [...this.state.filesToUpload];
        const fileFeatures=[...this.state.fileFeatures];
        fileFeatures.splice(index,1);
        files.splice(index,1);
        this.setState({
            filesToUpload: files,
            fileFeatures: fileFeatures
        });
    }

    uploadHandler = async(e) => {
        try {
            e.preventDefault();
            this.setState({disconected: false});
            let error;
            if(!this.state.section){
                alert(this.props.dictionary.sectionEmpty);
                error = true;
            }
            if(!this.state.description){
                this.setState({descriptionError: this.props.dictionary.addDescription});
                error = true;
            }
            if(error)
            {
                return false;
            }
            this.setState({disabled: true});
            //if(navigator.onLine) {
                const alreadyUpload = [...this.state.alreadyUpload];
                if(!this.state.postIdIserted){
                    const postId = await this.makePost();
                    this.setState({postIdIserted : postId.postInserted});
                }
                let copyFiles = [...this.state.filesToUpload];
                let audio = this.state.audiosToUpload;
                if(audio !== null){
                    copyFiles.push(audio);
                    this.setState({
                        filesToUpload : copyFiles
                    })
                }

                if(copyFiles.length > 0){
                    await copyFiles.map(async (item, index) => {
                        await this.uploadFiles(item, index)
                            .then(async resp => {
                                if(resp){
                                    alreadyUpload.push(resp);
                                    this.setState(()=>{ return {alreadyUpload : alreadyUpload}});
                                    if(alreadyUpload.length===copyFiles.length){
                                        let resolver = await this.getSectionPost(this.state.section);
                                        const postId = this.state.postIdIserted;
                                        this.setState({postIdIserted: null});
                                        setTimeout(()=>{
                                            window.location = '/posts?section='+resolver+'&postProgress='+postId;
                                        }, 2000);
                                    }
                                }
                            });
                    });
                }
            // }
            // else{
            //     this.setState({disconected: true});
            // }
        }
        catch (e) {
            //console.error(e); // [Error: write after end]
            axios.post('/api/v2/error/message/admin',{
                error : e
            });
            this.setState({disconected: true});
        }
    }
    uploadFiles = async (item, index)=>{
        try {
            const formData = new FormData();
            formData.append('totalFilesImage', this.state.filesToUpload.length);
            formData.append('section', this.state.section);
            formData.append('idModel', cookie.get('username'));
            formData.append('hostname', window.location.hostname === 'localhost' ? 'myladydusha.com' :window.location.hostname);
            formData.append('token', cookie.get('token'));
            formData.append('file', item);
            formData.append('postId', this.state.postIdIserted);
            formData.append('description', this.state.description);
            formData.append('order', index)
            //'https://socialtechapps.com/api/v2/post/media'
            return this.axiosMedia(formData, index);
            // return axios.post('https://socialtechapps.com/api/v2/post/media_2', formData, {
            //     onUploadProgress: progressEvent =>
            //     {
            //         const uploadProgress = [...this.state.uploadProgress];
            //         let progress= Math.floor((progressEvent.loaded / progressEvent.total)*100);
            //         uploadProgress[index] = progress;
            //         this.setState({uploadProgress : uploadProgress});
            //     }

            // })
            // .then(async (resp)=>{
            //     formData.delete('file');
            //     formData.delete('postId');
            //     return true;
            // })
            // .catch((err)=>{
            //     console.log(err);
            //     this.setState({disconected: true});
            // })
        }
        catch (e) {
            //console.error(e); // [Error: write after end]
            axios.post('/api/v2/error/message/admin',{
                error : e
            });
            this.setState({disconected: true});
        }
    }
    getSectionPost=async (section)=>{
        const postSection = [...this.state.postSection];
        return new Promise((resolver) => {
             //QUIERO VER ESTO WE
            // console.log(postSection)
            const firstFind = postSection.find(({idSection}) => parseInt(idSection) === parseInt(section));
            return resolver(firstFind.name);
        })
    }

    renderObject(){
        if (!this.state.postSection.length) return ;
        const postSection = this.state.postSection;
        let items = [];
        items.push(<option key='-1' value='0'>{this.props.dictionary.seloption}</option>)
        postSection.map((item, index) => {
            return items.push(<option key={index} value={item.idSection}>{item.name}</option>)
        });
        return items;
    }
    renderFileFeatures(index){
        if(window.location.hostname === 'jailynefans.com'){
            return (
                <div>
                    <span className='file-features'><b>Size: {(this.state.fileFeatures[index].fileSize/1024/1024).toFixed(2)} Mb</b></span>
                    <span className='file-features2'><b>Format: {this.state.fileFeatures[index].fileType}</b></span>
                </div>
            )
        }

    }
    renderObjectMedia(){
        const files = [...this.state.filesToUpload];
        return files.map( (item, index) => {
            let type = item.type.split("/");

            if(type[0] === "image"){
                return(
                    <div key={index}>
                        <div className='z-depth-4'><img alt="" src={URL.createObjectURL(item)} className="responsive-img"/></div>
                        <Button className='delete-btn' onClick={(e) => this.deleteImgHandler(e,index)}>x</Button>
                        {this.renderFileFeatures(index)}
                    </div>
                )
            }
            else if(type[0] === "video"){
                return (
                    <div key={index} className='videoSlide'>
                        <video preload="yes"
                               className=" responsive-video" controls controlsList="nodownload">
                            <source
                                src={URL.createObjectURL(item)}
                                type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        <Button className='delete-btn' onClick={(e) => this.deleteImgHandler(e,index)}>x</Button>
                        {this.renderFileFeatures(index)}
                    </div>
                )
            }
            else{
                return null;
            }
        })

    }
    renderAudio(){
        const audio = this.state.audiosToUpload;
        return (
            <div>
                <AudioPlayer
                    src={URL.createObjectURL(audio)}
                    volume={0.4}

                />
            </div>
        )
    }
    renderObjectLoading(){
        const copyUploadProgress = [...this.state.uploadProgress];
        if(this.state.disabled){
            return copyUploadProgress.map( (item, index) =>{
                return(
                    <div key={index} className='center-align'>
                        <h4 style={{fontWeight:200}}>{this.props.dictionary.uploading}...</h4>
                        <ProgressBar className='uploadProgressBar' progress={parseFloat(item)}/>
                        <p style={{fontWeight:200}}>{item}%</p>
                        {this.state.disconected && item < 100 ? <Button
                            type="submit" waves='light' onClick={(e)=>this.uploadHandler(e,index)} >{this.props.dictionary.retry}</Button> : ''}
                    </div>
                    )
            });
        }
    }
    handleCheck(e) {
        let value;
        if(e.target.name === 'publicPost'){
            value = e.target.checked;
        }
        this.setState({
            [e.target.name] : value
        })
    }
    drawCanvas(size){
        let canvas = [];
        for(let i = 0; i < size; i++){
            canvas.push(<canvas id={"canvasToRotate" + i} className="hide"></canvas>)
        }
        return canvas;
    }

    makePost = async () =>{
        return await axios.post('/api/v2/create/post',
            {
                description: this.state.description,
                section: this.state.section,
                publicPost : this.state.publicPost ? '1' : '0',
                datePost : (this.state.programPost) ? moment(this.state.startDate).utc().format("YYYY-MM-DDTHH:mm") : null
            })
            .then(resp => {return resp.data});
    }

    //Mediabox
    getMediaBox = async () => {
        await axios.get('/api/v2/stored/media',
            {
                params: {
                    post: true
                }
            }
            ).then(async (resp) => {
            let mediaBox = resp.data;
            let pendingMediaBox = [];
            this.setState({ mediaBox: mediaBox });
            // eslint-disable-next-line array-callback-return
            mediaBox.map((el, index) => {
                if (!el.finished) {
                    el['position'] = index;
                    pendingMediaBox.push(el);
                }
            });
            this.setState({
                pendingMediaBox: pendingMediaBox
            })
        }).catch(err => {

        })
    }
    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }
    sendMediaBoxFile = async (e, id, deleteMedia=false) => {
        e.preventDefault();
        if (!id) {
            return false;
        }
        if(deleteMedia){
            await this.setStateAsync({ mediaBoxResponse: id, mediaBoxIndex: e.target.dataset.index });
            const canDelete = await request.deleteMediaBox(id);
            if (canDelete) document
                               .getElementById("showDeleteModal-post")
                               .click();
        }
        else{
            document.getElementById("showSendModal-post").click();
            await this.setStateAsync({ mediaBoxResponse: id, mediaBoxIndex: e.target.dataset.index });
        }
    };

    showMediaBox = async() => {
        await this.setStateAsync({ showMediaBox: !this.state.showMediaBox})
    };
    goBack = async () => {
        await this.setStateAsync({ showMediaBox: false });
    };
    fileChangedHandlerStorage = async (e) => {
        const files = e.target.files;
        await this.setState({ filesUploading: files.length })
        let storageImages = this.state.storageImages;
        await new Promise.map(files, async (file, i) => {
            let typeFormat = file ? this.validateFormat(file.type) : '';
            if (typeFormat === 'video' || typeFormat === 'image') {
                if (file.type.match('image/jpeg.*')) {
                    let blob = await this.rotateImage(file, i);
                    storageImages.push({ id: null, image: null, video: null, file: blob, progress: 0, uploading: false, finished: false, error: false, typeFormat: typeFormat })
                }
                else {
                    storageImages.push({ id: null, image: null, video: null, file: file, progress: 0, uploading: false, finished: false, error: false, typeFormat: typeFormat })
                }
            }
        }).then(async () => {
            await this.setState({
                storageImages: storageImages
            })
            if (storageImages.length > 0) {
                await this.uploadStorageImages();
            }
            return true;
        })
    }
    uploadStorageImages = async () => {
        let storageImages = [...this.state.storageImages];
        return await new Promise.map(storageImages, async (file, index) => {
            if (!file.uploading && !file.finished && !file.error) {
                const formData = new FormData();
                formData.append('user', cookie.get('username'));
                formData.append('hostname', window.location.hostname === 'localhost' ? 'private-social-media.appspot.com' : window.location.hostname);
                formData.append('token', cookie.get('token'));
                formData.append('file', file.file);
                storageImages[index]["uploading"] = true;
                await this.setStateAsync({ storageImages: storageImages });
                let resp = await new Promise(async (resolve) => {
                    let result = await this.axiosMediaStorage(formData, index);
                    return resolve(result)
                });
                if (resp) {
                    if (file.typeFormat === 'image') {
                        storageImages[index]['image'] = resp.file;
                        storageImages[index]['id'] = resp.stored;
                        storageImages[index]['finished'] = 1;
                    }
                    else if (file.typeFormat === 'video') {
                        storageImages[index]['video'] = resp.file;
                        storageImages[index]['image'] = resp.poster;
                        storageImages[index]['id'] = resp.stored;
                        storageImages[index]['finished'] = 1;
                    }

                }
                else {
                    storageImages[index]['error'] = 1;
                }

            }
        }).then(async () => {
            setTimeout(async()=>{
                await this.setStateAsync({ storageImages: storageImages });
            },1000);

            return true;
        });
    }
    axiosMediaStorage = async (formData, index) => {
        try {
            return await axios.post('https://socialtechapps.com/api/v2/message/media/storage', formData, {
                onUploadProgress: progressEvent => {
                    let storageImages = [...this.state.storageImages];
                    let progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
                    storageImages[index]["progress"] = progress;
                    this.setState({ storageImages: storageImages });
                }
            }).then(async (res) => {
                return res.data;
            }).catch(async (res) => {

                return false
            });
        }
        catch (e) {

        }
    };
    makeMediaBoxPost = async ()=>{
        if(!this.state.mediaBoxResponse) return null;

        let error;
        if(!this.state.section){
            alert(this.props.dictionary.sectionEmpty);
            error = true;
        }
        if(!this.state.description){
            this.setState({descriptionError: this.props.dictionary.addDescription});
            error = true;
        }
        if(error)
        {
            return false;
        }
        const postId = await this.makePost();
        //guardar post con los datos de mediabox

        await axios.post('/api/v2/create/post/media/mediabox', {
             mediabox:  this.state.mediaBoxResponse,
             postId : postId.postInserted
            })
            .then(resp =>{
                const mediaBox = [...this.state.mediaBox];
                if (this.state.mediaBoxIndex > -1) {
                    mediaBox.splice(this.state.mediaBoxIndex, 1);
                }
                this.setState({
                    mediaBox: mediaBox
                });
                this.getMediaBox();
                window.Materialize.toast(this.props.dictionary.successPost, 2000,'green rounded');

            })
            .catch(err=>{
                console.log(err);
            })
    };
    deleteImageMediabox = async (e) => {
        await axios.post('/api/v2/delete/mediabox', {
            mediaBox : this.state.mediaBoxResponse
        }).then( resp => {
            if(resp.data.Success){
                this.getMediaBox();
            }
        })
    }
    render() {
        let listItem = []
        if (this.state.showMediaBox) {
            listItem.push(
                <li key={0}>
                    <Button
                        className='btn-goback btn-goback-mediabox'
                        style={{
                            color: this.props.config.color.principalText
                        }}
                        onClick={this.goBack}>
                        <Icon>
                            arrow_back
                        </Icon>
                    </Button>
                </li>
            );
        }
        else{
            listItem.push(
                <li key={1}>
                    <Button
                        className="btn-broadcast" large
                        style={{
                            backgroundColor: this.props.config.color.secondary,
                            color: this.props.config.color.secondaryText
                        }}
                        onClick={this.showMediaBox}
                    >
                        Media Box
                </Button>
                </li>
            );
        }
        return (
            <div>
                <SideNav
                    toElevate={this.props.toElevate}
                    dictionary={this.props.dictionary}
                    config={this.props.config}
                />
                {this.drawCanvas(this.state.filesUploading)}
                <div className="container main-container full-min-container">
                    <ul className="media-options">{listItem}</ul>
                    <MediaBox
                        dictionary={this.props.dictionary}
                        config={this.props.config}
                        show={this.state.showMediaBox}
                        fileChangedHandlerStorage={
                            this.fileChangedHandlerStorage
                        }
                        toUpload={this.state.storageImages}
                        mediaBox={this.state.mediaBox}
                        sendMediaBoxFile={this.sendMediaBoxFile}
                    />
                    <ModalConfirm
                        id="ModalPublishMediaBox"
                        key={0}
                        dictionary={this.props.dictionary}
                        config={this.props.config}
                        // open={this.state.openModalMediaBox}
                        className="modal-large modal-min-container"
                        header={this.props.dictionary.newPost}
                        trigger={
                            <Button id="showSendModal-post" className="hide">
                                send
                            </Button>
                        }
                        bodyText={
                            <div>
                                <Input
                                    type="checkbox"
                                    label={this.props.dictionary.freePost}
                                    name="publicPost"
                                    checked={this.state.publicPost}
                                    onClick={this.handleCheck}
                                />
                                <Input
                                    s={12}
                                    type="select"
                                    label=""
                                    defaultValue="0"
                                    name="section"
                                    onChange={this.changeHandler}
                                >
                                    {this.renderObject()}
                                </Input>
                                <Input
                                    s={12}
                                    className="styledPlaceholder"
                                    type="textarea"
                                    name="description"
                                    placeholder={
                                        this.props.dictionary.description
                                    }
                                    maxLength="10000"
                                    onChange={this.changeHandler}
                                    error={this.state.descriptionError}
                                    disabled={this.state.disabled}
                                />
                                <Input
                                    type="checkbox"
                                    s={12}
                                    id="programPost"
                                    label={this.props.dictionary.schedulePost}
                                    checked={this.state.programPost}
                                    value={true}
                                    onChange={(e) => this.progamThisPost(e)}
                                />
                                {this.state.programPost ? (
                                    <Col
                                        s={12}
                                        className="datepicker-container"
                                    >
                                        <DatePicker
                                            s={12}
                                            selected={
                                                new Date(this.state.startDate)
                                            }
                                            onChange={(date) =>
                                                this.setDate(date)
                                            }
                                            showTimeSelect
                                            inline
                                            minDate={new Date()}
                                            timeFormat="HH:mm"
                                            timeIntervals={10}
                                            timeCaption="time"
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                        />
                                    </Col>
                                ) : null}
                            </div>
                        }
                        btnConfirm={
                            <Button
                                className="btn-radius"
                                style={{
                                    backgroundColor: this.props.config.color
                                        .secondary,
                                    color: this.props.config.color
                                        .secondaryText,
                                    margin: 10,
                                }}
                                small
                                modal="close"
                                onClick={this.makeMediaBoxPost}
                            >
                                <span className="close-streaming-options">
                                    {this.props.dictionary.publicPost}
                                </span>
                            </Button>
                        }
                        mediaBoxResponse={this.state.mediaBoxResponse}
                    />

                    <ModalConfirm
                        id="ModalDeleteMediaBox"
                        key={1}
                        dictionary={this.props.dictionary}
                        config={this.props.config}
                        header={this.props.dictionary.dltMedBoxFl}
                        bodyText=""
                        trigger={
                            <Button id="showDeleteModal-post" className="hide">
                                delete
                            </Button>
                        }
                        btnConfirm={
                            <Button
                                className="btn-radius"
                                style={{
                                    backgroundColor: this.props.config.color
                                        .secondary,
                                    color: this.props.config.color
                                        .secondaryText,
                                    margin: 10,
                                }}
                                small
                                modal="close"
                                onClick={(e) => {
                                    this.deleteImageMediabox();
                                }}
                            >
                                <Icon>delete</Icon>
                                <span className="close-streaming-options">
                                    {this.props.dictionary.delete}
                                </span>
                            </Button>
                        }
                        mediaBoxResponse={this.state.mediaBoxResponse}
                    />

                    <div
                        className={
                            !this.state.showMediaBox ? "center-align" : "hide"
                        }
                        style={{
                            paddingLeft: "auto",
                            paddingRight: "auto",
                            width: "100%",
                        }}
                    >
                        <Col
                            s={12}
                            className="card"
                            style={{
                                backgroundColor: this.props.config.color.modal,
                                color: this.props.config.color.modalText,
                                boxShadow:
                                    "3px 3px 7px 0px " +
                                    this.props.config.color.modal,
                            }}
                        >
                            <div className="card-head">
                                <h6 className="mainTitle">
                                    {this.props.dictionary.newPost}
                                </h6>
                            </div>
                            <div className="card-body">
                                <div
                                    id="postForm"
                                    className={
                                        this.state.disabled
                                            ? "hiddeElement"
                                            : ""
                                    }
                                >
                                    {!this.state.selectedFile ? (
                                        <div
                                            id="error"
                                            className="card-panel amber darken-1"
                                            style={{ marginTop: 20 }}
                                        >
                                            {this.props.dictionary.noBlockCel}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <Input
                                        type="checkbox"
                                        label={this.props.dictionary.freePost}
                                        name="publicPost"
                                        checked={this.state.publicPost}
                                        onClick={this.handleCheck}
                                    />
                                    {this.state.postSection.length > 1 ? (
                                        <Input
                                            s={12}
                                            type="select"
                                            label=""
                                            defaultValue="0"
                                            name="section"
                                            onChange={this.changeHandler}
                                        >
                                            {this.renderObject()}
                                        </Input>
                                    ) : !navigator.onLine ? (
                                        <div>
                                            <p style={{ color: "#c62828" }}>
                                                {
                                                    this.props.dictionary
                                                        .checkInternet
                                                }
                                            </p>
                                            <Preloader
                                                className="center-align"
                                                size="big"
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <Input
                                        className="styledPlaceholder"
                                        type="textarea"
                                        name="description"
                                        placeholder={
                                            this.props.dictionary.description
                                        }
                                        maxLength="10000"
                                        onChange={this.changeHandler}
                                        error={this.state.descriptionError}
                                        disabled={this.state.disabled}
                                    />
                                    <Col className="checkbox-datepicker">
                                        <Input
                                            type="checkbox"
                                            s={12}
                                            id="programPost"
                                            label={
                                                this.props.dictionary
                                                    .schedulePost
                                            }
                                            checked={this.state.programPost}
                                            value={true}
                                            onChange={(e) =>
                                                this.progamThisPost(e)
                                            }
                                        />
                                    </Col>
                                    {this.state.programPost ? (
                                        <Col
                                            s={12}
                                            className="datepicker-container"
                                        >
                                            <DatePicker
                                                s={12}
                                                selected={
                                                    new Date(
                                                        this.state.startDate
                                                    )
                                                }
                                                onChange={(date) =>
                                                    this.setDate(date)
                                                }
                                                showTimeSelect
                                                inline
                                                minDate={new Date()}
                                                timeFormat="HH:mm"
                                                timeIntervals={10}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                            />
                                        </Col>
                                    ) : null}
                                    <div className="preview">
                                        {this.state.filesToUpload.length ===
                                            0 &&
                                        this.state.audiosToUpload === null ? (
                                            <img
                                                src="https://storage.googleapis.com/private-social-media.appspot.com/icons/adjuntar.png"
                                                alt=""
                                                className="center-align"
                                            />
                                        ) : this.state.filesToUpload.length >
                                          0 ? (
                                            <Carousel
                                                className="sliderImg"
                                                showStatus={false}
                                                dynamicHeight={true}
                                                emulateTouch={true}
                                                showThumbs={false}
                                            >
                                                {this.renderObjectMedia()}
                                            </Carousel>
                                        ) : (
                                            this.renderAudio()
                                        )}
                                    </div>
                                    {this.state.section !== "7" ? (
                                        <div
                                            id="divFile0"
                                            className="mediaStl uploadModel"
                                        >
                                            <div className="col file-field">
                                                <div
                                                    className="btn publish-post"
                                                    style={{
                                                        backgroundColor: this
                                                            .props.config.color
                                                            .secondary,
                                                        color: this.props.config
                                                            .color
                                                            .secondaryText,
                                                    }}
                                                >
                                                    <span>
                                                        {
                                                            this.props
                                                                .dictionary
                                                                .addFile
                                                        }
                                                    </span>
                                                    <input
                                                        type="file"
                                                        id="file0"
                                                        name="file0"
                                                        multiple
                                                        onChange={(e) =>
                                                            this.fileChangedHandler(
                                                                e,
                                                                0
                                                            )
                                                        }
                                                        disabled={
                                                            this.state.disabled
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            id="divFile0"
                                            className="mediaStl uploadModel"
                                        >
                                            <div className="col file-field">
                                                <div
                                                    className="btn"
                                                    style={{
                                                        backgroundColor: this
                                                            .props.config.color
                                                            .secondary,
                                                        color: this.props.config
                                                            .color
                                                            .secondaryText,
                                                    }}
                                                >
                                                    <span>
                                                        {
                                                            this.props
                                                                .dictionary
                                                                .addAudio
                                                        }
                                                    </span>
                                                    <input
                                                        type="file"
                                                        name="file0"
                                                        multiple
                                                        onChange={(e) =>
                                                            this.fileChangedHandlerAudio(
                                                                e,
                                                                0
                                                            )
                                                        }
                                                        disabled={
                                                            this.state.disabled
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div
                                        id="error"
                                        className="hide card-panel red darken-3"
                                        style={{ marginTop: 20 }}
                                    >
                                        {this.props.dictionary.invalidFormat}
                                    </div>
                                </div>
                            </div>

                            {this.renderObjectLoading()}
                        </Col>
                        <Col s={12}>
                            <Button
                                disabled={this.state.disabled}
                                className="publish"
                                type="submit"
                                waves="light"
                                onClick={(e) => this.uploadHandler(e)}
                                style={{
                                    backgroundColor: this.props.config.color
                                        .third,
                                    color: this.props.config.color.thirdText,
                                }}
                            >
                                {this.props.dictionary.publicPost}
                            </Button>
                        </Col>
                    </div>
                </div>
            </div>
        );
  }
}

export default PublishPost;
