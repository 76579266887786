import React from 'react';
import UserIcon from '../../../Svg/UserIcon';
import { Link } from 'react-router-dom';

const HeadItems= ({config, logo, dictionary,modeLoginAgain,landingPage})=>{
    let modalLogo;
    if(config.secondLogo){
        modalLogo = config.secondLogo;
    }
    else{
        modalLogo =  config.logo;
    }
    return(
        <div>
            <button title={dictionary.login} className="loginMenu-btn">
                <Link
                    to={"/login"}
                    onClick={modeLoginAgain}
                >
                    <UserIcon
                        color="#fff"
                        width={"27px"}
                        height={"27px"}
                    />
                </Link>
            </button>
            {landingPage === 2 ?
                <div
                    style = {{
                        backgroundImage: 'url(' + config.storageURL + config.principalImage + ')'
                    }}
                    className='imgPrincipalBackground'
                    id="imgPrincipalBackground"
                >
                </div>
            : 
                <div className="navImg">
                    <img alt="navLogo" className="navLogo" src={config.storageURL+modalLogo}/>
                </div>
            }
        </div>
    )
}
export default HeadItems;
