import React, { Component } from 'react';
import NavBar from "../../components/NavBar/SideNav";
import MediaBox from "../../components/MediaBox";
import {isMobile} from 'react-device-detect';
import './style.css';

class NewFeed extends Component{
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render(){
        return(
            <div className="redesign" style={
                {
                    backgroundColor : this.props.config.color.navbar
                }
            }>
                <NavBar
                    toElevate={this.props.toElevate}
                    dictionary={this.props.dictionary}
                    config={this.props.config}
                    view={this.props.view ? this.props.view : false}
                    notification={ this.props.streaming ? {link : "/streaming", message: "Model is streaming NOW!"} : null}
                />
                <div
                    className="container main-container media-box"
                    style={{
                        backgroundColor : this.props.config.color.principal,
                        color: this.props.config.color.principalText
                    }}
                >
                    <MediaBox
                        dictionary={this.props.dictionary}
                        config={this.props.config}
                        isMobile={isMobile}
                    />
                </div>
            </div>
        )
    }
}

export default NewFeed;
