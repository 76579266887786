import React, { Component } from 'react';
import Icon from "react-ionicons";
import FullscreenIcon from '../../../../Svg/FullscreenIcon';
import "./CarouselElement";
import AudioPlayer from "react-h5-audio-player";
class CarouselElement extends Component{
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        const video = document.querySelector('video');
        if(video){
            video.controls = "";
            video.addEventListener('pause', e => {
                video.controls = "";
            });
        }
    }

    handleImageLoaded() {
        this.props.imageStatus();
    }

    renderMedia(){
        let media = this.props.media;
        let config = this.props.config;
        let view = this.props.view;
        let post = this.props.post;
        let index = this.props.index;
        let img = (view) ?
            (media.mediaBox) ?
                config.storageURL + '/' + config.storage + "/message/model/" + media.post_media_img
                :
                config.storageURL + '/' + config.storage + "/posts/img/" + media.post_media_img
            :
            media.post_media_img;
        let video = (media.mediaBox) ?
            config.storageURL + '/' + config.storage + "/message/model/" + media.post_media_video
            :
            config.storageURL + '/' + config.storage + "/posts/video/" + media.post_media_video;
        let audio = config.storageURL + '/' + config.storage + "/posts/audio/" + media.post_media_audio;
        if(media.post_media_video !== null && this.props.length === 1) {
            if (media.post_media_video === "loading") {
                return (
                    <img
                        alt="post"
                        src={"https://storage.googleapis.com/private-social-media.appspot.com/icons/SD-default-image.png"}
                        className={"responsive-image no-select"}
                        onLoad={this.handleImageLoaded.bind(this)}
                    />
                )
            }
            this.props.clicVideo[index] = React.createRef();
            return(
                <video
                    ref={this.props.clicVideo[index]}
                    preload="metadata"
                    poster={img}
                    className="responsive-video carouselVideo no-select"
                    onLoadStart={this.handleImageLoaded.bind(this)}
                    controlsList="nodownload"
                    playsInline>
                    <source
                        src={video}
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
            )
        }
        else if(media.post_media_img !== null) {
            if (media.post_media_img === "loading"){
                return(
                    <img
                        alt="post"
                        src={"https://storage.googleapis.com/private-social-media.appspot.com/icons/SD-default-image.png"}
                        className={"responsive-image no-select"}
                        onLoad={this.handleImageLoaded.bind(this)}
                    />
                )
            }
            this.props.openViewerImage[index] = React.createRef();
            return(
                <img
                    ref={this.props.openViewerImage[index]}
                    alt="post"
                    data-lazy={img}
                    src={null}
                    onLoad={this.handleImageLoaded.bind(this)}
                    className="responsive-img"
                />
            )
            
        }
        else if(media.post_media_audio !== null) {
            return(
                <div className="audio">
                    <AudioPlayer
                        src={audio}
                        volume={0.5}
                    />
                </div>
            )
        }
    }

    render(){
        let media = this.props.media;
        let post = this.props.post;
        let index = this.props.index;
        return(
            <div>
                {this.renderMedia()}
                {media.post_media_video !== null ?
                    <Icon
                        icon="md-arrow-dropright-circle"
                        fontSize="4em"
                        color="rgba(255,255,255,0.5)"
                        className="play-video"
                    />
                    : null
                }
                {this.props.length === 1 && media.post_media_video ?
                    <FullscreenIcon 
                        color="rgba(255,255,255,0.7)" 
                        width="1.2em" 
                        height="1.2em" 
                        id="fullscreenBtn"
                        className="expand"
                        clickFunction={(e) => {this.props.openViewer(e, post, index, true)} }
                    />
                    :
                    null
                }
            </div>
        )
    }
}

export default CarouselElement;
