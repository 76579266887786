import React from 'react';
import {
    Link
} from 'react-router-dom';
const SubscribeNow = ({config, id, href, titulo}) => {
    return (
        <Link
            style={{
                padding:10, marginTop:0,
                backgroundColor:config.color.secondary,
                color: config.color.secondaryText
            }}
            id={id}
            className="subcribe-now-button z-depth-5"
            waves='light'
            to={'/join' + href}
        >
            {titulo}
        </Link>
    )
};
export default SubscribeNow;