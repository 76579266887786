import React, {Component} from "react";
import Icon from "react-ionicons";
import './style.css';

class ModalCustom extends Component{
    render() {
        let navbar = document.getElementsByClassName("styles_content__1Phom");
        if(navbar && this.props.open)
            navbar[0].style.zIndex = 0;
        let id = (this.props.id) ? this.props.id : "CustomModal";
        let body = (this.props.children) ? this.props.children : "";
        let open = this.props.open;
        // eslint-disable-next-line
        let head = (this.props.head) ? this.props.head : <h3></h3>
        let footer = (this.props.footer) ? this.props.footer : <ul></ul>
        let back = (this.props.config) ? this.props.config.color.modal : "auto";
        let color = (this.props.config) ? this.props.config.color.modalText : "auto";
        let close = (this.props.config) ? this.props.config.color.navbar : "auto";
        let closeAction = (this.props.config) ? this.props.config.color.navbarText : "auto";
        return(
            <div
                id={id}
                className={(open) ? "open custom-modal" : "custom-modal"}
            >
                <div
                    className="custom-modal-content"
                    style={{backgroundColor : back, color : color}}
                >
                    <span
                        className="close"
                        style={{backgroundColor: close, color : closeAction}}
                        onClick={(this.props.closeAction) ? this.props.closeAction : null}
                    >
                        <Icon
                            icon="md-close"
                            color={closeAction}
                            fontSize="1.5em"
                        />
                    </span>
                    <div
                        className="custom-modal-head"
                    >

                        {head}
                    </div>
                    <div
                        className="custom-modal-body"
                    >
                        {body}
                    </div>
                    <div
                        className="custom-modal-foot"
                    >
                        {footer}
                    </div>
                </div>
            </div>
        )
    }
}
export default ModalCustom;
