import React, { Component } from 'react';
import axios from 'axios';
import { Circle } from 'react-preloaders';
import { Link } from 'react-router-dom';
import getParams from '../../SearchParams/SearchParams';
import CameraIcon from '../../Svg/CameraIcon';
import LikeIcon from '../../Svg/LikeIcon';
import VideoIcon from '../../Svg/VideoIcon';
import LockIcon from '../../Svg/LockIcon';
//import CertifiedIcon from '../../Svg/CertifiedIcon';
import './styleThree.css';

class HomeThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promo: '',
            membershipData: [],
            benefits: true,
            loading: true,
            blurVideo: '',
            blurImg: [],
            images: 0,
            videos: 0,
            likes: 0,
            rating: false,
            ratingDesc: false,
        };
        this.formatDate = this.formatDate.bind(this);
    }

    componentDidMount() {
        this.getBlurMedia()
            .then(data => {
                const video = data.videoModel || [{ blurred: '' }];
                this.setState({
                    blurVideo: video.length ? video[0].blurred : [{ blurred: '' }],
                    blurImg: data.imgModel || [],
                });
            });
        const params = getParams();
        const promo = params.promo;
        if (promo)
            this.setState({promo: '/?promo=' + promo});

        if(this.props.rating)
            this.setState({rating: this.props.rating.rate, ratingDesc: this.props.rating.descriptionRating});

        this.getCounts()
            .then(counts => {
                if (counts.length > 0)
                    this.setState({
                        images: counts[0].images,
                        videos: counts[0].videos,
                        likes: counts[0].likes,
                    });
            })
            .catch(err => console.log(err));
        
        axios.post('/api/v2/membershipInfo', {
            promo: promo
        }).then(res => {
            this.setState({
                membershipData: res.data
            });
        });

        

        
    };

    getCounts = async () => {
        return await axios.get('/api/v2/get/home/counts')
            .then(async res => {
                return res.data;
            })
            .catch(err => {
                console.log(err);
                return false
            });
    };

    periodMembership = (period, type) => {
        if (period === "week" && type === "normal") {
            return this.props.dictionary.weekly;
        } else if (period === "week" && type === "promotion") {
            return ("1 " + this.props.dictionary.week);
        } else if (period === "month" && type === "normal") {
            return this.props.dictionary.monthly;
        } else if (period === "month" && type === "promotion") {
            return ("1 " + this.props.dictionary.month);
        } else if (period === "year" && type === "normal") {
            return this.props.dictionary.yearly;
        } else if (period === "year" && type === "promotion") {
            return ("1 " + this.props.dictionary.year);
        } else if (period === "day") {
            return this.props.dictionary.per24Hours;
        } else if (period === "2 day") {
            return this.props.dictionary.per48Hours;
        } else if (period === "3 day") {
            return this.props.dictionary.per3days;
        } else if (period === "30 day") {
            return this.props.dictionary.per30days;
        }
        else if (period === "30 day") {
            return this.props.dictionary.per30days;
        }
    };

    getBlurMedia = async () => {
        return await axios.get('/api/v2/get/home/media')
            .then(async res => {
                return (res.status === 200)
                    ? res.data
                    : {};
            })
            .catch(err => {
                console.log(err);
                return {};
            });
    };

    kFormatter = (num) => {
        return Math.abs(num) > 999
            ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(0)) + 'k'
            : Math.sign(num) * Math.abs(num);
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    renderSuscribe() {
        if (this.state.membershipData.length === 0)
            return null;

        if (this.state.membershipData.length > 1) {
            let principalmembership = this.state.membershipData[0].price
                + " " + this.state.membershipData[0].currency
                + " " + this.periodMembership(this.state.membershipData[0].payment_period, this.state.membershipData[0].typeMembership);
            let promoMembership = this.state.membershipData[1].price
                + " " + this.state.membershipData[1].currency
                + " " + this.periodMembership(this.state.membershipData[1].payment_period, this.state.membershipData[1].typeMembership);
            return (
                <div>
                    <div className="promo-label">
                        {this.props.dictionary.promo}
                    </div>
                    { this.props.statusModel !== 2  ? 
                        <button
                            style={{
                                backgroundColor: this.props.config.color.secondary,
                                color: this.props.config.color.secondaryText
                            }}
                            className="promoData homeSubscribe-btn subcribe-now-button"
                        >
                            <Link
                                to={"/register"}
                                style={{
                                    color: this.props.config.color.secondaryText
                                }}
                            >
                                {"$" + promoMembership}
                            </Link>
                        </button>
                    : null }
                    <div className="promo-well">
                        {this.props.dictionary.or}...
                    </div>
                    { this.props.statusModel !== 2  ? 
                        <button
                            style={{
                                marginBottom: 10,
                                backgroundColor: this.props.config.color.secondary,
                                color: this.props.config.color.secondaryText
                            }}
                            className="homeSubscribe-btn subcribe-now-button"
                        >
                            <Link
                                to={"/register"}
                                style={{
                                    color: this.props.config.color.secondaryText
                                }}
                            >
                                {"$" + principalmembership}
                            </Link>
                        </button>
                    : null }
                </div>
            );
        } else {
            let principalmembership = this.state.membershipData[0].price
                + " " + this.state.membershipData[0].currency
                + " " + this.periodMembership(this.state.membershipData[0].payment_period, this.state.membershipData[0].typeMembership);
            return (
                ( this.props.statusModel !== 2  ? 
                    <button
                        style={{
                            marginBottom: 10,
                            backgroundColor: this.props.config.color.secondary,
                            color: this.props.config.color.secondaryText
                        }}
                        className="homeSubscribe-btn subcribe-now-button"
                    >
                        <Link
                            to={"/register"}
                            style={{
                                color: this.props.config.color.secondaryText
                            }}
                        >
                            {"$" + principalmembership}
                        </Link>
                    </button>
                : null )
            );
        }
    }

    renderTopImages() {
        if (this.state.blurImg.length === 0)
            return null;
        const images = this.state.blurImg.map((item, index) => {
            return <div key={index} className='gridGallery'>
                <LockIcon
                    color={"#fff"}
                    width={"20px"}
                    height={"20px"}
                    className="lockSVG"
                />
                <img alt="streamx"
                    src={item.blurred}
                    className="responsive-img corners-nologin" />
            </div>;
        });

        return (<div>
            <h5 className="galleryText">
                {this.props.dictionary.galery}
            </h5>
            <div className="flex-same-height" style={{ paddingBottom: 30 }}>
                {images}
            </div>
        </div>
        );
    }
    benefist=()=>{
        if(window.location.hostname === "rogelioramosvip.com"){
            return (
                <div>
                    <p className="checkmark-text">
                        &#10004; &nbsp; Especial de comedia “La risa debe Continuar“
                    </p>
                        <p className="checkmark-text">
                            &#10004; &nbsp; Meet & Greet con Rogelio Ramos al visitar tu ciudad.
                    </p>
                        <p className="checkmark-text">
                            &#10004; &nbsp; Acceso a contenido Premium.
                    </p>
                        <p className="checkmark-text">
                            &#10004; &nbsp; "En la intimidad con Rogelio Ramos” conoce las más divertidas historias de Rogelio Ramos.
                    </p>
                        <p className="checkmark-text">
                            &#10004; &nbsp; Acceso a eBook "Hasta que el divorcio nos separe" (PRÓXIMAMENTE).
                    </p>
                        <p className="checkmark-text">
                            &#10004; &nbsp; y muchas sorpresas más...
                    </p>
                </div>
            );
        }
        else if (window.location.hostname === "carotrueba28.com"){
            return (
                <div>
                    <p className="checkmark-text">
                        &#10004; &nbsp; Rutinas de entrenamiento mensuales
                    </p>
                    <p>
                        &#10004; &nbsp; Resuelve tus dudas por chat personalizado
                    </p>
                    <p>
                        &#10004; &nbsp; Motivación diaria con contenido exclusivo
                    </p>
                </div>
            )
        }
        else{
            return (
                <div>
                    <p className="checkmark-text">
                        &#10004; &nbsp; {this.props.dictionary.benefist[1]}.
                    </p>
                    <p className="checkmark-text">
                        &#10004; &nbsp; {this.props.dictionary.benefist[2] + ' ' + this.props.config.nameTitle}.
                    </p>
                    <p className="checkmark-text">
                        &#10004; &nbsp; {this.props.dictionary.benefist[3]}.
                    </p>
                </div>
            );
        }
    }
    styleActiveSection = (show) =>{
        if(show){
            return {
                background: this.props.config.color.secondary,
                color: this.props.config.color.secondaryText
            }
        }
        return {};
    };
    rating=()=>{
        /*if(this.state.rating){
            return (
                <div className="rates">
                    <h6 className="title-benefits">
                        {this.props.dictionary.contentRating}
                    </h6>
                    <div className="rate-content">
                        <div>
                            <span>{this.props.dictionary.type}: </span>
                            {this.state.rating === 1 ? 
                                "Sexy"
                            : this.state.rating === 2 ?
                                this.props.dictionary.erotic
                            : this.state.rating === 3 ?
                                this.props.dictionary.explicit
                            : null }
                        </div>
                        <div>
                            <span>{this.props.dictionary.feed}: </span> 
                            <h6>
                                {this.state.ratingDesc ? 
                                    this.state.ratingDesc 
                                : this.props.dictionary.retingDesc["desc" + this.state.rating]}
                            </h6>
                        </div>  
                    </div>
                    <div className="certified">
                        {this.props.dictionary.certifiedBy}  <a 
                            style={{color: this.props.config.color.secondary}} 
                            href="https://teampeach.app/"
                        >
                            TeamPeach 🍑
                        </a>
                    </div>
                </div>
            );
        }else{*/
        if(this.state.rating){

            return (
                <div className="bottom-space-benefits">
                    <h6 className="title-benefits">
                        {this.props.dictionary.contentRating}
                    </h6>
                    <div className="secciones">

                        <div
                            className={this.state.rating === 1 ? "selected": ""}
                            style={ this.styleActiveSection(this.state.rating === 1)}
                        >
                            Sexy 🔥
                        </div>
                        <div
                            className={this.state.rating === 2 ?  "selected": ""}
                            style={ this.styleActiveSection(this.state.rating === 2)}
                        >
                            {this.props.dictionary.erotic} 🔥🔥
                        </div>
                        <div
                            className={this.state.rating === 3 ? "selected" : ""}
                            style={ this.styleActiveSection(this.state.rating === 3)}
                        >
                            {this.props.dictionary.explicit} 🔥🔥🔥
                        </div>

                    </div>
                </div>

            );
        }
        return null;
    }
    render() {
        if (this.props.dictionary.length === 0 || this.props.config.length === 0)
            return <div className="center-align" style={{ paddingTop: 45 }}>
                    <Circle color="#4285f4" className="center-align" />
                   </div>;
        return (
            <div
                className="container main-container center-align "
                style={{
                    zIndex: 0,
                    backgroundColor: this.props.config.color.principal,
                    color: this.props.config.color.principalText
                }}
            >
                <p className="profile-modelName">
                    {this.props.config.nameTitle}
                </p>
                {/*<CertifiedIcon
                    color={this.props.config.color.secondary}
                    width={"30px"}
                    height={"30px"}
                    className="certified-icon"
                />*/}

                {this.state.images > 20 && this.state.videos > 20 && this.state.likes >= 100 ?
                    <ul className="count-info">
                        <li>
                            <CameraIcon
                                color={this.props.config.color.secondary}
                                width={"20px"}
                                height={"20px"}
                            />
                            <div>
                                {this.state.images > 999 ?
                                    this.kFormatter(this.state.images)
                                    : this.state.images}
                            </div>
                        </li>
                        <li>
                            <LikeIcon
                                color={this.props.config.color.secondary}
                                width={"20px"}
                                height={"20px"}
                            />
                            <div>
                                {this.state.likes > 999 ?
                                    this.kFormatter(this.state.likes)
                                    : this.state.likes}
                            </div>
                        </li>
                        <li>
                            <VideoIcon
                                color={this.props.config.color.secondary}
                                width={"20px"}
                                height={"20px"}
                            />
                            <div>
                                {this.state.videos > 999 ?
                                    this.kFormatter(this.state.videos)
                                    : this.state.videos}
                            </div>
                        </li>
                    </ul>
                : null }

                <div className="register-options">
                    <Link
                        to={"/login"}
                        style={{
                            color: this.props.config.color.thirdText
                        }}
                    >
                        <button
                            style={{
                                marginBottom: 20,
                                backgroundColor: this.props.config.color.third,
                                color: this.props.config.color.secondaryText
                            }}
                            className="subcribe-now-button login-btn"
                        >

                            {this.props.dictionary.login}

                        </button>
                    </Link>
                    <Link
                        to={"/register"}
                        style={{
                            color: this.props.config.color.secondaryText
                        }}
                    >
                        <button
                            style={{
                                paddingTop: 0,
                                backgroundColor: this.props.config.color.secondary,
                                color: this.props.config.color.secondaryText
                            }}
                            className="subcribe-now-button"
                        >

                            {this.props.dictionary.subscribeButton}

                        </button>
                    </Link>
                </div>
                { this.props.description ?
                    <p className="description-container">
                        {this.props.description}
                    </p>
                :
                    <p className="benefit-text">
                        {this.props.dictionary.joinMeNow[0]}
                        <br/>
                        {this.props.dictionary.joinMeNow[1]}
                    </p>
                }
                
                <h6 className="title-benefits">
                    {this.props.dictionary.subsBenefits}
                </h6>
                <div className="benefits-contain">
                    {window.location.hostname === "adrianamaciasapp.com" &&
                    <>
                        <p className="checkmark-text">
                            &#10004; &nbsp; Serie: “Lo que no sabías de mí”
                        </p>
                        <p>
                            &#10004; &nbsp; Meditaciones guiadas
                        </p>
                    </>
                    }
                    {this.benefist()}
                </div>

                {this.rating()}
                {this.state.blurVideo ?
                    <div className="videoGallery intro-video-container" style={{ borderRadius: 8 }}>
                        <LockIcon
                            color={"#fff"}
                            width={"20px"}
                            height={"20px"}
                            className="lockSVG"
                        />
                        <button className="play-customBtn">
                            <VideoIcon
                                color={"#fff"}
                                width={"50px"}
                                height={"50px"}
                            />
                        </button>
                        {this.renderSuscribe()}
                        <p className="premium-c">
                            {this.props.dictionary.contPremiumEmph}
                        </p>
                        <img
                            src={this.state.blurVideo}
                            className=" responsive-img corners-nologin"
                            alt="blur video"
                        />
                    </div>
                    : null}
                {this.renderTopImages()}
            </div>
        );
    }
}

export default HomeThree;
