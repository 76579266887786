import React, { Component } from 'react';
import axios from "axios";
import Promise from "bluebird";
import { Link } from 'react-router-dom';
import { Circle } from 'react-preloaders';
import Tabs from "../Tabs";
import MediaViewer from '../MediaViewer';
import toolbox from "../Toolbox/Toolbox";
import PurchaseBoxElement from "./PurchaseBoxElement";
import './style.css';

class MediaBox extends Component{
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            option: 1,
            files : [],
            purchases: [],
            mediaBox : [],
            loading: false,
            defaultOpen : 0,
            mediaOpened : [],
            totalMedia: null,
            blockLoad: false,
            filesUploading : 0,
            openViewer : false,
            mediaEditing : null,
            mediaIndexEditing : null, 
            options: [
                {
                    option: 1,
                    name: props.dictionary.images
                },
                {
                    option: 2,
                    name: props.dictionary.videoModule
                },
                {
                    option: 3,
                    name: props.dictionary.audioModule
                }
            ]
        }
        this.getPurchases = this.getPurchases.bind(this);
        this.getTotalPurchases = this.getTotalPurchases.bind(this);
    }

    componentDidMount() {
        this.getPurchases(this.state.offset)
        .then(bought => {
            this.setState({
                purchases: bought,
                loading: false, 
                offset: 12
            });
        }).catch(err => {
            toolbox.sendError(err);
        });
        this.getTotalPurchases();
        document.addEventListener("scroll", this.trackScrolling);
    }

    openViewer = async(e, media, index) => {
        let sidenav= document.getElementsByClassName("styles_content__1Phom"); //put sidenav and notifymodal behind
        if(sidenav!== null && sidenav !== undefined){
            sidenav[0].style.zIndex = 0;
        }
        await this.setState({
            openViewer: true,
            defaultOpen : index,
            mediaOpened : [media]
        });
    }

    closeViewer = async () => {
        let sidenav= document.getElementsByClassName("styles_content__1Phom");
        if(sidenav!== null && sidenav !== undefined){
            sidenav[0].style.zIndex = 1;
        }
        this.setState({
            mediaOpened : [],
            openViewer: false,
        });
    }

    playAudio = async (e) => {
        const audios = document.getElementsByClassName("audio-purchased");
        var inputList = Array.prototype.slice.call(audios);
        let selected = e.target.parentNode;
        let parent= (selected.tagName === "svg") ? selected.parentNode : selected;
        let audio = parent.nextSibling;
        let iconPlay  = (selected.tagName === "svg") ? selected : selected.firstChild;
        inputList.forEach((item, index) => {  
            if(!item.paused && (audio.paused !== item.paused)){
                item.pause();
                item.previousSibling.firstChild.classList.remove("playing");
                item.previousSibling.classList.remove("animate-audio1");
            }
        });
        if(audio.paused) {
            audio.play();
            
            iconPlay.classList.add("playing");
            parent.classList.add("animate-audio1");
        }
        else{
            audio.pause();
            iconPlay.classList.remove("playing");
            parent.classList.remove("animate-audio1");
        }
        audio.addEventListener("ended", function(){
            audio.pause();
            iconPlay.classList.remove("playing");
            parent.classList.remove("animate-audio1");
        });   
    }
                
    playVideo = async (e) => {
        let video = e.target;
        if(video.paused) {
            video.play();
            video.nextSibling.classList.add("playing");
        }
        else{
            video.pause();
            video.nextSibling.classList.remove("playing");
        }
    }

    getTotalPurchases = async () =>{
        return await axios.post('/api/v2/purchases/media/total')
        .then(resp => {
            this.setState({totalMedia: resp.data.count});
        }).catch(err => {
            toolbox.sendError(err);
        });
    }
    getPurchases = async (numOffset ) => {
        this.setState({loading: true});
        let data = { 
            typeMedia : this.state.option,
            offset: numOffset
        };
        return await axios.post('/api/v2/purchases/media', data)
        .then(resp => {
            return resp.data;
        }).catch(err => {
            toolbox.sendError(err);
        });
    }

    getMorePurchases = async (noMoreOffset = false) => {
        let offset = noMoreOffset ? 0 : this.state.offset;
        if (!this.state.loading) {
            this.setState({loading: true});
            let purchases     = await this.getPurchases(offset);
            let copyPurchases = [...this.state.purchases];
            let newPurchases  = [];
            if (noMoreOffset) newPurchases = purchases;
            else newPurchases = copyPurchases.concat(purchases);
            this.setState({
                loading: false,
                purchases: newPurchases,
                offset: noMoreOffset ? offset : offset + 12
            });
        }
    }

    trackScrolling = (e) => {
        const wrappedElement = document.querySelector('.main-container');
        if (wrappedElement && toolbox.isBottom(wrappedElement)) {
            this.getMorePurchases();
        }
    }

    drawPurchases(media) {
        if (!media)
        {
            if(!this.state.loading){
                return (
                    <div className="wo-content">
                        {this.props.dictionary.noPurchases}
                    </div>
                );
            }
        } else {
            let config = this.props.config;
            let dictionary = this.props.dictionary;
            return media.map( (el, index) => {
                return(
                    <PurchaseBoxElement
                        media={el}
                        key={index}
                        index={index}
                        config={config}
                        dictionary={dictionary}
                        playVideo={this.playVideo}
                        playAudio={this.playAudio}
                        openViewer={this.openViewer}
                    />
                )
            })
        }
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    changeTab = async(tab) => {
        if (this.state.loading) return false;
        await this.setStateAsync({ 
            option: tab, 
            offset: 0, 
            purchases: []
        });
        if(tab===1 || tab===2 || tab===3){
            this.getPurchases(this.state.offset)
            .then(async purchases => {
            await this.setState({purchases: purchases, loading: false, offset: 12 });
            }).catch(err => {
                toolbox.sendError(err);
            });
            document.addEventListener("scroll", this.trackScrolling);
        }
    }

    render(){
        let media = this.state.purchases ? this.state.purchases : null ;
        let loading = this.state.loading
            ? <div className="center-align">
                <Circle 
                    background={"transparent !important"} 
                    color={this.props.config.color.secondary}
                />
              </div>
            : null;
        return(
            <div>
                <div className="purchases">
                    {this.state.totalMedia > 0 ?
                        <div className="purchase-options">
                            <Tabs
                                tab={this.state.option}
                                tabs={this.state.options}
                                changeTab={this.changeTab}
                                config={this.props.config}
                            />
                        </div>
                    : null }
                    {this.props.view || (!this.props.view && media.length > 0) ?
                            <div>
                                {this.drawPurchases(media)}
                                {loading}
                                {this.state.openViewer ? 
                                    <MediaViewer
                                        config={this.props.config}
                                        open={this.state.openViewer}
                                        media={this.state.mediaOpened}
                                        closeAction={this.closeViewer}
                                        defaultOpen={this.state.defaultOpen}
                                    />
                                : null}
                            </div>
                    : (media.length <= 0 && this.state.loading) ?
                        loading
                    :
                        <Link
                            style={{
                                backgroundColor:this.props.config.color.secondary,
                                color: this.props.config.color.secondaryText
                            }}
                            className="link-membership subcribe-now-button"
                            to={'/membership'}
                        >
                            {this.props.dictionary.getToBuy}
                        </Link>
                    } 
                </div>  
            </div>
        )
    }
}

export default MediaBox;
