import React, {Component} from 'react';
//import { isMobile } from 'react-device-detect';
import Modal from "../../../Modal"
import axios from 'axios';
import "./style.css"
//import Promise from "bluebird";

class DeleteModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            model : false
        }
    }
    deleteMessage = async (all = false) => {
        let message = this.props.messageToDelete.msg;
        await axios.post('/api/v2/message/delete', {message : message, all : all})
            .then( (res) => {
                this.props.deleteMessage(message, all);
                this.props.close()
            })
    }
    render() {
        return(
            <Modal
                id={ "modalDeleteMessage" }
                head={null}
                config={this.props.config}
                footer={
                    null
                }
                closeAction={() => this.props.close()}
                open={this.props.open}
            >
                <ul className="delete-option-button">
                    <li>
                        <button
                            onClick={() => this.deleteMessage()}
                            style={{
                                backgroundColor : this.props.config.color.secondary,
                                color : this.props.config.color.secondaryText
                            }}
                        >
                            {this.props.dictionary.deleteForMe}
                        </button>
                    </li>
                    {this.props.messageToDelete && this.props.messageToDelete.model ?
                        <li>
                            <button
                                onClick={() => this.deleteMessage(true)}
                                style={{
                                    backgroundColor : this.props.config.color.secondary,
                                    color : this.props.config.color.secondaryText
                                }}
                            >
                                {this.props.dictionary.deleteForAll}
                            </button>
                        </li>
                        :
                        null
                    }
                </ul>
            </Modal>
        );
    }
}
export default DeleteModal;
