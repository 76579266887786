import React, { Component } from 'react';
import Modal from "../../ModalPortal/ModalPortal";
import './ModalSaveStreaming.css';
class Streaming extends Component {
    constructor(props){
        super(props);
        this.state={
            save: true,
            section: 0,
            description: '',
        }
        this.handleCheck = this.handleCheck.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.saveSuccess = this.saveSuccess.bind(this);
    }
    componentDidMount(){
        const description = this.props.title;
        this.setState({
            description : description,
        });
    }
    handleCheck(e) {
        let value = e.target.checked;
        this.setState({
            [e.target.name] : value
        });
    }
    
    changeHandler(e) {
        if(e.target.name === "save"){
            e.target.value=(e.target.value === "true");
        }
        this.setState({[e.target.name]: e.target.value});
    }

    handleSelect=(e)=>{
        let save =  (e.target.value === 'true');
        this.setState({save: save});
    }
 
    renderSections(){
        if (!this.props.sections && !this.props.sections.length) return ;
        const postSection = this.props.sections;
        let items = [];
        postSection.map((item, index) => {
            if(item.idSection!==7){
                return items.push(
                    <p className="p" key={index}>
                        <input
                            onChange={this.changeHandler}
                            name="section"
                            value={item.idSection}
                            type="radio"
                            id={item.idSection} 
                            className="input__radius"
                            disabled={!this.state.save}
                            style={{checked:'red'}}
                            />
                        <label for={item.idSection}>{item.sectionName}</label>
                    </p>
                );
            }
            return item;
        });
        return items;
    }

    saveSuccess(e){
        this.props.stopCamera(
            this.state.description,
            this.state.section,
            this.state.save
        )
        e.preventDefault(); 
    }

    render(){
        let disabled = (!(this.state.save && this.state.description.trim() !== '' && this.state.section !== 0));
        return(
            <Modal
                show={this.props.show}
                onCancel={this.props.onCancel}
                config={this.props.config}
                header={this.props.title}
                footer={this.props.footer}
                blackModal={this.props.blackModal}
            >
                <div className='center-align'>
                    <h3  className="save__exit__title">{this.props.dictionary.saveOrExit}</h3>
                    <p className="save__exit__description">  {this.props.dictionary.DescriptionModalExit}</p>
                </div>
                    <div>
                       <div className='center-align' >
                           <label className="custom-select">
                                <select 
                                    name="save" 
                                    className="save__select" 
                                    value={this.state.save}  
                                    onChange={e=>{this.handleSelect(e)}}
                                    style={{background:this.props.blackModal?this.props.config.color.third:this.props.config.color.modal, color: this.props.blackModal?this.props.config.color.secondaryText:this.props.config.principalText,  boderStyle:'solid',borderWidth:'1px', borderColor:this.props.config.color.secondary}}
                                >
                                    <option value={true}  >{this.props.dictionary['save&exit']}</option>
                                    <option value={false}  >{this.props.dictionary.exitWithoutSave}</option>
                                </select>
                            </label>
                        </div>
                            <div className="sections__render border__solid__1" style={{borderColor:this.props.config.color.sideBar}}>
                                 <input
                                    s={12}
                                    className="save__input__description"
                                    type="textarea"
                                    name="description"
                                    defaultValue={this.state.description}
                                    placeholder={this.props.dictionary.description}
                                    maxLength="10000"
                                    onChange={this.changeHandler}
                                    error={this.props.descriptionError}
                                    style={{background:this.props.blackModal?this.props.config.color.third:this.props.config.color.modal, color: this.props.blackModal?this.props.config.color.secondaryText:this.props.config.principalText,  boderStyle:'solid',borderWidth:'1px', borderColor:this.props.config.color.secondary}}
                                    disabled={!this.state.save}
                                />

                                <h6 className="select__section">Select a section</h6>
                                {this.renderSections()}

                            </div>           
                            <div className='sections__render'>
                                {this.state.save?
                                    <button
                                        onClick={this.saveSuccess}
                                    className="save__btn__done"
                                    style={{background:disabled?'rgba(204, 204, 204, 0.7)':this.props.config.color.secondary, color:this.props.config.color.secondaryText}}
                                    disabled={disabled}
                                    >
                                        {this.props.dictionary.agree}
                                    </button>
                                    :
                                    <button  className="save__btn__done"
                                        style={{background:this.props.config.color.secondary, color:this.props.config.color.secondaryText}}>
                                        {this.props.dictionary.exit}
                                    </button>
                                }
                            </div>
                        </div>
            </Modal>
        )
    }
}
export default Streaming;