import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CarouselElement from "./CarouselElement";
import './style.css'

class CarouselObj extends Component{
    constructor(props) {
        super();
        this.clickVideo = [];
        this.openViewerImage = [];
        this.viewerKeys=[];
    }

    componentDidMount() {
    }

    playVideo = async (e) => {
        let video = e.target || e.current;
        if(video){
            let playingVideo = document.querySelector(".video-playing");
            if(playingVideo && playingVideo!==video){
                playingVideo.pause();
                playingVideo.nextSibling.classList.remove("playing");
                playingVideo.classList.remove("video-playing");
            }

            if(video.paused) {
                video.play();
                video.classList.add("video-playing");
                video.nextSibling.classList.add("playing");
            }
            else{
                video.pause();
                video.classList.remove("video-playing");
                video.nextSibling.classList.remove("playing");
            }
        }
        return false;
    }

    playVideoRef(index, post){
        if(this.viewerKeys.findIndex((element) => element === index) >=0 )
            this.props.openViewer(null, post, index);
        else
            this.playVideo(this.clickVideo[index]);
    }


    drawMedia(){
        let media = [...this.props.media];
        if(!media || !media.length) return ;
        let length = media.length;
        let view = this.props.view;
        return media.map((item, index) => {
            this.clickVideo.push(index);
            this.openViewerImage.push(index);
            if((!item.post_media_video && !item.post_media_audio) || media.length>1 ){
                this.viewerKeys.push(index);
            }

            return (
                <>
                <CarouselElement
                    media={item}
                    length={length}
                    config={this.props.config}
                    view={view}
                    playVideo={this.playVideo}
                    key={index}
                    index={index}
                    post={this.props.post}
                    openViewer={this.props.openViewer}
                    isSafari={this.props.isSafari}
                    isIOS={this.props.isIOS}
                    imageStatus={this.props.imageStatus}
                    clicVideo={this.clickVideo}
                    openViewerImage={this.openViewerImage}
                />
                <div
                    className="media-element-coat"
                    onClick={()=>{this.playVideoRef(index, this.props.post)}}
                >
                </div>
                </>
            )
        });
    }

    render(){
        let media = [...this.props.media];
        return(
            (media.length > 1) ?
                <div>
                    <Carousel
                        showStatus={false}
                        dynamicHeight={false}
                        emulateTouch={true}
                        showThumbs={false}
                        selectedItem={this.props.selectedItem}
                    // onClickItem={()=>this.renderLightBox(item.media)}
                    >
                        {this.drawMedia()}
                    </Carousel>
                </div>
                :
                <div className="only">
                    {this.drawMedia()}
                </div>
        )
    }
}

export default CarouselObj;
