import React from 'react';
import {Dropdown, Preloader, ProgressBar} from 'react-materialize';
import { Icon } from 'react-materialize';

import "./style.css";
const MediaBox = (props) => {
    const {
        config,
        show,
        fileChangedHandlerStorage,
        toUpload,
        mediaBox,
        sendMediaBoxFile
    } = props;
    let drawImages = (images) => {

        if(!images)
            return null;

        return images.map( (el, index) => {
            if(el.error){
                return false;
            }
            return(

                <div key={index}
                     data-index={index}
                    className='grid-post multimedia-mediaBox'
                >
                    <div className='menu-grid-mediabox'>
                        <Dropdown
                            trigger={
                                <a href="#edit-post"
                                   style={{color: props.config.color.modalText}}
                                >
                                    <Icon>more_horizontal</Icon>
                                </a>
                            }
                        >
                       <div
                           onClick={(e) => {sendMediaBoxFile(e, el.id)}}
                           style={{backgroundColor: props.config.color.modal, color: props.config.color.modalText}}
                       >
                           {props.dictionary.send}
                       </div>
                        <div
                            onClick={(e) => {sendMediaBoxFile(e, el.id, true)}}
                            style={{backgroundColor: props.config.color.modal, color: props.config.color.modalText}}
                        >
                            {props.dictionary.delete}
                        </div>
                        </Dropdown>
                    </div>
                    {!el.finished ?
                        <Preloader size='big' flashing='true'/>
                        :
                        el.typeFormat === 'video' ?
                            <video preload="yes"
                                className=" responsive-video"
                                poster={config.storageURL + '/' + config.storage + '/message/model/' + el.image}
                                controls
                                controlsList="nodownload">
                                <source
                                    src={config.storageURL + '/' + config.storage + '/message/model/' + el.video}
                                    type="video/mp4"/>

                            </video>
                            : <img src={config.storageURL + '/' + config.storage + '/message/model/' + el.image} alt=""/>
                    }
                    {(el.progress !== undefined && el.progress < 100)
                        ? <ProgressBar
                            className='uploadProgressBar progressMessage progressMediaBox'
                            progress={parseFloat(el.progress)}/>
                        : null
                    }
                </div>
            )
        })
    };

    return (
        show
        ? <>
            <div key={1}
                className='grid-post'
            >
                <input type="file" name="fileStorage" id="fileStorage" multiple onChange={(e) => {fileChangedHandlerStorage(e)}} />
                <Icon className="icon-grid">add_circle_outline</Icon>
            </div>
            {drawImages(toUpload)}
            {drawImages(mediaBox)}
        </>
        : null
    )
};
export default MediaBox;