import React, { Component } from 'react';
import Carousel from './Carousel'
import './style.css'
import moment from "moment";
import cookie from "js-cookie";
import axios from "axios";
import ShowMore from 'react-show-more';
import LikeIcon from '../../Svg/LikeIcon';
import CoinsIcon from '../../Svg/CoinsIcon';
import ManyImgIcon from '../../Svg/ManyImgIcon';
import MoreIcon from '../../Svg/MoreIcon';
import DropdownList from "../../DropdownList";
import {Link} from "react-router-dom";
import UsersDonatedPost from '../../Coin/UsersDonatedPost';
import { ThreeDots, Preloader } from "react-preloader-icon";
import Ionicon from 'react-ionicons';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

class Post extends Component{
    constructor(props) {
        super();
        this.state = {
            handler : true,
            permission : props.permission,
            imageStatus: true,
            audio:false
        }
    }

    componentDidMount() {
        setTimeout(this.loadLazy, 120);
    }

    validateLoading = () => {
        this.setState({
            imageStatus: false
        });
    }

    loadLazy() {
        let elems = document.querySelectorAll('[data-lazy]');
        if(elems.length > 0){
            elems.forEach((elem, index) => {
                let lazy = elem.getAttribute('data-lazy')
                if(elem.tagName === 'VIDEO')
                    elem.poster = lazy;
                else
                    elem.src = lazy;

                elem.removeAttribute('data-lazy');
            });
        }
    }

    addLike(post)
    {
        if(!post.alreadyLike)
        {
            this.likePost(post.id_post);
            post.likes+=1;
            post.alreadyLike=1;
        }
        else {
            this.unlikePost(post.id_post);
            post.likes-=1;
            post.alreadyLike=null;
        }
        this.setState({handler : !this.state.handler})
    }

    likePost=async(postId)=>{
        const dataLike ={
            username: cookie.get('username'),
            postId: postId
        }
        return await  axios.post('/api/v2/likePost',dataLike)
            .then(async res => {
                return res.data;

            })
            .catch(err => {
                throw Error(err);
            });
    }
    unlikePost=async(postId)=>{
        const dataLike ={
            username: cookie.get('username'),
            postId: postId
        }
        return await  axios.post('/api/v2/dislikePost',dataLike)
            .then(async res => {
                return res.data;

            })
            .catch(err => {
                throw Error(err);
            });
    }

    /**
     *
     * @param number
     * @returns {string}
     */
    formatNumber(number) {
        if(number < 1000){
            return number.toString();
        }
        if(number < 1000000){
            return Math.floor((number / 1000) * 10) / 10 + "K"
        }
        return Math.floor((number / 1000000) * 10) / 10 + "M"
    }
    renderBestUsers = (users) => {
        if(users.length > 0){
            return users.map((item, index) => {
                return (
                    <span key={index} className="donator-span">
                        {item.user} {index < (users.length-1) ? "," : ""}&nbsp;
                    </span>
                )
            })
        }
    }
    showDonators = async () => {
        await this.setState({showModal : true, modalAction : "donators"});
    }
    closeModalDonators = async () => {
        this.setState({showModal : false})
    }
    processingDot(processing, text=null) {
        if (processing===2){
            return (
                <div>
                    <span className='error-post'>{this.props.dictionary.errorPost}</span>
                </div>
            );
        }
        return (
            <div className='loading-dot-text'>
                <span>{text || ''}</span>
                <Preloader
                    use={ThreeDots}
                    size={40}
                    strokeWidth={6}
                    strokeColor={this.props.config.color.secundary}
                    duration={2000}
                />
            </div>
        );
    }

    dropDownMenu(processing, post ,index){
        if ((this.state.permission === "1" || this.state.permission === "4") && processing!==2){
            return (
                <DropdownList
                    items={[
                        [
                            this.props.dictionary.editPost,
                            "#",
                            "",
                            "",
                            () => this.props.edit(post, index),
                        ],
                        [
                            post.post_free
                                ? this.props.dictionary.removeFreePost
                                : this.props.dictionary.makeFreePost,
                            "#",
                            "",
                            "",
                            () => this.props.security(post, index),
                        ],
                        [
                            this.props.dictionary.delPost,
                            "#",
                            "",
                            "",
                            () => this.props.delete(post, index),
                        ],
                    ]}
                    trigger={
                        <MoreIcon
                            color={"#fff"}
                            width={"20px"}
                            height={"20px"}
                            className="more-post"
                        />
                    }
                />
            );
        }
        else if(processing===2) {
            return(
                <div className='delete-post-btn' onClick={()=>this.props.retry(post, index)}>
                    <span>
                        <Ionicon
                            icon="md-refresh"
                            fontSize="38px"
                            color="#ba5151"
                        />
                    </span>
                </div>
            );
        }

    }

    placeHolder(){
        let post = this.props.post;
        return(
            <div>
            <div className="post">
                <div className="postHead">
                        <div style={{ "margin-top": "1.5em"}}>
                            <ReactPlaceholder type='round' showLoadingAnimation={true} ready={false} color='#E0E0E0' style={{ width: 30, height: 30 }}>
                            </ReactPlaceholder>
                        </div>
                        <div style={{"left": "3.9em", "right": "0.27em","top": "1.5em" , "position": "absolute", "width":"250px"}}>
                            <ReactPlaceholder type='textRow'  showLoadingAnimation={true}  ready={false} color='#E0E0E0'>
                            </ReactPlaceholder>
                        </div>
                        
                        <div style={{"right": "0.55em", "top": "1.5em" , "position": "absolute"}}>
                            <ReactPlaceholder type='round' showLoadingAnimation={true} ready={false} color='#E0E0E0' style={{ width: 20, height: 20 }}>
                            </ReactPlaceholder>
                        </div>     
                </div>
                <div className={post.processing === 2 ? "post-body post-error" : "post-body"}>
                    <div style={{"display": "flex", "justify-content": "center", "margin-top": "10px"}}>
                        <ReactPlaceholder showLoadingAnimation={true} type='rect'  style={{ width: 450, height: 250}}>
                        </ReactPlaceholder>
                    </div>
                </div>
                <div className="postHead">
                        <div style={{ "margin-top": "0.8em"}}>
                            <ReactPlaceholder type='round' showLoadingAnimation={true} ready={false} color='#E0E0E0' style={{ width: 25, height: 25 }}>
                            </ReactPlaceholder>
                        </div>    
                        <div style={{"right": "0.55em", "top": "22.8em" , "position": "absolute"}}>
                            <ReactPlaceholder type='round' showLoadingAnimation={true} ready={false} color='#E0E0E0' style={{ width: 25, height: 25 }}>
                            </ReactPlaceholder>
                        </div>     
                </div>
                <div>
                    <ReactPlaceholder type='textRow'  showLoadingAnimation={true}  ready={false} color='#E0E0E0'>
                    </ReactPlaceholder>
                </div>
            </div>
            </div>
        );
    }

    render(){
        let post = this.props.post;
        let media = this.props.post.media;
        let model = this.props.model;
        let config = this.props.config;
        let view = this.props.view;
        let index = this.props.index;
        let showDonation = this.props.showDonation;
        for(let i = 0; i < media.length; i++){
            this.state.audio = media[i].post_media_audio != null;
        }
        return (
            <div>
                {
                    this.state.imageStatus &&  !this.state.audio ? this.placeHolder() : null
                }
                <div className="post" key={index} id={"post-" + index}>
                    <p className="postHead">
                        <img
                            src={
                                model.modelImage
                                    ? config.storageURL +
                                    "/" +
                                    config.storage +
                                    "/profiles/" +
                                    model.modelImage
                                    : config.storageURL + config.principalImage
                            }
                            alt="profile"
                        />
                        {!post.processing
                            ? model.modelname
                            : this.processingDot(post.processing, this.props.dictionary.processing)
                        }
                        {this.state.permission !== "1" ? (
                            <Link
                                to={"/messages/?post=" + post.id_post}
                                className="reply-icon"
                            >
                                <Ionicon
                                    icon="ios-undo-outline"
                                    fontSize="22px"
                                    color={config.color.secondary}
                                    className="shadowLike"
                                />
                            </Link>
                        ) 
                        : null}
                    </p>
                    <div className={post.processing === 2 ? "post-body post-error" : "post-body"}>
                        {media && media.length > 1 ? (
                            <ManyImgIcon
                                color={"#fff"}
                                width={"21px"}
                                height={"21px"}
                                className="photos-post"
                            />
                        ) : null}
                        {this.dropDownMenu(post.processing, post, index)}
                        <Carousel
                            media={media}
                            config={config}
                            view={view || post.post_free}
                            post={this.props.index}
                            openViewer={this.props.openViewer}
                            selectedItem={post.default ? post.default : null}
                            isSafari={this.props.isSafari}
                            isIOS={this.props.isIOS}
                            imageStatus={this.validateLoading}
                        />
                    </div>

                    <div className="actions-container">
                        <span
                            className="like-post"
                            style={{ color: config.color.principalText }}
                            onClick={() => this.addLike(post)}
                        >
                            <LikeIcon
                                width={"19px"}
                                height={"19px"}
                                color={
                                    post.alreadyLike
                                        ? config.color.secondary
                                        : "transparent"
                                }
                                colorStroke={config.color.secondary}
                                widthStroke={post.alreadyLike ? 0 : 1}
                                className={
                                    post.alreadyLike
                                        ? "shadowLikeAlready"
                                        : "shadowLike"
                                }
                            />
                            <div
                                className="shadowNumber"
                                // style={{
                                //     color: post.alreadyLike
                                //         ? config.color.secondary
                                //         : "",
                                // }}
                            >
                                {this.formatNumber(post.likes ? post.likes : "")}
                            </div>
                        </span>

                        {post.created_time ? (
                            <span className="time-posted">{moment(post.created_time).fromNow()}</span>
                        ) : null}
                        
                        {showDonation && this.state.permission !== "1" ? (
                            <span
                                className="donate-post"
                                style={{ color: config.color.principalText }}
                                onClick={() => this.props.donate(post, index)}
                            >
                                <CoinsIcon
                                    width={"21px"}
                                    height={"21px"}
                                    color={config.color.secondary}
                                    colorPrincipal={"#ffc2b2"}
                                    className={"shadowNumber"}
                                />
                                <div className="shadowNumber number-span-coins">
                                    {this.formatNumber(
                                        post.donations ? post.donations : ""
                                    )}
                                </div>
                            </span>
                        ) :
                            <span
                                className="model-coins donate-post"
                                style={{ color: config.color.principalText }}
                            >
                                <CoinsIcon
                                    width={"21px"}
                                    height={"21px"}
                                    color={config.color.secondary}
                                    colorPrincipal={"#ffc2b2"}
                                    className={"shadowNumber"}
                                />
                                <div className="shadowNumber number-span-coins">
                                    {this.formatNumber(
                                        post.donations ? post.donations : ""
                                    )}
                                </div>
                            </span>
                        }
                        {post.bestUsers && post.bestUsers.length && this.state.permission === "1" ?
                            <div
                                className="v2-best-users"
                                onClick={() => this.showDonators()}
                            >
                                {this.renderBestUsers(post.bestUsers.slice(0,3))}
                            </div>
                        : null }
                    </div>

                    <div
                        className={
                            this.state.permission !== "1"
                                ? "content content-action"
                                : "content"
                        }
                    >
                        <div className="showmore-post">
                            <ShowMore
                                lines={3}
                                more={this.props.dictionary.seemore}
                                less={this.props.dictionary.seeless}
                            >
                                {post.post_name}
                            </ShowMore>
                        </div>
                    </div>
                    {this.state.showModal ?
                        <UsersDonatedPost
                            index={index}
                            dictionary={this.props.dictionary}
                            config={this.props.config}
                            users={post.bestUsers}
                            showModal={this.state.showModal}
                            close={this.closeModalDonators}
                            modalAction={this.state.modalAction}
                        />
                    : null }
                </div>
            </div>
            
        );
    }
}

export default Post;
