import React from 'react';

const PrivacyEnglish = ({hostname}) =>{
    return(
        <div>
            <h3 className='center'>Privacy Policy</h3>
            <p>
                Welcome to our privacy policy (the "Privacy Policy"). We respect the privacy of our members and users of {hostname} (the “Site”) service. We have created this Privacy Policy to inform you as to how we collect, store, and use data resulting from your use of Site and any of its services. Our services are not intended for anyone under 18 without the direct supervision of a parent, as further described in Section IV herein. We do not knowingly offer our services to, or otherwise collect, personal information from anyone under 18, or anyone using a false identity. This Privacy Policy is an addition to our Terms of Service (the "Terms"), and together with the Terms controls your relationship with Site, and the Terms are expressly incorporated herein by this reference. This Privacy Policy and any dispute concerning it, shall be governed by the dispute resolution procedures contained within the Terms.
            </p>
            <p>
                This Privacy Policy may be updated from time to time, though it is current as of the "Last Updated" date listed at the top of this Privacy Policy. We will let you know anytime we make material changes to this Privacy Policy. Any changes will be made according to the modification provisions listed below. Having said that, you should review this policy periodically.
            </p>
            <p><b>I. Personally Identifiable Information ("PII") We Collect and Use</b></p>
            <p>
                In using the Site and/or its services, you acknowledge and agree that we collect PII as outlined below when you create an account on the Site. PII is provided by you and collected when you use this Site, purchase a subscription, or sell a subscription. Site does not utilize or share any PII with third parties except as herein described.
            </p>
            <p>
                The Site may include links to third-party websites. Except as set forth herein, we do not share any of your PII with them. The Site does not control, endorse, or supervise these third-party sites and such sites may collect PII when you click an external link or visit a third-party website. This Privacy Policy does not govern your interaction with any third-party site, and you should consult with the third-party website regarding their privacy policies and terms of service.
            </p>
            <p>
                We collect the following information: (1) information you voluntarily give to us, (2) information we collect when you use our services, and (3) information we may obtain from third parties as part of your use of the Site. Additionally, in limited circumstances, we may also ask for government identification.
            </p>
            <p>
                In order to open an account and use our services, you will disclose to us: (1) your name, (2) home address, (3) email address, (4) your date of birth, (5) a valid, unexpired copy of your passport or other verified government ID (upon our request only), and (6) banking or payment information to facilitate your subscription.
            </p>
            <p>
                We reserve the right to ask for additional information that may be publicly visible, such as a profile picture, profile name, or other identifying information.
            </p>
            <p>
                <b>II. How We Use Your PII</b>
            </p>
            <p>
                Your PII is used by us internally, and allows us to facilitate transactions and to provide and deliver you access to your profile, content, and subscriptions of your choosing. It also allows us to contact you and answer any requests or comments you may have.
            </p>
            <p>
                We also use PII internally to create, develop, and improve our fraud monitoring and loss prevention policies. We also use your PII internally for audits and analysis to keep the Site services running as smoothly as possible.
            </p>
            <p>
                The Site may use the PII you provide in conjunction with third parties that are under contract with Site such as our payment processors and vendors who ensure you have access to your subscriptions, content, and profile. Site only provides your PII to the extent necessary to ensure you are provided with these services. Site never provides your PII to third parties for marketing or advertising purposes.
            </p>
            <p>
                Similarly, we use PII, including your date of birth, to ensure you are of suitable age to access the site and the various content available through profile owners on the Site.
            </p>
            <p>
                In limited circumstances, we may also use your PII to contact you and give you important notices, including but not limited to fraud warnings, changes to the Terms and/or this Privacy Policy. While you may opt out of some communications from us , you may not opt out of communications the relate to changes to the Terms or this Privacy Policy, as these form the basis of your relationship with us and govern our interactions.
            </p>
            <p>
                If you are a profile owner, some of the PII you provide may be searchable to subscribers and users on the Site. Searchable PII will be limited to username or first and/or last name.
            </p>
            <p>
                We may create anonymous data ("Anonymous Data") logs (logs that exclude PII, such as details about how you have used our services, device information, such as your web browser type and language, access times, pages viewed, and IP address (defined below)) to help us conduct analysis that helps us monitor and improve the Site. We reserve all rights to use Anonymous Data for any purpose, and to disclose Anonymous Data to third parties at our sole discretion.
            </p>
            <p>
                It is important to note that we may access, utilize, and release your PII to comply with court orders, subpoenas, legal process, litigation and/or requests from governmental agencies or authorities which require us to disclose your PII. We may also disclose your information if we determine that disclosure is necessary to comply with applicable law or suspicion of violation of law
            </p>
            <p>
                If we so choose to merge, sell, or otherwise reorganize the Site in any way, we may transfer and deliver your PII to the relevant third party at such time. Such transfers are ordinarily part of any business transfers.
            </p>
            <p>
                Aside from the instances specifically addressed in this Privacy Policy, you will receive notice when your PII may go to a third party, and you will be provided with an opportunity to withhold your consent to the transfer of any such information to a third party.
            </p>
            <p>
                <b>III. Cookies and Other Technology</b>
            </p>
            <p>
                The Site, products, services, and communications may use cookies and other technologies such as pixel tags and web beacons. Cookies are a form of technology that contains electronic information that can be used to help us customize your individual use of our website by informing us of what parts of the website you have utilized or visited, keep records and logs of your access to the site, and/or store information required by a user on an ongoing basis. Site utilizes cookies to remember your PII to tailor your experience to you, including so that you do not need to repeatedly re-enter your information, and/or username and password each time you access a new page on the Site. We regard information collected by cookies and similar technologies as non-personal information. If, according to the local laws, Internet Protocol ("IP") addresses or similar identifying information is considered personally identifiable information, we will also treat such information as personally identifiable.
            </p>
            <p>
                Some information we collect is gathered automatically and stored in log files. We may use third party analytics that utilize cookies to collect non-personally identifiable information regarding your use of the website.
            </p>
            <p>
                <b>IV. Children Under the Age of 18</b>
            </p>
            <p>
                Children under the age of 18 are strictly prohibited from using the Site website. We will not knowingly collect any PII from children under the age of 18. In accordance with the Site Terms, we encourage parents to monitor their child's activities online and to counsel them to never give out PII while online. If we become aware that a child under 18 has provided us with personal information without parental consent, we take steps to remove such information and terminate the child's account immediately.
            </p>
            <p>
                <b>V. Opting-Out of Communications With Us</b>
            </p>
            <p>
                As noted above, you have the ability to opt-out of receiving promotional or advertising communications from us. If you would like to opt-out of promotional communications, please contact us at support@agenciadiso.freshdesk.com so that we can provide you with instructions for discontinuing such communications.
            </p>
            <p>
                <b>VI. Storing and Securing Your PII</b>
            </p>
            <p>
                We endeavor to protect the security of the PII you provide to us by employing reasonable measures to ensure your information remains protected. Unfortunately, the transmission of information via the Internet is not entirely secure. We utilize commercially reasonable physical, managerial, and technical safeguards to preserve the integrity and security of your PII. We cannot, however, guarantee or warrant the security of any information you transmit on the Site , and you do so at your own risk.
            </p>
            <p>
                If you close your account, the personal data that relates to your account and/or payment information will be deleted after five months, subject to Section VII below. Financial records shall be kept for 7 years, which we are legally required to do regarding details of payments made by you. This paragraph satisfies the provisions of Article 13(2)(b&c) GDPR, if your use of the Site is within the European Union.
            </p>
            <p>
                <b>VII. Information You Can Access</b>
            </p>
            <p>
                Concerns about the type and accuracy of PII collected by us , or if you wish to review or amend any information you have provided us with, or if you wish for your PII to be purged, terminated, or otherwise deleted from our records, you can contact us at support@agenciadiso.freshdesk.com. We reserve the right not to comply with any requests that would be unreasonably impractical, repetitive, or would infringe the privacy of others. Additionally, we may retain information about you for the purposes authorized under this Privacy Policy, unless prohibited by law. For example, we may retain information to prevent, investigate, or identify possible wrongdoing in connection with your use of the site, or to comply with legal obligations.
            </p>
            <p>
                <b>VIII. Conditions, Use, and Modifications</b>
            </p>
            <p>
                By accessing the site or utilizing any services therein, your use and any dispute relating to or arising from that use are controlled by this Privacy Policy and the  Terms of Service, including but not limited to, limitation of damages, arbitration, and class action waiver.
            </p>
            <p>
                If you have any questions or concerns regarding your PII please do not hesitate to contact us at support@agenciadiso.freshdesk.com and we will address it.
            </p>
            <p>
                As outlined in our Terms, we may from time-to-time update our Terms or this Privacy Policy. The right to amend the Terms and this Privacy Policy includes the right to modify, add to, or remove clauses at our sole discretion, and without notice to you. Except as otherwise stated in the Terms or this Privacy Policy, all amended terms shall be effective immediately after they are posted on the Site. Regardless of our efforts to provide reasonable notice of any change in Terms or Privacy Policy, you should review the Terms and Privacy Policy periodically.
            </p>
            <p>
                Any changes that are made to the Terms or this Privacy Policy will not apply retroactively, and will not apply to any dispute of which both parties had actual notice on or prior to the date of the change or amendment

                    </p>
            <p>
                <b>&copy; 2020 All right reserved</b>
            </p>
        </div>
    );
};
export default PrivacyEnglish;
