import React, { Component } from 'react';
import { Circle } from 'react-preloaders';
import {isMobile} from 'react-device-detect';
import SideNav from '../../components/NavBar/SideNav';
import PurchaseList from '../../components/PurchaseList';
import './style.css';

class Purchases extends Component {

    constructor(props) {
        super(props);
        this.state={
            config: [],
            content: [],
            picodes: [],
            promo: null,
            dictionary: [],
            accepted: false,
            confirmed: false,
            showModal : false,
            membership: false,
            displayMenu: false,
            picodeSelected: null,
            profileFeatures:null,
            showModalCancel: false
        }
    }

    componentDidMount(){
        this.setState({ 
            dictionary: this.props.dictionary
        });
    }

    render() {
        if(this.state.dictionary === null ) return (
            <div> 
                <SideNav 
                    config={this.props.config}
                    toElevate={this.props.toElevate}
                    dictionary={this.props.dictionary} 
                    view={this.props.view ? this.props.view : false} 
                    notification={ this.props.streaming ? {link : "/streaming", message: "Model is streaming NOW!"} : null}
                />
                <div className="center-align" style={{paddingTop:45}}>
                    <Circle
                        color="#4285f4"
                        className="center-align"
                        background="transparent !important"
                    />
                </div>
            </div>);

        return (
            <div 
                className="redesign"
                style={{
                    backgroundColor : this.props.config.color.navbar
                }}
            >
                <SideNav
                    style={{height:250}}
                    config={this.props.config}
                    toElevate={this.props.toElevate}
                    dictionary={this.props.dictionary}
                    view={this.props.view ? this.props.view : false}
                    notification={ this.props.streaming ? {link : "/streaming", message: "Model is streaming NOW!"} : null} />
                <div
                    className="container main-container redesign-profile-container"
                    style={{
                        backgroundColor : this.props.config.color.principal,
                        color: this.props.config.color.principalText
                    }}
                >
                    <div className="center-align">
                        <h5>
                            {this.props.dictionary.purchases || "Purchases" }
                        </h5>
                        <hr
                            style={{
                                backgroundColor: this.props.config.color.principalText,
                                opacity: 0.3
                            }}
                        />
                        <PurchaseList
                            isMobile={isMobile}
                            view={this.props.view}
                            config={this.props.config}
                            dictionary={this.props.dictionary}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Purchases;
