import React, { Component } from 'react';
import SectionIcon from '../../../Svg/SectionIcon';

class Section extends Component{
    // constructor(props) {
    //     super(props);
    // }

    // componentDidMount() {
    // }

    render(){
        let section = this.props.section;
        let config = this.props.config;
        let dictionary = this.props.dictionary;
        return(
            <li
                onClick={(e) => this.props.changeSection(section.sectionName)}
            >
                <div className="section-cell">

                    {section.iconType ?
                        <SectionIcon
                            iconName={section.iconType}
                            color={this.props.active ? config.color.secondary : config.color.principalText}
                            width={"25px"}
                            height={"25px"}
                        />
                    :
                        <img
                            src={section.icon}
                            title={section.sectionName}
                            alt={section.sectionName}
                        />
                    }

                    { this.props.active ?
                        <span style={{color : config.color.secondary}}>
                            {dictionary[section.sectionName] || section.sectionName}
                        </span>
                        : null
                    }
                </div>

            </li>
        )
    }
}

export default Section;
