import React, {Component} from "react";
import './style.css';
import Modal from "../../../Modal";
import Carousel from "../../../Publish/Carousel";
import Promise from "bluebird";
import MediaBoxList from '../../../MediaBox/MediaBoxList';
import axios from "axios";
import cookie from "js-cookie";
import images from '../../../Images';
import CoinIcon from '../../../Svg/CoinIcon';
import ImageIcon from '../../../Svg/ImageIcon';
import MediaBoxIcon from '../../../Svg/MediaBoxIcon';
import UploadFiles from "../../../UploadFiles/UploadFiles";
import WriteIcon from '../../../Svg/WriteIcon';
import {Circle} from "react-preloaders";
import Saving from "../../../Saving";

class ConversationItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            filesUploading: 0,
            idMediaBox: "mediaBoxMasive",
            postSection: [],
            section: null,
            openDatePicker: false,
            publicPost: false,
            description: null,
            descriptionError: false,
            disabled: false,
            saving: false,
            uploading: false,
            progress: 0,
            progressSlice: 0,
            messageBroadcast: "",
            costoBroadcast: 10,
            totalFiles: 0,
            filesToUpload: 0,
            filesChanged: false,
            success: false,
            loadMediaBox : false
        }
        this.axiosInstance = axios.create({
            baseURL: 'https://socialtechapps.com/'
            // baseURL: 'http://localhost:5000/'
        });
        this.changeHandler = this.changeHandler.bind(this);
        this.fileInput = React.createRef();
        this.baseState = this.state;
    }

    saveBroadcast = async (e) => {
        const message = this.state.messageBroadcast;
        let price = this.state.costoBroadcast;
        const minCoin = this.props.coinPrice
        price = minCoin * price;
        let send = true;
        if (isNaN(price) || price < 0) {
            this.setState({errorBroadcast: this.props.dictionary.MsInvalidPrice});
            send = false;
        }
        if (this.state.files.length === 0) {
            this.setState({errorBroadcast: this.props.dictionary.MsInvalidFileCount});
            send = false;
        }

        if (send) {
            this.setState({
                errorBroadcast: null, saving: true, disabled: true
            });

            const messageSocket = {
                created_time: new Date(),
                messages: message,
                model_message: 1,
                modeloname: this.props.modelData.modeluser,
                user_message: 0,
                username: cookie.get('username'),
                token: cookie.get('token'),
                id_user: null,
                media: [],
                hasMedia: false,
                index: null,
                is_broadcast: 1,
                price: price,
                pagado: null,
                massive: true,
                privateMessage: null,
                processingMedia: 0
            };

            await axios.post("/api/v2/messages/broadcast", {
                message: Buffer.from(message).toString('base64'),
                price: price
            }).then(async res => {
                const messageId = res.data.success;
                let currMedia = this.state.files;
                let countMedia = this.state.files.length;

                messageSocket.hasMedia = currMedia.length;
                messageSocket.index = res.data.success;
                messageSocket.privateMessage = res.data.success;

                this.props.socket.emit('SEND_MESSAGE', this.props.modelData.modeluser, messageSocket);
                this.setState({
                    totalFiles: countMedia, filesToUpload: countMedia, uploading: true
                });

                await Promise.all(currMedia.map(async (item, index) => {
                    if (item.method === "mediabox") {
                        await this.mediaBoxMedia(item.mediaBoxId, index, messageId);
                    } else {
                        await UploadFiles.uploadFiles(item.file,{
                            index,
                            message: messageId,
                            type: "message",
                            totalFiles: this.state.totalFiles,
                            folder: '/message/',
                        }, this.axiosMedia);
                    }
                }));

            });

        }
    }

    mediaBoxMedia = async (mediabox, index, message) => {
        return await axios.post('/api/v2/message/media/mediabox', {
            mediabox: mediabox,
            message: message,
            total: this.state.totalFiles,
            mediaAnswering: null
        })
            .then(resp => {
                let images = [...this.state.files];
                if (images[index]) {
                    images[index]["progress"] = 100;
                    let filesToUpload = this.state.filesToUpload;
                    filesToUpload--;
                    this.setState({filesToUpload: filesToUpload});
                    if (filesToUpload === 0) {
                        this.setState({
                            files: [],
                            audio: null,
                            uploadingPost: null,
                            disabled: false,
                            mediaBoxHandler: !this.state.mediaBoxHandler,
                            success: true
                        })
                    } else {
                        this.setState({files: images});
                    }
                }
            })
            .catch(err => {
                return false
            })
    }

    uploadFiles = async (file, index, message) => {
        const formData = new FormData();
        formData.append('message', message);
        formData.append('user', cookie.get('username'));
        formData.append('hostname',
            window.location.hostname === 'localhost'
                ? 'myladydusha.com'
                : window.location.hostname
        );
        formData.append('isModel', true);
        formData.append('token', cookie.get('token'));
        formData.append('file', file.file);
        formData.append('numTotalImages', this.state.totalFiles);
        await this.axiosMedia(formData, index);
    };

    axiosMedia = async (url="api/v2/message/media", formData, option) => {
        try {
            return await this.axiosInstance.put(url, formData, {
                onUploadProgress: progressEvent => {
                    let images = [...this.state.files];
                    if (images[option.index]) {

                        let progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);

                        images[option.index]["progress"] = progress;
                        this.setState({progress: progress});

                        let filesToUpload = this.state.filesToUpload;
                        if (progress === 100) {
                            filesToUpload--;
                            this.setState({filesToUpload: filesToUpload});
                        }

                        if (filesToUpload === 0) {
                            this.setState({
                                files: [],
                                uploading: null,
                                saving: false,
                                success: true
                            })
                        } else {
                            this.setState({files: images});
                        }
                    }
                },
                headers: {
                    'Content-Type': formData.type
                }
            }).then(async (res) => {
                return res;
            }).catch(async (res) => {
                return res;
            });
        } catch (e) {
            console.log(e)
        }
    };

    changeHandler(e) {
        let name = e.target.name;
        let value = e.target.value;
        value = name === "costoBroadcast" ? parseInt(value) : value;
        this.setState({[name]: value});
    }

    removeMedia = (index) => {
        let files = [...this.state.files];
        files.splice(index, 1);
        this.setState({files: files})
    }

    openMediaBox = async () => {
        await this.setState({loadMediaBox : true});
        setTimeout(() => {
            document.getElementById(this.state.idMediaBox).classList.add("open")
        }, 350); 
    }

    getChoosen = async (arr) => {
        let files = [...this.state.files];
        let newFiles = files.concat(arr);
        this.setState({files: newFiles});
    }

    async fileChangedHandler(e) {

        const files = e.target.files;
        let currFiles = this.state.files;

        //Show loading while waiting draw
        this.setState({filesChanged: true, filesUploading: files.length});

        await new Promise.map(files, async (file, i) => {

            let typeFormat = file
                ? images.validateFormat(file.type)
                : '';

            if (typeFormat === 'video' || typeFormat === 'image') {
                if (file.type.match('image/jpeg.*')) {
                    let blob = await images.rotateImage(file, i);
                    currFiles.push({file: blob, type: typeFormat, method: "upload", progress: 0});
                } else {
                    currFiles.push({file: file, type: typeFormat, method: "upload", progress: 0});
                }
            }

        });

        this.setState({
            files: currFiles,
            filesChanged: false
        })
    }

    drawCanvas(size) {
        let canvas = [];
        for (let i = 0; i < size; i++) {
            canvas.push(<canvas key={i} id={"canvasToRotate" + i} className="hide"></canvas>)
        }
        return canvas;
    }

    renderCarousel() {
        let files = [...this.state.files];
        let config = this.props.config;
        if (files.length === 0) {
            return null;
        }
        return (
            <Carousel
                media={files}
                config={config}
                remove={this.removeMedia}
            />
        )
    }

    triggerSuccess() {
        setTimeout(() => {
            this.setState(this.baseState);
            //This state files is modified in this.baseState D:
            this.setState({files: []});
            this.props.toElevate.toggleModalBroadcastMessage();
        }, 4000);
        return <Modal id="modalBroadcastMessage"
                      open={this.props.open}
                      closeAction={null}
                      head={this.props.dictionary.sentSuccess}
                      config={this.props.config}
                      footer={null}>
        </Modal>;
    }

    calcProgress = () => {
        let files = [...this.state.files];
        let length = files.length;
        let progress = 0;
        files.map( async (el) => {
            progress = progress + el.progress;
        })
        return progress/length;
    }

    render() {

        let dictionary = this.props.dictionary;
        let config = this.props.config;
        let btnStyle = {
            backgroundColor: config.color.secondary,
            color: config.color.secondaryText
        };
        let btnStyleInactive = {
            backgroundColor: config.color.secondaryText,
            color: config.color.secondary,
        };
        let actionStyle = {
            border: "1px solid " + config.color.principalText, color: config.color.principalText
        };
        let footer = <div className={'modal-broadcast'}>
            <button disabled={this.state.disabled}
                    className="button"
                    style={this.state.disabled ? btnStyleInactive : btnStyle}
                    onClick={(e) => {
                        this.saveBroadcast(e)
                    }}>
                {dictionary.publicPost}
            </button>
        </div>;

        let head = <h5 className={'modal-broadcast-title'}
                       style={{color: config.color.principalText}}>
            {dictionary.MsPublic}
        </h5>;

        let progress = 0;
        if(this.state.uploading){
            progress = this.calcProgress();
        }

        let saving = this.state.saving || this.state.uploading
            ?  <div style={{display: 'table', margin: '0 auto'}} className={"text-center"}> <span>{dictionary.processing}</span><Saving
                config={config}
                id="savingBroadcast"
                saving={false}
                loading={10/*this.state.uploading*/}
                progress={progress}
            /></div>
            : null;

        let filesChange = this.state.filesChanged
            ? <div className={"center-align"}>
                <Circle background={"transparent !important"} color={"#4285f4"} className={"center-align"}/></div>
            : null

        return this.state.success
            ? this.triggerSuccess()
            : (<Modal id="modalBroadcastMessage"
                      open={this.props.open}
                      closeAction={this.props.toElevate.toggleModalBroadcastMessage}
                      head={head}
                      config={this.props.config}
                      footer={footer}>
                    <div className={'modal-broadcast'}>
                        {saving}
                        {filesChange}
                        {this.state.loadMediaBox ? 
                            <MediaBoxList
                                config={config}
                                dictionary={dictionary}
                                id={this.state.idMediaBox}
                                getChoosen={this.getChoosen}
                                post={true}/>
                        : null }
                        {this.drawCanvas(this.state.filesUploading)}
                        <div hidden={this.state.saving || this.state.uploading}>
                            <label>
                                <WriteIcon color={config.color.principalText}
                                           width={"25px"}
                                           height={"25px"}/>
                                {this.props.dictionary.msn}
                            </label>
                            <textarea name="messageBroadcast"
                                      id="messageBroadcast"
                                      className={(this.state.descriptionError) ? "error" : ""}
                                      placeholder={"max. 750 " + this.props.dictionary.chars}
                                      rows="5"
                                      maxLength={750}
                                      onChange={this.changeHandler}
                                      value={this.state.messageBroadcast}
                            >
                            </textarea>
                        </div>
                        <div hidden={this.state.saving || this.state.uploading}>
                            <div>
                                <p>
                                    <label>
                                        <CoinIcon color={config.color.principalText}
                                                  width={"25px"}
                                                  height={"25px"}/>
                                        {this.props.dictionary.cost + " (" + this.props.dictionary.dollars + ")"}
                                    </label>
                                </p>
                                <div className={'half first'}>

                                    <input type="number"
                                           id="costoBroadcast"
                                           name="costoBroadcast"
                                           placeholder="0"
                                           min={0}
                                           onChange={this.changeHandler}
                                           value={this.state.costoBroadcast}
                                    />
                                </div>
                                <div className={'half second'}>
                                    <ul className="actions">
                                        <li>
                                            <button className="button"
                                                    style={actionStyle}
                                                    onClick={() => {
                                                        this.fileInput.current.click()
                                                    }}
                                            >
                                                <ImageIcon
                                                    color={config.color.principalText}
                                                    width={"25px"}
                                                    height={"25px"}
                                                />
                                            </button>
                                            {
                                                (this.props.isMobile) ?
                                                    <input
                                                        ref={this.fileInput}
                                                        hidden
                                                        type="file"
                                                        name="fileStorage"
                                                        id="fileStorage"
                                                        onChange={(e) => {
                                                            this.fileChangedHandler(e)
                                                        }}
                                                    />
                                                    :
                                                    <input
                                                        ref={this.fileInput}
                                                        hidden
                                                        type="file"
                                                        name="fileStorage"
                                                        id="fileStorage"
                                                        multiple
                                                        onChange={(e) => {
                                                            this.fileChangedHandler(e)
                                                        }}
                                                    />
                                            }

                                        </li>
                                        <li>
                                            <button
                                                className="button"
                                                style={actionStyle}
                                                onClick={(e) => this.openMediaBox(e)}
                                            >
                                                <MediaBoxIcon
                                                    color={config.color.principalText}
                                                    width={"20px"}
                                                    height={"20px"}
                                                />
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="mt-1 text-center redesign-error-class">{this.state.errorBroadcast}</div>
                            {this.renderCarousel()}
                        </div>
                    </div>
                </Modal>
            );
    }
}

export default ConversationItem;
