import React, {Component} from 'react';
import moment from "moment";
import Icon from "react-ionicons";
import { Preloader, Oval } from 'react-preloader-icon';
import DropdownList from "../../../DropdownList"

/**
 * @prop Object data {
 * created_time: Date,
 * error: Number,
 * idMessage: Number,
 * id_user: Number,
 * is_broadcast: Number,
 * media: Array,
 * media_requested: mix|null,
 * media_sended: mix|null,
 * messages: String,
 * model_message: Number,
 * modeloname: String,
 * pagado: mixed|null,
 * price: mixed|null,
 * privateMessage: Number,
 * procesando: Number,
 * user_message: Number,
 * username: String
 * }
 */
class ChatGlobe extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMedia: false,
            fullMedia: null,

        };

        this.playVideo = this.playVideo.bind(this);
        this.closeFull = this.closeFull.bind(this);
    }

    closeFull() {
        this.setState({fullMedia: null});
    }

    toggleFull(e) {
        let elem = e.target;
        if (document.fullscreenElement)
            document.exitFullscreen();

        else {
            if (elem.requestFullscreen)
                elem.requestFullscreen();
        }

    }

    playVideo(e) {
        e.preventDefault();
        let elem = e.target;
        if (elem.paused)
            elem.play();
        else {
            elem.pause();
        }

        /*
        let src = elem.getAttribute('src');
        let ext = src.substring(src.length, (src.length -4));
        let container = <div className={'v2-chat-globe-full-media'}>
            <button onClick={this.closeFull}>Close</button>
            {src}
        </div>;
        this.setState({fullMedia: container});*/
    }

    drawMenu(msg, model, color, completeMessage={}) {
        if (this.props.isModel) {
            let itemsListed = [];
            if (completeMessage.media_requested && !completeMessage.media_sended){
                itemsListed.push([
                    this.props.dictionary.markAsAnswered, '#', '', '', (e) => {
                        e.preventDefault();
                        this.props.triggerMarkMessageAsResolve(msg, model)
                    }]
                );
            }
            else{
                itemsListed.push(["Delete", '#', '', '', (e) => {
                    e.preventDefault();
                    this.props.triggerDeleteModal(msg, model)
                }]);
            }
            return (
                <DropdownList
                    items={itemsListed}
                    trigger={
                        <Icon icon="ios-arrow-down" color={color}/>
                    }
                />
            )
        }
        return null
    }

    postInfo(post, border) {
        if (!post) {
            return null
        }
        let config = this.props.config
        let image = (post.mediabox) ?
            config.storageURL + '/' + config.storage + "/message/model/" + post.img
            :
            config.storageURL + '/' + config.storage + "/posts/img/" + post.img

        let postName = post.name || "";

        if(post.name && post.name.length  > 50 ){
            postName = post.name.substring(0,50) + '...';
        }
        return (
            <div className="v2-chat-globe-post"
                style={{
                    borderColor: border
                }}>
                <span>
                    <img alt={postName} src={image}/>
                </span>
                <div>
                    {postName}
                </div>
            </div>
        )
    }

    broadcastMedia(item, index) {
        if (item.pagado === null) {
            return (
                (!this.props.isModel) ?
                    <div className="message-buy">
                        <p>
                            {this.props.dictionary.queExclusiveContent} {item.price} {this.props.dictionary.coins}?
                        </p>
                        { this.blurMediaMessage(item, index)}
                        <button
                            style={{
                                backgroundColor: this.props.config.color.principal,
                                color: this.props.config.color.principalText
                            }}
                            onClick={(e) => this.props.getPack(item.privateMessage, item.price)}
                        >
                            {this.props.dictionary.getIt}
                        </button>
                    </div>
                    :
                    <div className="message-buy">
                        <p>{this.props.dictionary.usrnotacqmsn}</p>
                    </div>
            )
        } else {
            return(
                (!this.props.isModel) ?
                    <div>
                        <p>{this.props.dictionary.enjoyExcCont}</p>
                        {this.mediaMessage(item, index)}
                    </div>
                :
                    <div>
                        <p>{this.props.dictionary.usracqmsn}</p>
                        {this.mediaMessage(item, index)}
                    </div>
            )
        }
    }

    blurMediaMessage(item, index){

        let mediaArray = item.media;
        if (!Array.isArray(mediaArray) || mediaArray.length === 0)
            return null
        return mediaArray.map((media, index) => {

            if (media.blurMedia !== null ){
                return <img key={index}
                        src={media.blurMedia}
                        caption={"image" + index}
                        className="zoom-cursor responsive-img"
                        alt={"image" + index}/>
            }
            return null;
        });
    }

    mediaMessage(item, index) {
        if (item.procesando && item.error) {
            return (
                <div className="message-error">
                    <Icon
                        icon="ios-close-circle-outline"
                        color={this.props.config.color.principal}
                        fontSize="3em"
                    />
                    <button
                        style={{
                            backgroundColor: this.props.config.color.principal,
                            color: this.props.config.color.principalText
                        }}
                        onClick={
                            (item.pendingFiles === undefined) ?
                                (e) => this.props.retryMessage(e, item.privateMessage)
                                :
                                (e) => this.props.retrySendMessage(e, item.privateMessage, index)
                        }
                    >
                        {this.props.dictionary.retry}
                    </button>
                </div>
            )
        }
        if (item.procesando) {
            return <div className={'v2-chat-globe-loader'}><Preloader
                use={Oval}
                size={30}
                strokeWidth={6}
                strokeColor={this.props.config.color.principal}
                duration={600}
            />
            </div>
        }

        let mediaArray = item.media;
        if (!Array.isArray(mediaArray) || mediaArray.length === 0)
            return null
        return mediaArray.map((media, index) => {
            //const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
            let inverseCount = ((mediaArray.length - 1) - index);

            let route = (item.user_message === 1)
                ? (this.props.isModel) ? this.props.userFrom : this.props.userData.userId
                : "model";
            route = this.props.config.storageURL + '/' + this.props.config.storage + "/message/" + route + "/";
            if (media.audio)
                return <audio
                            key={index}
                             autoPlay={false}
                             controls
                             controlsList="nodownload"
                             volume={0.7}
                             src={route + media.audio}
                             type="audio/mp3">
                        Your browser does not support the
                        <code>audio</code> tag.
                    </audio>;

            if (media.video)
                return (
                    <>
                        <video
                          key={index}
                          preload="yes"
                          poster={route + media.img}
                          className="zoom-cursor responsive-video"
                          controlsList="nodownload"
                          alt={"image" + index}
                          src={route + media.video}>
                            <source type="video/mp4" src={route + media.video}/>
                            Your browser does not support the <code>video</code> tag.
                        </video>

                        <div
                            style={{ position : "absolute", left:0, top:0, right:0, bottom:0, zIndex:1 }}
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.toggleMediaViewer(this.props.mediaPosition - inverseCount)
                            }}
                            onDoubleClick={this.toggleFull}
                        >
                        </div>
                    </>);

            if (media.img)
                return(
                    <>
                        <img key={index}
                                src={route + media.img}
                                caption={"image" + index}
                                className="zoom-cursor responsive-img"
                                alt={"image" + index}
                        />
                        <div
                            style={{ position : "absolute", left:0, top:0, right:0, bottom:0, zIndex:1 }}
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.toggleMediaViewer(this.props.mediaPosition - inverseCount)
                            }}
                        >
                        </div>
                    </>
                );

            return null;
        });
    }

    getMessage(item) {
        if (item.media_requested) {
            let icon = "";
            // eslint-disable-next-line
            switch (item.media_requested) {
                case 3:
                    icon = "md-mic";
                    break;
                case 4 :
                    icon = "md-camera";
                    break;
                case 5 :
                    icon = "md-videocam";
            }
            return (
                <div className={
                    (!item.media_sended && this.props.isModel) ?
                        "media-request model-media-request"
                        :
                        "media-request"
                }>
                    <Icon
                        icon={icon}
                        fontSize={"2.5rem"}
                        color={this.props.config.color.principal}
                    />
                    {(!item.media_sended)
                        ?
                        this.props.isModel ?
                            this.props.dictionary.respreqmul
                            :
                            this.props.dictionary.hvreqmedfl
                        :
                        this.props.isModel ?
                            this.props.dictionary.alranstmsg
                            :
                            this.props.dictionary.hvrecmedfl
                    }
                    {(!item.media_sended && this.props.isModel) ?
                        <button
                            style={{
                                backgroundColor: this.props.config.color.principal,
                                color: this.props.config.color.principalText
                            }}
                            onClick={(e) => this.props.answerMediaRequest(e, item.privateMessage, item.media_requested)}
                        >
                            {this.props.dictionary.send}
                        </button>
                        :
                        null
                    }
                </div>
            )
        } else {
            return (
                item.messages
            )
        }
    }

    render() {

        const messageData = this.props.data;

        if ((messageData.model_message === 1 && messageData.procesando && !this.props.isModel)
            || (messageData.user_message === 1 && messageData.procesando && this.props.isModel))
            return null

        let configColor = this.props.config.color;

        let content = null;
        if (messageData.media.length > 0 || messageData.hasMedia)
            content = (messageData.price)
                ? this.broadcastMedia(messageData, this.props.index)
                : this.mediaMessage(messageData, this.props.index);

        let message = this.getMessage(messageData);
        let post = this.postInfo(
            messageData.post,
            (messageData.user_message === 1) ?
                configColor.chat.userChatText
                :
                configColor.chat.modelChatText
        )
        let menu = (messageData.is_broadcast) ?
            null
            :
            this.drawMenu(
                messageData.privateMessage,
                messageData.model_message,
                (messageData.user_message === 1) ?
                    configColor.chat.userChatText
                    :
                    configColor.chat.modelChatText,
                messageData
            )

        let forForward = this.props.toScroll;
        let styleMessage = (messageData.user_message === 1)
            ? {
                backgroundColor: configColor.chat.userChat,
                color: configColor.chat.userChatText
            }
            : {
                backgroundColor: configColor.chat.modelChat,
                color: configColor.chat.modelChatText
            };
        let liClassName;
        if (this.props.isModel) {
            liClassName = messageData.user_message
                ? 'v2-chat-globe-from'
                : 'v2-chat-globe-to';
        } else {
            liClassName = messageData.model_message
                ? 'v2-chat-globe-from'
                : 'v2-chat-globe-to';
        }

        let tailStyle = messageData.model_message
            ? {borderTop: "1rem solid " + configColor.chat.modelChat}
            : {borderTop: "1rem solid " + configColor.chat.userChat}

        let time = moment(messageData.created_time).format('LT');
        let fullMedia = this.state.fullMedia;
        return (
            <li key={messageData.index} className={liClassName}>
                {fullMedia}
                {forForward}
                <div style={styleMessage} className={"v2-chat-globe"}>
                    {menu}
                    {post}
                    {content}
                    <div className={'v2-chat-globe-wrap'}>
                        {typeof message === "object" ? (
                            message
                        ) : (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: message,
                                }}
                            />
                        )}
                        <span className={"v2-chat-globe-moment"}>{time}</span>
                    </div>
                    <span className={"globe-tail"} style={tailStyle}/>
                </div>
            </li>
        );
    }
}

export default ChatGlobe;
