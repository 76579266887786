import React from "react";
import StreamingAndroid from "../../components/StreamingAndroid";
import StreamingClient from "../../components/StreamingClient/StreamingClient";


const StreamingContent = props => {
        return (
            <div>
                {props.streamingMdl ?
                    <StreamingAndroid
                        sections={props.sections}
                        toElevate={props.toElevate}
                        dictionary={props.dictionary}
                        config={props.config}
                    />
                 : 
                    <StreamingClient
                        toElevate={props.toElevate}
                        dictionary={props.dictionary}
                        config={props.config}
                        view={props.view}
                    />
                }
            </div>
        );
}
export default StreamingContent;