import React from 'react';

const TravelIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            version="1.1" 
            id="Capa_1" 
            xmlns="http://www.w3.org/2000/svg"
            width={width} 
            height={height}
            viewBox="0 0 24 24" 
        >
            <style type="text/css">
            </style>
            <g class="st0">
                <path 
                    fill={color} 
                    d="M7.66,2.91C7.71,2.88,7.73,2.82,7.7,2.78C7.61,2.62,7.59,2.41,7.66,2.26C7.71,2.14,7.8,2.07,7.93,2.03
                    C7.98,2.02,8.01,1.97,8,1.91C7.99,1.86,7.93,1.83,7.88,1.84C7.69,1.89,7.55,2.01,7.48,2.18c-0.1,0.24-0.05,0.52,0.05,0.7
                    c0.02,0.03,0.05,0.05,0.09,0.05C7.63,2.93,7.65,2.92,7.66,2.91z"
                />
                <path 
                    fill={color} 
                    d="M8.57,4.75C8.57,4.75,8.57,4.75,8.57,4.75C8.57,4.75,8.57,4.75,8.57,4.75c0.96-0.05,1.66-0.5,2.09-1.33
                    c0.15-0.28,0.19-0.44,0.24-0.64c0.03-0.1,0.05-0.21,0.1-0.35c0.05-0.15,0.12-0.22,0.22-0.23c0.1-0.01,0.18,0.05,0.25,0.18
                    c0.07,0.13,0.12,0.23,0.16,0.32c0.09,0.18,0.16,0.34,0.35,0.58c0.49,0.63,1.13,0.95,1.89,0.95c0.13,0,0.26-0.01,0.4-0.03
                    c0,0,0,0,0.01,0c0.81-0.28,1.46-1.06,1.64-2c0.14-0.71-0.04-1.34-0.47-1.71c-0.5-0.43-1.48-0.36-1.96,0.13
                    c-0.72-0.21-1.65,0.2-1.93,0.86c-0.01,0.03-0.02,0.06-0.03,0.09c-0.11-0.08-0.25-0.12-0.39-0.11c-0.14,0.01-0.27,0.08-0.36,0.18
                    c-0.01-0.03-0.03-0.05-0.05-0.08c-0.4-0.6-1.39-0.83-2.05-0.49C8.13,0.7,7.15,0.82,6.74,1.33C6.38,1.78,6.32,2.42,6.59,3.1
                    C6.94,3.98,7.72,4.63,8.57,4.75z M7.17,1.72c0.14-0.17,0.37-0.27,0.65-0.29c0.02,0,0.04,0,0.06,0c0.31,0,0.62,0.11,0.78,0.27
                    c0.01,0.01,0.03,0.01,0.04,0c0.22-0.17,0.59-0.25,0.91-0.2c0.2,0.03,0.48,0.13,0.67,0.41c0.17,0.26,0.15,0.75-0.06,1.19
                    c-0.15,0.3-0.6,1.02-1.65,1.11C7.53,3.93,7.18,3.16,7.08,2.85C6.94,2.4,6.98,1.96,7.17,1.72z M12.07,1.75
                    c0.13-0.31,0.39-0.46,0.58-0.53c0.14-0.05,0.3-0.08,0.46-0.08c0.18,0,0.34,0.03,0.48,0.1c0.01,0.01,0.03,0,0.04-0.01
                    c0.14-0.2,0.46-0.37,0.78-0.42c0.28-0.04,0.52,0.02,0.69,0.16c0.24,0.2,0.36,0.63,0.3,1.09c-0.04,0.33-0.24,1.15-1.21,1.62
                    c-1.05,0.11-1.63-0.51-1.83-0.78C12.06,2.52,11.95,2.04,12.07,1.75z"
                />
                <path 
                    fill={color} 
                    d="M12.6,2.46c0.01,0,0.03,0,0.04-0.01c0.05-0.02,0.07-0.08,0.05-0.13c-0.06-0.14-0.04-0.32,0.04-0.44
                    c0.08-0.11,0.21-0.16,0.38-0.15c0.05,0,0.1-0.04,0.11-0.09s-0.04-0.1-0.09-0.11c-0.24-0.02-0.43,0.06-0.55,0.23
                    c-0.13,0.17-0.15,0.42-0.06,0.63C12.53,2.44,12.57,2.46,12.6,2.46z"
                />
                <path 
                    fill={color} 
                    d="M8.12,21.4c0-0.01,0-0.01,0-0.02c0-0.01,0-0.01,0-0.02c0-0.01,0-0.01,0-0.02c0-0.01,0-0.01-0.01-0.02
                    c0,0,0-0.01,0-0.01l-0.69-2.07L19.16,7.49c0,0,0,0,0,0c1.26-1.26,1.27-3.65,1.27-3.75c0-0.23-0.18-0.41-0.41-0.41
                    c-0.1,0-2.49,0.01-3.75,1.27l-2.53,2.53L1.66,4.12c0,0-0.01,0-0.01,0c-0.01,0-0.02,0-0.02,0c-0.01,0-0.01,0-0.02,0
                    c-0.01,0-0.01,0-0.02,0c-0.01,0-0.01,0-0.02,0c-0.01,0-0.01,0-0.02,0c-0.01,0-0.01,0-0.02,0c-0.01,0-0.01,0-0.02,0
                    c-0.01,0-0.01,0-0.02,0c-0.01,0-0.02,0-0.02,0c0,0-0.01,0-0.01,0c-0.01,0-0.02,0.01-0.03,0.01c0,0-0.01,0-0.01,0
                    c-0.01,0-0.02,0.01-0.03,0.01c0,0-0.01,0-0.01,0c-0.01,0-0.02,0.01-0.03,0.01c0,0-0.01,0-0.01,0.01c-0.01,0-0.01,0.01-0.02,0.01
                    c0,0-0.01,0.01-0.01,0.01C1.31,4.19,1.3,4.2,1.3,4.2c-0.01,0-0.01,0.01-0.01,0.01c0,0-0.01,0-0.01,0.01L0.12,5.38
                    c0,0,0,0-0.01,0.01C0.11,5.39,0.1,5.39,0.1,5.4c0,0.01-0.01,0.01-0.01,0.02c0,0,0,0-0.01,0.01c0,0,0,0.01-0.01,0.01
                    c0,0.01-0.01,0.01-0.01,0.02c0,0.01-0.01,0.01-0.01,0.02c0,0.01-0.01,0.01-0.01,0.02c0,0.01-0.01,0.01-0.01,0.02
                    c0,0.01-0.01,0.01-0.01,0.02c0,0.01,0,0.01-0.01,0.02c0,0.01,0,0.01-0.01,0.02c0,0.01,0,0.01-0.01,0.02c0,0.01,0,0.01,0,0.02
                    C0,5.6,0,5.61,0,5.62c0,0.01,0,0.01,0,0.02c0,0.01,0,0.01,0,0.02c0,0.01,0,0.01,0,0.02C0,5.68,0,5.69,0,5.7C0,5.7,0,5.71,0,5.72
                    c0,0.01,0,0.01,0,0.02c0,0.01,0,0.01,0,0.02c0,0.01,0,0.01,0,0.02c0,0.01,0,0.01,0.01,0.02c0,0.01,0,0.01,0.01,0.02
                    c0,0.01,0.01,0.01,0.01,0.02c0,0.01,0.01,0.01,0.01,0.02c0,0.01,0.01,0.01,0.01,0.02c0,0.01,0.01,0.01,0.01,0.02
                    c0,0.01,0.01,0.01,0.01,0.02c0,0.01,0.01,0.01,0.01,0.02c0,0,0.01,0.01,0.01,0.01C0.1,5.93,0.11,5.94,0.11,5.95c0,0,0,0,0.01,0.01
                    c0,0,0,0,0.01,0.01c0.01,0,0.01,0.01,0.02,0.01c0.01,0,0.01,0.01,0.02,0.01c0,0,0,0,0.01,0.01l8.47,6.26l-4.1,4.1l-2.07-0.69
                    c0,0-0.01,0-0.01,0c-0.01,0-0.02,0-0.02-0.01c-0.01,0-0.01,0-0.02,0c-0.01,0-0.01,0-0.02,0c-0.01,0-0.02,0-0.02,0
                    c-0.01,0-0.01,0-0.02,0c-0.01,0-0.02,0-0.02,0c-0.01,0-0.01,0-0.02,0c-0.01,0-0.02,0-0.02,0c-0.01,0-0.01,0-0.02,0
                    c-0.01,0-0.02,0-0.02,0c-0.01,0-0.01,0-0.02,0c-0.01,0-0.02,0-0.02,0.01c-0.01,0-0.01,0-0.02,0.01c-0.01,0-0.02,0.01-0.02,0.01
                    c0,0-0.01,0-0.01,0.01c-0.01,0-0.02,0.01-0.03,0.01c0,0,0,0-0.01,0l-0.01,0c0,0,0,0,0,0L0.2,16.85c0,0,0,0,0,0l0,0c0,0,0,0,0,0
                    c-0.01,0.01-0.02,0.01-0.03,0.02c0,0-0.01,0-0.01,0.01c-0.01,0.01-0.01,0.01-0.02,0.02c0,0-0.01,0.01-0.01,0.01
                    c0,0-0.01,0.01-0.01,0.01c-0.01,0.01-0.01,0.01-0.02,0.02c0,0-0.01,0.01-0.01,0.01c-0.01,0.01-0.01,0.02-0.02,0.03c0,0,0,0,0,0
                    c0,0,0,0,0,0c-0.01,0.02-0.03,0.05-0.03,0.07c0,0.01-0.01,0.02-0.01,0.03c0,0,0,0,0,0c0,0.01,0,0.02-0.01,0.03c0,0,0,0.01,0,0.01
                    c0,0.01,0,0.02,0,0.03c0,0,0,0.01,0,0.01c0,0.01,0,0.01,0,0.02c0,0.01,0,0.01,0,0.02c0,0,0,0.01,0,0.01c0,0.01,0,0.02,0,0.03
                    c0,0,0,0,0,0.01c0,0.01,0,0.02,0.01,0.03c0,0,0,0,0,0c0,0.02,0.01,0.04,0.02,0.06c0,0.01,0,0.01,0.01,0.02c0,0,0,0,0,0
                    c0.01,0.01,0.01,0.02,0.02,0.03c0,0,0,0,0,0c0,0,0,0,0,0c0.01,0.01,0.02,0.03,0.03,0.04c0,0,0.01,0.01,0.01,0.01
                    c0.01,0.01,0.02,0.02,0.02,0.03c0,0,0.01,0.01,0.01,0.01c0.01,0.01,0.02,0.02,0.04,0.03c0,0,0.01,0.01,0.01,0.01
                    c0.01,0,0.01,0.01,0.02,0.01l3.4,2.04L3.2,20c-0.16,0.16-0.16,0.42,0,0.58c0.08,0.08,0.18,0.12,0.29,0.12
                    c0.1,0,0.21-0.04,0.29-0.12l0.4-0.4l2.04,3.4c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0.01,0.01,0.02,0.03,0.03,0.04c0,0,0.01,0.01,0.01,0.01
                    c0.01,0.01,0.02,0.02,0.03,0.03c0,0,0.01,0.01,0.01,0.01c0.01,0.01,0.02,0.02,0.04,0.03c0,0,0.01,0.01,0.01,0.01
                    c0.01,0.01,0.01,0.01,0.02,0.02c0,0,0,0,0.01,0c0.01,0.01,0.02,0.01,0.03,0.02c0.01,0,0.01,0.01,0.02,0.01
                    c0.01,0,0.02,0.01,0.03,0.01c0.01,0,0.02,0.01,0.02,0.01c0.01,0,0.02,0,0.02,0c0.01,0,0.02,0,0.03,0.01c0.01,0,0.02,0,0.03,0
                    c0.01,0,0.01,0,0.02,0c0,0,0,0,0,0s0,0,0,0c0.01,0,0.01,0,0.02,0c0.01,0,0.02,0,0.03,0c0.01,0,0.02,0,0.03-0.01
                    c0.01,0,0.02,0,0.02,0c0.01,0,0.02-0.01,0.02-0.01c0.01,0,0.02-0.01,0.03-0.01c0.01,0,0.01-0.01,0.02-0.01
                    c0.01,0,0.02-0.01,0.03-0.02c0,0,0,0,0.01,0c0.01,0,0.02-0.01,0.02-0.02c0,0,0.01,0,0.01-0.01c0.01-0.01,0.02-0.02,0.04-0.03
                    c0,0,0.01-0.01,0.01-0.01c0.01-0.01,0.02-0.02,0.03-0.03c0,0,0.01-0.01,0.01-0.01c0.01-0.01,0.02-0.03,0.03-0.04c0,0,0,0,0,0l0,0
                    c0,0,0,0,0,0l1.15-1.92c0,0,0-0.01,0.01-0.01c0-0.01,0.01-0.01,0.01-0.02c0-0.01,0.01-0.01,0.01-0.02c0-0.01,0-0.01,0.01-0.02
                    c0-0.01,0-0.01,0.01-0.02c0-0.01,0-0.01,0.01-0.02c0-0.01,0-0.01,0-0.02c0-0.01,0-0.01,0-0.02c0-0.01,0-0.01,0-0.02
                    c0-0.01,0-0.01,0-0.02c0-0.01,0-0.01,0-0.02c0-0.01,0-0.01,0-0.02C8.12,21.42,8.12,21.41,8.12,21.4z M1.03,5.62l0.66-0.66
                    l11.39,2.85l-3.86,3.86L1.03,5.62z M6.56,22.57l-1.79-2.99l1.7-1.7c0.16-0.16,0.16-0.42,0-0.58c-0.16-0.16-0.42-0.16-0.58,0
                    L4.19,19L1.2,17.21l1.18-0.71l2.13,0.71c0,0,0,0,0,0c0.02,0.01,0.04,0.01,0.06,0.01c0,0,0.01,0,0.01,0c0.02,0,0.03,0,0.05,0
                    c0,0,0,0,0.01,0c0,0,0,0,0.01,0c0.02,0,0.03,0,0.05,0c0.01,0,0.01,0,0.02,0c0.02,0,0.03-0.01,0.05-0.01c0,0,0.01,0,0.01,0
                    c0.02-0.01,0.03-0.01,0.05-0.02c0,0,0.01,0,0.01,0c0.02-0.01,0.03-0.02,0.05-0.03c0,0,0.01-0.01,0.01-0.01
                    c0.01-0.01,0.02-0.02,0.03-0.03c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0L16.85,5.18c0.74-0.74,2.06-0.95,2.74-1.01
                    c-0.06,0.68-0.27,2-1.01,2.74c0,0,0,0,0,0L6.66,18.84c0,0,0,0,0,0c-0.01,0.01-0.01,0.01-0.02,0.02c0,0-0.01,0.01-0.01,0.01
                    c0,0.01-0.01,0.01-0.01,0.02c0,0.01-0.01,0.01-0.01,0.02c0,0-0.01,0.01-0.01,0.01c0,0.01-0.01,0.01-0.01,0.02c0,0,0,0.01-0.01,0.01
                    c0,0.01-0.01,0.02-0.01,0.02c0,0,0,0.01,0,0.01c0,0.01-0.01,0.02-0.01,0.02c0,0.01,0,0.01,0,0.02c0,0.01,0,0.01,0,0.02
                    c0,0.01,0,0.01,0,0.02c0,0.01,0,0.01,0,0.02c0,0.01,0,0.02,0,0.02c0,0,0,0.01,0,0.01c0,0.01,0,0.02,0,0.02c0,0.01,0,0.01,0,0.02
                    c0,0.01,0,0.01,0,0.02c0,0.01,0,0.01,0,0.02c0,0.01,0,0.01,0,0.02c0,0.01,0,0.02,0.01,0.02c0,0,0,0,0,0.01l0.71,2.13L6.56,22.57z"   
                />
                <path 
                    fill={color} 
                    d="M19.66,22.16c0-0.01,0-0.01,0-0.02c0-0.01,0-0.02,0-0.02c0,0,0-0.01,0-0.01l-2.62-10.46
                    c-0.05-0.22-0.28-0.35-0.49-0.3c-0.22,0.05-0.35,0.28-0.3,0.49l2.56,10.24l-0.66,0.66l-5.28-7.14c-0.13-0.18-0.39-0.22-0.57-0.09
                    c-0.18,0.13-0.22,0.39-0.09,0.57l5.56,7.52c0,0,0,0,0,0.01c0.01,0.01,0.01,0.02,0.02,0.02c0,0,0.01,0.01,0.01,0.01c0,0,0,0,0,0
                    c0.01,0.01,0.01,0.01,0.02,0.02c0,0,0.01,0.01,0.01,0.01c0.01,0.01,0.02,0.02,0.04,0.03c0,0,0.01,0,0.01,0.01
                    c0.01,0.01,0.02,0.01,0.03,0.02c0,0,0.01,0,0.01,0.01c0.01,0,0.02,0.01,0.03,0.01c0,0,0.01,0,0.01,0.01c0.01,0,0.02,0.01,0.03,0.01
                    c0,0,0.01,0,0.01,0c0.01,0,0.02,0,0.03,0.01c0,0,0.01,0,0.01,0c0.01,0,0.03,0,0.04,0c0,0,0,0,0,0c0,0,0,0,0,0c0.02,0,0.04,0,0.05,0
                    c0,0,0.01,0,0.01,0c0.02,0,0.04-0.01,0.05-0.01c0,0,0.01,0,0.01,0c0.02-0.01,0.03-0.01,0.05-0.02c0,0,0,0,0,0
                    c0.02-0.01,0.03-0.02,0.05-0.03c0,0,0,0,0,0c0,0,0,0,0.01-0.01c0.01,0,0.01-0.01,0.02-0.01c0.01,0,0.01-0.01,0.02-0.01
                    c0,0,0,0,0.01-0.01l1.15-1.15c0,0,0,0,0.01-0.01c0.01-0.01,0.01-0.01,0.01-0.02c0-0.01,0.01-0.01,0.01-0.02
                    c0,0,0.01-0.01,0.01-0.01c0-0.01,0.01-0.01,0.01-0.02c0,0,0.01-0.01,0.01-0.01c0-0.01,0.01-0.01,0.01-0.02c0,0,0-0.01,0.01-0.01
                    c0-0.01,0.01-0.01,0.01-0.02c0-0.01,0-0.01,0.01-0.02c0-0.01,0-0.01,0.01-0.02c0-0.01,0-0.01,0-0.02c0-0.01,0-0.01,0-0.02
                    c0-0.01,0-0.01,0-0.02c0-0.01,0-0.01,0-0.02c0-0.01,0-0.01,0-0.02c0-0.01,0-0.01,0-0.02c0-0.01,0-0.01,0-0.02
                    C19.66,22.17,19.66,22.17,19.66,22.16z"
                />
                <path 
                    fill={color} 
                    d="M22.84,11.53c0.04-0.12-0.04-0.25-0.16-0.29c-0.06-0.02-0.12-0.01-0.18,0.02c-0.05,0.03-0.09,0.08-0.11,0.14
                    c-0.02,0.06-0.01,0.12,0.02,0.18c0.03,0.05,0.08,0.09,0.14,0.11c0.02,0.01,0.04,0.01,0.06,0.01C22.71,11.7,22.81,11.64,22.84,11.53
                    z"
                />
                <path 
                    fill={color} 
                    d="M22.85,11.63c-0.06,0.02-0.1,0.07-0.12,0.13c-0.02,0.06-0.02,0.12,0,0.18c0.11,0.24,0.11,0.57,0.03,0.89
                    c-0.02,0.06-0.01,0.12,0.02,0.18s0.08,0.09,0.14,0.11c0.02,0.01,0.04,0.01,0.06,0.01c0.1,0,0.2-0.07,0.23-0.17
                    c0.12-0.41,0.1-0.86-0.05-1.2C23.11,11.63,22.97,11.58,22.85,11.63z"
                />
                <path 
                    fill={color} 
                    d="M23.89,11.54c-0.17-0.48-0.49-0.81-0.91-0.93c-0.29-0.08-0.56,0.01-0.76,0.09l0.05-0.18
                    c0.09-0.31,0.3-0.57,0.58-0.73c0.11-0.06,0.2-0.17,0.23-0.29c0.04-0.12,0.02-0.25-0.04-0.37c-0.06-0.11-0.17-0.2-0.29-0.23
                    c-0.12-0.04-0.25-0.02-0.37,0.04c-0.3,0.17-0.56,0.4-0.75,0.68c-0.2-0.5-0.61-0.88-1.13-1.03l-0.24-0.07l-0.01,0
                    c-0.12-0.03-0.25,0.04-0.28,0.16L19.9,8.93c-0.1,0.35-0.09,0.71,0.03,1.05c-0.24,0.08-0.47,0.23-0.67,0.44
                    c-0.27,0.27-0.48,0.64-0.6,1.03c-0.16,0.55-0.17,1.33-0.02,1.98c0.18,0.81,0.57,1.34,1.09,1.49c0.12,0.03,0.45,0,0.57-0.03
                    c0.09-0.03,0.15-0.01,0.19,0.01c0.02,0.01,0.03,0.01,0.05,0.01c0.06,0.01,0.12,0.04,0.16,0.09c0.08,0.09,0.34,0.29,0.46,0.33
                    c0.1,0.03,0.2,0.04,0.31,0.04c0.44,0,0.93-0.25,1.4-0.73c0.47-0.47,0.88-1.14,1.03-1.69C24.04,12.48,24.03,11.97,23.89,11.54z
                     M21.67,10.33c0.13-0.44,0.7-1.17,0.94-0.96c0.1,0.09,0,0.22-0.01,0.22c-0.39,0.22-0.53,0.47-0.66,0.91l-0.08,0.33
                    c-0.01,0-0.32-0.11-0.33-0.11L21.67,10.33z M20.71,9.8l0.08-0.08l-0.08-0.51c0.4,0.26,0.63,0.73,0.56,1.22
                    c-0.15-0.21-0.38-0.5-0.87-0.52c-0.07-0.15-0.1-0.31-0.1-0.47L20.71,9.8z M23.45,12.82c-0.14,0.48-0.5,1.07-0.92,1.49
                    c-0.26,0.27-0.77,0.69-1.24,0.57c-0.05-0.02-0.21-0.14-0.25-0.19c-0.11-0.13-0.25-0.21-0.42-0.25c-0.15-0.06-0.33-0.06-0.48-0.01
                    c-0.06,0.02-0.25,0.03-0.31,0.03c-0.46-0.14-0.67-0.77-0.75-1.14c-0.13-0.58-0.12-1.27,0.01-1.75c0.19-0.65,0.68-1.15,1.17-1.19
                    l0,0c0.09-0.01,0.19,0,0.28,0.03c0.16,0.05,0.27,0.2,0.37,0.34c0.06,0.08,0.11,0.15,0.17,0.21c0.27,0.25,0.63,0.35,0.99,0.29
                    c0.08-0.02,0.17-0.05,0.26-0.09c0.17-0.07,0.34-0.14,0.5-0.1c0.27,0.08,0.48,0.3,0.6,0.63C23.56,12.03,23.56,12.44,23.45,12.82z"
                />
            </g>
        </svg>
    )
}
export default TravelIcon;
