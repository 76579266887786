import React, { Component } from 'react';
import Modal from "../../../../Modal";
import './style.css';
import Icon from 'react-ionicons'

class ModalRequireMedia extends Component{

    requireMediaSelect = async (e, type) =>{
        e.preventDefault();
        //console.log(type);
        const mediaList = document.querySelectorAll('.buy-media-list');
        const eventMediaList = e.target.closest(".buy-media-list");
        const keyNoChange = eventMediaList.getAttribute('data-key');
        mediaList.forEach((item, index) => {
            if (item.getAttribute('data-key') === keyNoChange) {
                item.classList.add('select');
            }
            else{
                item.classList.remove('select');
            }
        });
        this.props.setRequestMedia(type)
    }

    drawPricesModal() {
        const prices = [...this.props.pricesmodal];
        return prices.map((item, index) => {
            if(item.id === 2){
                return null
            }
            let show = false;
            let icon = "md-mic";
            if (item.id === 3 && this.props.showModule["audio-message-req"]) {
                show = true;
                icon = "md-mic";
            } else if (item.id === 4 && this.props.showModule["image-message-req"]) {
                show = true;
                icon = "md-image"
            } else if (item.id === 5 && this.props.showModule["video-message-req"]) {
                show = true;
                icon = "md-videocam"
            }
            if (show) {
                return (
                    <li key={index} className='buy-media-list' data-key={index}
                        onClick={(e) => this.requireMediaSelect(e, item.id)}>
                        <button style={{
                            backgroundColor: this.props.config.color.secondary,
                            color: this.props.config.color.secondaryText
                        }}>
                            <Icon icon={icon} color={this.props.config.color.secondaryText}/>
                        </button>
                        <span>{(item.id === 3) ? this.props.dictionary.audio : ((item.id === 4) ? this.props.dictionary.images : this.props.dictionary.video)} {this.props.dictionary.for} {item.monedas} {this.props.dictionary.coins}
                        </span>
                    </li>
                )
            } else {
                return null;
            }
        })
    }

    render(){
        //let coins = this.props.currentCoins;
        let dictionary = this.props.dictionary;
        let config = this.props.config;
        return(
            <div>
                <Modal
                    id="ModalRequireMedia"
                    head={<p></p>}
                    config={this.props.config}
                    footer={
                        (this.props.typeMultimediaToBuy) ?
                            <div className="actions">
                                <button
                                    style={{
                                        backgroundColor: config.color.secondary,
                                        color: config.color.secondaryText
                                    }}
                                    onClick={(e) => this.props.requireMedia(e)}
                                >
                                    {dictionary.buy}
                                </button>
                            </div>
                            :
                            null

                    }
                    open={this.props.open}
                    closeAction={this.props.close}
                >
                    <div>
                        <p>
                            {dictionary.stfMediaMessage}
                        </p>
                        <ul className="modal-list">
                            {this.drawPricesModal()}
                        </ul>
                    </div>

                </Modal>
            </div>
        )
    }
}

export default ModalRequireMedia;
