import React, { Component } from 'react';
import Modal from "../../../Modal"
import axios from 'axios';



class MarkAsResolve extends Component {

    constructor(props) {
        super(props);
        this.state = {
            model: false
        }
    }

    markAsResolve = async () => {
        let message = this.props.messageToMarkResolved.msg;
        await axios.post('/api/v2/message/answered/', { message: message })
            .then((res) => {
                this.props.markMessageAsResolve(message);
                this.props.close()
            })
    }

    render() {
        return (
            <Modal
                id={"modalMarkAsResolve"}
                head={null}
                config={this.props.config}
                footer={
                    null
                }
                closeAction={() => this.props.close()}
                open={this.props.open}
            >
                {this.props.dictionary.markAsAnsweredTitle}
                <ul className="delete-option-button">
                    <li>
                        <button
                            onClick={() => this.markAsResolve()}
                            style={{
                                backgroundColor: this.props.config.color.secondary,
                                color: this.props.config.color.secondaryText
                            }}
                        >
                            {this.props.dictionary.markAsAnswered}
                        </button>
                    </li>
                </ul>
            </Modal>
        );
    }
}

export default MarkAsResolve;
