import React, { Component } from 'react';
import axios from 'axios';
import Ionicon from 'react-ionicons';
import SideNav from '../../components/NavBar/SideNav';
import "../Login/styleLogin.css";
class ChangePassword extends Component {
    constructor(props){
        super(props);
        this.changeHandler=this.changeHandler.bind(this);
        this.state={
            newPassword: '',
            confNewPassword: '',
            viewPass: false,
            viewPassRepeat: false,
            passwordError: false,
            repeatpwdError: false,
        }
    }
    changePassword=async()=>{
        let error=false;
        document.getElementById("input_1").classList.remove("sign-error");
        document.getElementById("input_2").classList.remove("sign-error");
        this.setState({
            passwordError:false,
            repeatpwdError: false
        });
        if (this.state.newPassword.length < 6){
            document.getElementById("input_1").classList.add("sign-error");
            document.getElementById("input_1").focus();
            await this.setState({passwordError: this.props.dictionary.passwordError});
            error=true;
        }
       if(!this.state.confNewPassword || this.state.confNewPassword !== this.state.newPassword){
            document.getElementById("input_2").classList.add("sign-error");
            document.getElementById("input_2").focus();
            await this.setState({repeatpwdError: this.props.dictionary.repasswordError});
            error=true;
        }
        if(error) return;
        const newPassword={
            newPassword: this.state.newPassword
        }
        await axios.post('/api/v2/changePasswordProfile/',newPassword)
            .then(change=>{
                if(change.data==="OK"){
                    alert('Password successfully changed!');
                    window.location.href='/profile';
                }

            })

    }
    keyPress(event) {
        if (event.key === 'Enter') {
            this.changePassword()
        }
    }
    changeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    viewPassword(){
        this.setState({viewPass:!this.state.viewPass})
    }
    viewRepeatPassword(){
        this.setState({viewPassRepeat:!this.state.viewPassRepeat})
    }
    render(){
        return(
            <div className="redesign"
                style={{
                        backgroundColor : this.props.config.color.navbar
                    }}
            >
                <SideNav
                    toElevate={this.props.toElevate}
                    dictionary={this.props.dictionary}
                    config={this.props.config}
                />
                <div
                    className="container main-container center-align redesign-log-in"
                    style={{
                        backgroundColor : this.props.config.color.principal,
                        color: this.props.config.color.principalText
                    }}
                >
                    <h5>
                        {this.props.dictionary.changePassword}
                    </h5>
                    <div className="col input-field-login">
                        <label htmlFor="input_1">
                            <Ionicon
                                icon='ios-lock'
                                fontSize="25px"
                                color="rgb(92, 92, 92)"
                            />
                            {this.props.dictionary.newpw}
                        </label>
                        <input
                            id="input_1"
                            onKeyPress={this.keyPress}
                            type={this.state.viewPass ? 'text' : 'password'}
                            label="New password"
                            name='newPassword'
                            minLength="6"
                            maxLength="40"
                            onChange={this.changeHandler}
                            value={this.state.newPassword}
                            className="register-inputs validate"
                        />
                        {this.state.passwordError ?
                            <div className="log-error-class">{this.state.passwordError}</div>
                        : null}
                        <span onClick={()=>this.viewPassword()}>
                            <Ionicon
                                icon={this.state.viewPass?'md-eye-off':'md-eye'}
                                fontSize="25px"
                                color="rgb(92, 92, 92)"
                                small
                                className="icon-view-pwd"
                            />
                        </span>
                    </div>
                    <div className="col input-field-login">
                        <label htmlFor="input_2">
                            <Ionicon
                                icon='ios-lock'
                                fontSize="25px"
                                color="rgb(92, 92, 92)"
                            />
                            {this.props.dictionary.repeatPwd}
                        </label>
                        <input
                            id="input_2"
                            onKeyPress={this.keyPress}
                            type={this.state.viewPassRepeat ? 'text' : 'password'}
                            minLength="6"
                            maxLength="40"
                            label="Confirm your new password"
                            name='confNewPassword'
                            onChange={this.changeHandler}
                            value={this.state.confNewPassword}
                            className="register-inputs validate"
                        />
                        {this.state.repeatpwdError ?
                            <div className="log-error-class">{this.state.repeatpwdError}</div>
                        : null}
                        <span onClick={()=>this.viewRepeatPassword()}>
                            <Ionicon icon={this.state.viewPassRepeat ? 'md-eye-off' : 'md-eye'}
                                fontSize="25px"
                                color="rgb(92, 92, 92)"
                                small
                                className="icon-view-pwd"
                            />
                        </span>
                    </div>
                    <div className="center-align">
                        <button
                            onClick={this.changePassword}
                            className="log-btn"
                            style={{
                                    backgroundColor: this.props.config.color.secondary,
                                    color: this.props.config.color.secondaryText
                                }}
                        >
                            {this.props.dictionary.send}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
export default ChangePassword;
