import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Circle } from 'react-preloaders';
import PrivacyEnglish from "./PrivacyEnglish";
import PrivacySpanish from "./PrivacySpanish";
import SideNav from '../../components/NavBar/SideNav';
import FooterCompliances from '../../components/Footer/FooterCompliances';
class Privacy extends Component {
    fireTracking = () => {
      ReactGA.pageview(window.location.pathname);
    }
    renderObject(){
        if(this.props.config.length===0 ) return <div className="center-align" style={{paddingTop:45}}><Circle background="transparent !important" color="#4285f4" className="center-align"/></div>;
        let Privacy=[];
        let hostname = window.location.hostname;
        switch (this.props.dictionary.languageKey) {
            case "es":
                Privacy = <PrivacySpanish hostname={hostname} />;
                break;
            case "en":
                Privacy = <PrivacyEnglish hostname={hostname} />;
                break
            default:
                Privacy = <PrivacyEnglish hostname={hostname} />;
                break;
        }
        return Privacy;
    }
    render(){
        this.fireTracking();
        return(
            <div
                className="redesign"
                style={{
                    backgroundColor:this.props.config.color.navbar
                }}
            >
                <SideNav toElevate={this.props.toElevate} dictionary={this.props.dictionary} config={this.props.config}/>

                <div
                    id="container-support"
                    className="container main-container center-align"
                    style={{
                        zIndex: 0,
                        backgroundColor : this.props.config.color.principal,
                        color: this.props.config.color.principalText
                    }}
                >
                    {this.renderObject()}
                </div>

                <FooterCompliances dictionary={this.props.dictionary} color={this.props.config.color}/>
            </div>
        )
    }
}
export default Privacy;
