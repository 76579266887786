import React, { Component } from 'react';
import { Circle } from 'react-preloaders';
import SideNav from '../../../../components/NavBar/SideNav';
class GetMembership extends Component {
    renderObject(){
        if(this.props.dictionary.length===0 || this.props.config.length===0 ) return <div className="center-align" style={{paddingTop:45}}><Circle background="transparent !important" color="#4285f4" className="center-align"/></div>;
        return(
            <div
                id="container-support"
                className="container main-container center-align"
                style={{
                    backgroundColor : this.props.config.color.principal,
                    color: this.props.config.color.principalText
                }}
            >
                <h5>{this.props.dictionary.FAQ.q7}</h5>
                <div className="faq-steps ">
                    <ol>
                        <li>
                            {this.props.dictionary.FAQ.q7p1} <a href="./create_account">{this.props.dictionary.FAQ.q1}</a>
                        </li>
                        <li>
                            {this.props.dictionary.FAQ.q7p2}
                        </li>
                        <li>
                            {this.props.dictionary.FAQ.q7p3}
                        </li>
                        <li>
                            {this.props.dictionary.FAQ.q7p4}
                        </li>
                        <li>
                            {this.props.dictionary.FAQ.q7p5}
                        </li>
                        <li>
                            {this.props.dictionary.FAQ.q7p6} <a href="https://epoch.com/billingsupport">https://epoch.com/billingsupport</a>
                        </li>
                    </ol>
                </div>
            </div>
        )
    }
    render(){
        return(
            <div
                className="redesign"
                style={{
                    backgroundColor:this.props.config.color.navbar
                }}
            >
                <SideNav toElevate={this.props.toElevate} dictionary={this.props.dictionary} config={this.props.config} />
                {this.renderObject()}
            </div>
        )
    }
}
export default GetMembership;
