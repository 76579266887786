import React, { Component } from 'react';
import ReactGA from 'react-ga';
import SideNav from '../../components/NavBar/SideNav';
import FooterCompliances from '../../components/Footer/FooterCompliances';

import { Circle } from 'react-preloaders';
import TermsEnglish from "./TermsEnglish";
import TermsSpanish from "./TermsSpanish";
import "./style.css";
class Terms extends Component {
    fireTracking = () => {
      ReactGA.pageview(window.location.pathname);
    };
    renderTerms(){
        if (this.props.config.length === 0) return <div className="center-align" style={{ paddingTop: 45 }}><Circle background="transparent !important" color="#4285f4" className="center-align" /></div>;
        let Terms = [];
        let hostname = window.location.hostname;
        switch (this.props.dictionary.languageKey) {
            case "es":
                Terms = <TermsSpanish hostname={hostname} color={this.props.config.color} />;
                break;
            case "en":
                Terms = <TermsEnglish hostname={hostname} color={this.props.config.color} />;
                break
            default:
                Terms = <TermsEnglish hostname={hostname} color={this.props.config.color}/>;
                break;
        }
        return Terms
    }
    render(){
        this.fireTracking();
        return(
            <div
                className="redesign"
                style={{
                    backgroundColor:this.props.config.color.navbar,
                    color: this.props.config.color.principalText
                }}
            >
                <SideNav
                    toElevate={this.props.toElevate}
                    dictionary={this.props.dictionary}
                    config={this.props.config}
                />
                {this.renderTerms()}
                <FooterCompliances
                    dictionary={this.props.dictionary}
                    color={this.props.config.color}
                />
            </div>
        )
    }
}
export default Terms;
