import React, {Component} from 'react';
import { Circle } from 'react-preloaders';
import {Link} from 'react-router-dom';
import Ionicon from 'react-ionicons';
import SideNav from '../../components/NavBar/SideNav';
import axios from 'axios'
import cookie from 'js-cookie';
import getParams from '../../components/SearchParams/SearchParams';
import PersonIcon from '../../components/Svg/PersonIcon';
import LockIcon from '../../components/Svg/LockIcon';
import MailIcon from '../../components/Svg/MailIcon';

import '../Login/styleLogin.css';

class Register extends Component {
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.register = this.register.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.state = {
            dictionary: [],
            config: [],
            username: '',
            email: '',
            password: '',
            repassword:'',
            usernameError: false,
            emailError: false,
            passwordError: false,
            repeatpwdError: false,
            sendRecoverMessage: false,
            viewPass: false,
            viewPassRepeat: false
        }
    }
    componentDidMount=async ()=> {
        this.setState({ dictionary: this.props.dictionary, config: this.props.config});
    }
    keyPress(event) {
        if (event.key === 'Enter') {
            this.register();
        }
    }
    changeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }
    register = async (username, email, password,repassword) => {
        const params = getParams();
        const promo = params.promo;
        let error=false;
        document.getElementById("input_4").classList.remove("sign-error");
        document.getElementById("input_5").classList.remove("sign-error");
        document.getElementById("input_6").classList.remove("sign-error");
        document.getElementById("input_7").classList.remove("sign-error");

        await  this.setStateAsync({
            username: this.state.username.trim()
        });
        this.setState({
            usernameError: false,
            emailError:false,
            passwordError:false,
            repeatpwdError: false
        });

        if (this.state.username.length <= 4 || !this.state.username.match(/^[a-z0-9]+$/i)){
            document.getElementById("input_4").classList.add("sign-error");
            document.getElementById("input_4").focus();
            await this.setState({usernameError: this.props.dictionary.usernameError});
            error=true;
        }
        if(!this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
        {
            document.getElementById("input_5").classList.add("sign-error");
            document.getElementById("input_5").focus();
            await this.setState({emailError: this.props.dictionary.emailError});
            error=true;
        }
        if (this.state.password.length < 6){
            document.getElementById("input_6").classList.add("sign-error");
            document.getElementById("input_6").focus();
            await this.setState({passwordError: this.props.dictionary.passwordError});
            error=true;
        }
       if(!this.state.repassword || this.state.repassword !== this.state.password){
            document.getElementById("input_7").classList.add("sign-error");
            document.getElementById("input_7").focus();
            await this.setState({repeatpwdError: this.props.dictionary.repasswordError});
            error=true;
        }
        if(error) return;
        const keyCountry = await this.getLocation();
        const user = {
            username: this.state.username.trim(),
            email: this.state.email.trim(),
            password: this.state.password,
            location: keyCountry
        }
        axios.post('/api/v2/register', user).then(res => {
            const validRegistration = res.data.valid;
            if (validRegistration) {
                cookie.set('username', this.state.username,{ expires: 30  });
                cookie.set('token', res.data.token,{ expires: 30  });
                cookie.set('email', res.data.email,{ expires: 30  });
                window.location = '/'+(promo ? '?promo='+promo : '');
            } else {
                this.setState({
                    usernameError: res.data.username
                        ? this.props.dictionary.usrNotAvail
                        : '',
                    emailError: res.data.email
                        ? this.props.dictionary.mailNotAvail
                        : ''
                });

            }
        })
    };
    getLocation = async()=>{
        return await axios.get('https://us-central1-private-social-media.cloudfunctions.net/locate')
        .then(res => {
            return res.data.country;
        });
    }
    viewPassword(){
        this.setState({viewPass:!this.state.viewPass})
    }
    viewRepeatPassword(){
        this.setState({viewPassRepeat:!this.state.viewPassRepeat})
    }

    render() {
        if(this.state.dictionary.length===0 || this.state.config.length===0 ) return <div className="center-align" style={{paddingTop:45}}><Circle background="transparent !important" color="#4285f4" className="center-align"/></div>;
        return (
        <div
            className="redesign"
            style={{
                backgroundColor:this.props.config.color.navbar
            }}
        >
            <SideNav toElevate={this.props.toElevate} dictionary={this.props.dictionary} config={this.props.config}/>
            <div
                id="redesign-login-register"
                className="container main-container center-align redesign-login-container"
                style={{
                    zIndex: 0,
                    backgroundColor : this.props.config.color.principal,
                    color: this.props.config.color.principalText
                }}
            >
                <div className='redesign-log-in'>
                    <h5>{this.state.dictionary.welcome}</h5>
                    <div className="col input-field-login">
                        <label htmlFor="input_4">
                            <PersonIcon
                                color={"rgb(92, 92, 92)"}
                                width={"20px"}
                                height={"20px"}
                            />
                            {this.props.dictionary.name}
                        </label>
                        <input
                            name="username"
                            style={{color:this.props.config.color.principalText}}
                            id="input_4"
                            minLength="4"
                            maxLength="40"
                            onKeyPress={this.keyPress}
                            className="register-inputs validate"
                            required
                            onChange={this.changeHandler}
                            type="text"
                            value={this.state.username}
                            placeholder={this.props.dictionary.name}
                        />
                        {this.state.usernameError ?
                            <div className="log-error-class">{this.state.usernameError}</div>
                        : null}
                    </div>

                    <div className="col input-field-login">
                        <label htmlFor="input_5">
                                <MailIcon
                                    color={"rgb(92, 92, 92)"}
                                    width={"20px"}
                                    height={"20px"}
                                />
                                {this.props.dictionary.email}
                            </label>
                        <input
                            style={{color:this.props.config.color.principalText}}
                            name="email"
                            id="input_5"
                            minLength="4"
                            maxLength="255"
                            onKeyPress={this.keyPress}
                            className="register-inputs validate"
                            required
                            onChange={this.changeHandler}
                            type='email'
                            value={this.state.email}
                            placeholder="email@gmail.com"
                        />
                        {this.state.emailError ?
                            <div className="log-error-class">{this.state.emailError}</div>
                        : null}
                    </div>
                    <div style={{position:'relative'}}>
                        <div className="col input-field-login">
                            <label htmlFor="input_6">
                                <LockIcon
                                    color={"rgb(92, 92, 92)"}
                                    width={"20px"}
                                    height={"20px"}
                                />
                                {this.state.dictionary.password}
                            </label>
                            <input
                                style={{color:this.props.config.color.principalText, paddingRight: 33}}
                                name="password"
                                id="input_6"
                                minLength="6"
                                maxLength="40"
                                onKeyPress={this.keyPress}
                                className="register-inputs validate"
                                required
                                onChange={this.changeHandler}
                                type={this.state.viewPass ? 'text' : 'password'}
                                value={this.state.password}
                                placeholder={"+6 "+this.props.dictionary.chars}
                            />
                            {this.state.passwordError ?
                                <div className="log-error-class">{this.state.passwordError}</div>
                            : null}
                            <span onClick={()=>this.viewPassword()}>
                                <Ionicon icon={this.state.viewPass?'ios-eye-off-outline':'ios-eye-outline'}
                                    fontSize="25px"
                                    color="rgb(92, 92, 92)"
                                    small
                                    className="icon-view-pwd"
                                />
                            </span>
                        </div>
                    </div>
                    <div className="col input-field-login">
                        <label htmlFor="input_7">
                            <LockIcon
                                color={"rgb(92, 92, 92)"}
                                width={"20px"}
                                height={"20px"}
                            />
                            {this.state.dictionary.repeatPwd}
                        </label>
                        <input
                            style={{color:this.props.config.color.principalText, paddingRight: 33}}
                            name="repassword"
                            id="input_7"
                            minLength="6"
                            maxLength="40"
                            onKeyPress={this.keyPress}
                            className="register-inputs validate"
                            required
                            onChange={this.changeHandler}
                            type={this.state.viewPassRepeat ? 'text' : 'password'}
                            value={this.state.repassword}
                            placeholder={"+6 "+this.props.dictionary.chars}
                        />
                        {this.state.repeatpwdError ?
                            <div className="log-error-class">{this.state.repeatpwdError}</div>
                        : null}
                        <span onClick={()=>this.viewRepeatPassword()}>
                            <Ionicon icon={this.state.viewPassRepeat ? 'ios-eye-off-outline':'ios-eye-outline'}
                                fontSize="25px"
                                color="rgb(92, 92, 92)"
                                small
                                className="icon-view-pwd"
                            />
                        </span>
                    </div>

                    <div className="center-align">
                        <button
                            style={{
                                backgroundColor: this.state.config.color.secondary,
                                color: this.state.config.color.secondaryText
                            }}
                            className="log-btn"
                            onClick={this.register}
                        >
                            {this.state.dictionary.createAccount}
                        </button>
                    </div>
                    <div
                        className='terms-text'
                        style={{
                            color:'#7c7c7c',
                            paddingTop:5
                        }}
                    >
                        {this.props.dictionary.aceptTerms}
                        <Link
                            style={{
                                color: this.props.config.color.secondary
                            }}
                            to='/terms'> {this.props.dictionary.terms}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        )
    }

}
export default Register;
