import React, { Component } from 'react';
import axios from 'axios';
//import { Link } from 'react-router-dom';
import { Circle } from 'react-preloaders';
import moment from 'moment/min/moment-with-locales';
import ReactGA from 'react-ga';
import './Activity.css';
import SideNav from '../../components/NavBar/SideNav';
import CoinsIcon from '../../components/Svg/CoinsIcon';
import Ionicon from 'react-ionicons';
import SectionIcon from '../../components/Svg/SectionIcon';
import toolbox from '../../components/Toolbox/Toolbox';

class Activity extends Component {

    constructor(props) {
        super(props);
        this.fireTracking();
        this.state={
            loading: false,
            activityFeatures:[],
            activitySubs: [],
            dictionary: [],
            config: [],
            offset: 0
        }
        this.getLocalDictionary = this.getLocalDictionary.bind(this);
        this.getActivity = this.getActivity.bind(this);
        this.getSubsActivity = this.getSubsActivity.bind(this);
    }
    componentDidMount(){
        this.setState({ dictionary: this.props.dictionary, config: this.props.config, benefits:true });
        this.getActivity(this.state.offset)
        .then(activity => {
            this.setState({activityFeatures: activity, offset: 15 });
        }).catch(err => {
            toolbox.sendError(err);
        });
        let languageChange = localStorage.getItem('dictionary');
        this.getLocalDictionary(languageChange);
        this.getSubsActivity();
        document.addEventListener("scroll", this.trackScrolling);
    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling)
    }
    fireTracking = () => {
        ReactGA.pageview(window.location.pathname);
    }
    trackScrolling = (e) => {
        const wrappedElement = document.querySelector('.main-container');
        let scroll = window.scrollY;

        if(scroll > 0 && !this.state.loading && !this.state.showUpBtn)
            this.setState({showUpBtn: true});

        if(scroll <= 0 && this.state.showUpBtn)
            this.setState({showUpBtn: false});

        if (wrappedElement && toolbox.isBottom(wrappedElement)) {
            this.loadMoreActivities();
        }
    }
    getLocalDictionary(languageChange){
        var extract = languageChange.slice(0, 12);
        const dict = {
                extract: extract
            }
        return axios.post('/api/v2/get/localDictionary', dict)
            .then(res => {
                if(res.data){
                    if(res.data.lang.code === "MX"){
                        moment.locale('es');
                    } else {
                        moment.locale('en');
                    }
                }
            })
            .catch(err => {
                console.log(err)
            });
    }
    getActivity = async (numOffset) =>{
        const offset =
            {
                offset: numOffset
            }
        return await axios.post('/api/v2/getActivity',offset)
        .then(async res => {
            return res.data;
        })
        .catch(err => {
            throw Error(err);
        });
    }

    getSubsActivity(){
        return axios.post('/api/v2/getSubsActivity')
        .then(res => {
            this.setState({activitySubs: res.data});
        })
        .catch(err => {
            throw Error(err);
        });
    }

    loadMoreActivities = async (noMoreOffset = false) => {
        let offset = noMoreOffset ? 0 : this.state.offset;
        if (!this.state.loading) {
            this.setState({loading: true});
            let activityFeatures = await this.getActivity(offset);
            let copyActivity = [...this.state.activityFeatures];
            let newActivity = [];
            if (noMoreOffset) newActivity = activityFeatures;
            else newActivity = copyActivity.concat(activityFeatures);
            this.setState({
                activityFeatures: newActivity,
                offset: noMoreOffset ? offset : offset + 15,
                loading: false
            });
        }
    }

    renderActivities(){
        if(this.state.activityFeatures.length === 0) return <div className="no-activity">{this.props.dictionary.noActivity}.</div>;
        let notification = [...this.state.activityFeatures];
        let subsModel = [...this.state.activitySubs];
        let myFinalList = [];
        if(this.props.permission && this.props.permission === 1){
            notification.forEach(function (element, i) {
                let date = moment(element.created);
                let compareDate = subsModel.length ? moment(subsModel[0].created) : null;
                while (compareDate && compareDate > date) {
                    myFinalList.push(subsModel[0]);
                    subsModel.splice(0, 1);
                    compareDate = subsModel.length ? subsModel[0].created : null;
                }
                myFinalList.push(element)
            }, this);
        } else {
            myFinalList = notification;
        }
        return myFinalList.map((item, index) => {
            let config = this.props.config;
            let image = (item.isMediaBox) ?
                config.storageURL + '/' + config.storage + "/message/model/" + item.postMedia
                : config.storageURL + '/' + config.storage + "/posts/img/" + item.postMedia;
            let iconImage = (item.activity_type === 2) ?
                "md-mic"
                : item.activity_type === 3 ? "md-image"
                : item.activity_type === 4 ? "md-videocam"
                : "md-photos";
            let donateIcon = (item.activity_type === 6) ?
                "PMessageIcon"
                : "StreamIcon";
            let mediaText = (item.isModel === 1) ?
                (item.username + " " + this.props.dictionary[item.typeName].model + ". ")
                : (this.props.dictionary[item.typeName].client + " " + item.amount + " " + this.props.dictionary.coins + ". ");
            let donationText = (item.isModel === 1) ?
                (item.username + " " + this.props.dictionary.hasDonated + " " + item.amount + " " + this.props.dictionary.coins + " " + this.props.dictionary[item.typeName] + ". ")
                : (this.props.dictionary.Idonated + " " + item.amount + " " + this.props.dictionary.coins + " " + this.props.dictionary[item.typeName] + ". ")
            let donationPostText = (item.isModel === 1) ?
                (item.username + " " + this.props.dictionary.hasDonated + " " + item.amount + " " + this.props.dictionary.coins + " " + this.props.dictionary[item.typeName])
                : (this.props.dictionary.Idonated + " " + item.amount + " " + this.props.dictionary.coins + " " + this.props.dictionary[item.typeName]);
            let dateMoment = moment(item.created).startOf('hour').fromNow();
            return (
                <div key={index} className="activity-notify">

                    {item.activity_type === 1 ?
                        <div className="activity-content">
                            <div
                                className="image icon-donate"
                                style={{borderColor:this.props.config.color.secondary}}
                            >
                                <CoinsIcon
                                    color={this.props.config.color.secondary}
                                    colorPrincipal={this.props.config.color.principal}
                                    width={"20px"}
                                    height={"20px"}
                                />
                            </div>
                            <div className="act-text-box">
                                {this.props.dictionary[item.typeName] + " " + item.amount + " " + this.props.dictionary.coins + ". "}
                                <span>{dateMoment}</span>
                            </div>
                        </div>
                    : (item.activity_type === 2 || item.activity_type === 3 || item.activity_type === 4 || item.activity_type === 5) ?
                        <div className="activity-content">
                            <div
                                className="image icon"
                                style={{borderColor:this.props.config.color.secondary}}
                            >
                                <Ionicon
                                    icon={iconImage}
                                    fontSize="25px"
                                    color={this.props.config.color.secondary}
                                />
                            </div>
                            <div className="act-text-box">
                                {mediaText}
                                <span>{dateMoment}</span>
                            </div>
                        </div>
                    : (item.activity_type === 6 || item.activity_type === 8) ?
                        <div className="activity-content">
                            <div
                                className="image icon-donate"
                                style={{borderColor:this.props.config.color.secondary}}
                            >
                                <SectionIcon
                                    iconName={donateIcon}
                                    color={this.props.config.color.secondary}
                                    width={"20px"}
                                    height={"20px"}
                                />
                            </div>
                            <div className="act-text-box">
                                {donationText}
                                <span>{dateMoment}</span>
                            </div>
                        </div>
                    : item.activity_type === 7 ?
                        <div className="activity-content">
                            <div
                                className="image"
                                style={{borderColor:this.props.config.color.secondary,
                                    backgroundImage:'url('+image+')'}}
                            ></div>
                            <div className="act-text-box">
                                {donationPostText} <b>"{item.post_name.slice(0, 10)}.."</b>.
                                <span>{dateMoment}</span>
                            </div>
                        </div>
                    : (item.activity_type === 9) ?
                        <div className="activity-content">
                            <div
                                className="image icon"
                                style={{borderColor:this.props.config.color.secondary}}
                            >
                                <Ionicon
                                    icon="md-star"
                                    fontSize="25px"
                                    color={this.props.config.color.secondary}
                                />
                            </div>
                            <div className="act-text-box">
                                {item.countUsers + " " + (item.countUsers > 1 ? (this.props.dictionary.userPlural + " " + this.props.dictionary[item.typeName].plural) : (this.props.dictionary.userSingular + " " + this.props.dictionary[item.typeName].singular)) + ". "}
                                <span>{dateMoment}</span>
                            </div>
                        </div>
                    : null }
                </div>
            )
        });
    }

    kFormatter=(num)=> {
        return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
    }
    render() {
        if(this.state.activityFeatures===null ) return <div> <SideNav view={this.props.view ? this.props.view : false} toElevate={this.props.toElevate} dictionary={this.props.dictionary} config={this.props.config}  notification={ this.props.streaming ? {link : "/streaming", message: "Model is streaming NOW!"} : null} /><div className="center-align" style={{paddingTop:45}}><Circle background="transparent !important" color="#4285f4" className="center-align"/></div></div>;
        let loading = this.state.loading
            ? <div className="center-align">
                <Circle background={"transparent !important"} color={this.props.config.color.secondary} className="loaderPost"/>
            </div>
            : null;
        return (
            <div className="redesign"
                style={{
                        backgroundColor : this.props.config.color.navbar
                    }}
            >
                <SideNav
                    style={{height:250}}
                    toElevate={this.props.toElevate}
                    dictionary={this.props.dictionary}
                    config={this.props.config}
                    view={this.props.view ? this.props.view : false}
                    notification={ this.props.streaming ? {link : "/streaming", message: "Model is streaming NOW!"} : null} />
                <div
                    className="container main-container redesign-activity-container"
                    style={{
                        backgroundColor : this.props.config.color.principal,
                        color: this.props.config.color.principalText
                    }}
                >
                    <h5 className='activity-name'>
                        {this.props.dictionary.activity}
                    </h5>
                    <hr
                        style={{
                            backgroundColor: this.props.config.color.principalText,
                            opacity: 0.3
                        }}
                    />
                    <div>
                        {this.renderActivities()}
                        {loading}
                    </div>
                </div>
            </div>
        );
    }
}

export default Activity;
