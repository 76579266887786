import React, {Component} from 'react';
import cookie from 'js-cookie';
import Sidenav from 'sidenavjs';
import { Circle } from 'react-preloaders';
import Ionicon from 'react-ionicons';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
import HeadItems from './HeadItems';
import SideItems from './SideItems';
import HeadItemsLogged from './HeadItemsLogged';
import SideItemsLogged from './SideItemsLogged';
import GetCoins from "../../GetCoins/";
import Advice from '../../Advice';
import Push from 'push.js';
import MenuIcon from '../../Svg/MenuIcon';
import PencilIcon from '../../Svg/PencilIcon';
import MediaBoxIcon from '../../Svg/MediaBoxIcon';
import SupportIcon from '../../Svg/SupportIcon';
import SafeIcon from '../../Svg/SafeIcon/';

import'./SideNav.css';

let logged;
class SideNav extends Component {
    constructor(props){
        super(props);
        this.state={
            sidebarOpen: false,
            permission: cookie.get('permission') || null,
            token: cookie.get('token') || null,
            username: cookie.get('username') || null,
            handleNotify: false,
            coins : 0,
            canSellCoins : false,
            activeSection: window.location.pathname,
        };
        this.renderObject= this.renderObject.bind(this);
        this.logOut=this.logOut.bind(this);
        this.botonNotify = this.botonNotify.bind(this);
        this.closeModalCoins = this.closeModalCoins.bind(this);
        this.buyCoins = this.buyCoins.bind(this);
    }
    onSetSidebarOpen = open => {
        this.setState({ sidebarOpen: open })
    }
    canSellCoin = async () => {
        await axios.get('/api/v2/model/have/code/coin')
        .then((res)=>{
            this.setState({canSellCoins : res.data.haveCoin});
        });
    };
    componentDidMount(){
        this.logged();
        var navLinkChanger = document.querySelectorAll('#sidenav-overlay');
        if(navLinkChanger.length>0){
            document.body.style.overflow='auto';
            document.body.style.width='auto';
        }
        let withSize = window.innerWidth;
        if(withSize<993){
            let sideBar = document.querySelector("#nav-mobile");
            if(sideBar){
                sideBar.style.backgroundColor=this.props.config.color.sideBar;
                sideBar.style.color=this.props.config.color.sideBarText;
            }
        }
        window.addEventListener("resize", ()=>{
            let sizeWidth = window.innerWidth;
            if(sizeWidth<993){
                let sideBar = document.querySelector("#nav-mobile");
                if(sideBar){
                    sideBar.style.backgroundColor=this.props.config.color.sideBar;
                    sideBar.style.color=this.props.config.color.sideBarText;
                }
            }
        });
    }
    openLogin= async() =>{
        window.location.pathname="/Login";
    };
    botonNotify = async () =>{
        await Push.Permission.request(
            () =>  {
                this.setState({handleNotify: true});
                // Do something with the granted permission.
            },
             () => {
                this.setState({handleNotify: true});
            }
        );
    };
    logOut= async () => {
        await axios.post('/api/v2/logOut').then(res => {
            cookie.remove('username');
            cookie.remove('token');
            cookie.remove('permission');
            cookie.remove('email');
            cookie.remove("io");
            window.location = '/';
        })
    };
    getCoins=async()=>{
        await axios.get('/api/v2/get/coins')
            .then(resp =>{
                this.setState({coins : resp.data.coins})
            });
        return true;
    };
    logged=async()=>{
        let {token} = this.state;
        if(!this.state.username && this.props.landingPage === 2){
            var pathname = window.location.pathname;
            if(pathname.includes("/support") || pathname.includes("/terms") || pathname.includes("/privacy")){
                document.getElementById("container-support").style.paddingTop = '16rem';
            } else if (pathname.includes("/login") || pathname.includes("/register")) {
                document.getElementById("redesign-login-register").style.paddingTop = '16rem';
            }
        }
        logged = token && this.props.toElevate && this.props.toElevate.authenticated;
        if(logged){
            //pasar coins desde Appjs se renderizan varias veces en diferentes componentes
            this.getCoins();
            const canSellCoinPermision = this.state.permission ?  parseInt(this.state.permission) : false;
            if((canSellCoinPermision && canSellCoinPermision !== 1 ) || (canSellCoinPermision && canSellCoinPermision !== 1 && this.props.view) || (!canSellCoinPermision && this.props.view)){
                this.canSellCoin();
            }
        }
        return logged;
    };
    renderLanguage(){
        let flag= true;
        if(flag){
            return (
                <div>language selection</div>
            );
        }
    }
    buyCoins = async () => {
        this.onSetSidebarOpen(false);
        this.setState({showModalCoins : true});
    };

    closeModalCoins = async () => {
        this.setState({showModalCoins : false})
    };
    renderObject(){
        let extraMenu = [];
        if(this.state.permission==="1" || this.state.permission==='4')
        {
            extraMenu=[
                <li
                    key={5}
                    className={this.state.activeSection==="/post" ? "active-item" : ""}
                    style={
                        this.state.activeSection==="/post" ?
                            {backgroundColor:this.props.config.color.secondary}
                        : {backgroundColor:"transparent"}
                    }
                >
                    <Link to='/post'>
                        <PencilIcon
                            color={this.state.activeSection==="/post" ? "#fff" :"#c4c4c4"}
                            width={"20px"}
                            height={"18px"}
                            className='menu-name'
                        />
                        {this.props.dictionary.newPost}
                    </Link>
                </li>,
                <li
                    key={6}
                    className={this.state.activeSection==="/analytics" ? "active-item" : ""}
                    style={
                        this.state.activeSection==="/analytics" ?
                            {backgroundColor:this.props.config.color.secondary}
                        : {backgroundColor:"transparent"}
                    }
                >
                    <Link to='/analytics'>
                        <Ionicon
                            className='menu-name'
                            icon="md-analytics"
                            fontSize="22px"
                            color={this.state.activeSection==="/analytics" ? "#fff" :"#c4c4c4"}
                        />
                        {this.props.dictionary.analytics}
                    </Link>
                </li>,
                <li
                    key={7}
                    className={this.state.activeSection==="/MediaBox" ? "active-item" : ""}
                    style={
                        this.state.activeSection==="/MediaBox" ?
                            {backgroundColor:this.props.config.color.secondary}
                        : {backgroundColor:"transparent"}
                    }
                >
                    <Link to='/MediaBox'>
                        <MediaBoxIcon
                            color={this.state.activeSection==="/MediaBox" ? "#fff" :"#c4c4c4"}
                            width={"20px"}
                            height={"20px"}
                            className='menu-name'
                        />
                        {'Media Box'}
                    </Link>
                </li>,
                <li
                    key={8}
                    className={this.state.activeSection === "/settings" ? "active-item" : ""}
                    style={
                        this.state.activeSection === "/settings" ?
                            { backgroundColor: this.props.config.color.secondary }
                            : { backgroundColor: "transparent" }
                    }
                >
                    <Link to='/settings'>
                        <Ionicon
                            className='menu-name'
                            icon="md-settings"
                            fontSize="22px"
                            color={this.state.activeSection === "/settings" ? "#fff" : "#c4c4c4"}
                        />
                        {this.props.dictionary.settings}
                    </Link>
                </li>,
            ];
        }
        else if(this.state.permission==="2")
        {
            extraMenu=[
                <li
                    key={5}
                    className={this.state.activeSection==="/panel/support" ? "active-item" : ""}
                    style={
                        this.state.activeSection==="/panel/support" ?
                            {backgroundColor:this.props.config.color.secondary}
                        : {backgroundColor:"transparent"}
                    }
                >
                    <Link to='/panel/support'>
                        <SupportIcon
                            color={this.state.activeSection==="/panel/support" ? "#fff" :"#c4c4c4"}
                            width={"20px"}
                            height={"20px"}
                            className='menu-name'
                        />
                        {this.props.dictionary.panelSupport}
                    </Link>
                </li>,
                <li key={6}
                    className={this.state.activeSection==="/support/faq" ? "active-item" : ""}
                    style={
                        this.state.activeSection==="/support/faq" ?
                            {backgroundColor:this.props.config.color.secondary}
                            : {backgroundColor:"transparent"}
                    }
                >
                    <Link
                          className='navLink'
                          to='/support/faq'
                    >
                        <Ionicon
                            className='menu-name'
                            icon="md-help-circle"
                            fontSize="22px"
                            color={this.state.activeSection==="/support/faq" ? "#fff" :"#c4c4c4"}
                        />
                        F.A.Q.
                    </Link>
                </li>,
                <li
                    key={7}
                    className={this.state.activeSection==="/MediaBox" ? "active-item" : ""}
                    style={
                        this.state.activeSection==="/MediaBox" ?
                            {backgroundColor:this.props.config.color.secondary}
                        : {backgroundColor:"transparent"}
                    }
                >
                    <Link to='/MediaBox'>
                        <MediaBoxIcon
                            color={this.state.activeSection==="/MediaBox" ? "#fff" :"#c4c4c4"}
                            width={"20px"}
                            height={"20px"}
                            className='menu-name'
                        />
                        {'Media Box'}
                    </Link>
                </li>,
                <li key={8}
                    className={this.state.activeSection==="/analytics" ? "active-item" : ""}
                    style={
                        this.state.activeSection==="/analytics" ?
                            {backgroundColor:this.props.config.color.secondary}
                            : {backgroundColor:"transparent"}
                    }
                >
                    <Link to='/analytics'>
                        <Ionicon
                            className='menu-name'
                            icon="md-analytics"
                            fontSize="22px"
                            color={this.state.activeSection==="/analytics" ? "#fff" :"#c4c4c4"}
                        />
                        {this.props.dictionary.analytics}
                    </Link>
                </li>
            ]
        }
        else{
            extraMenu=[
                <li
                    key={5}
                    className={this.state.activeSection === "/membership" ? "active-item" : ""}
                    style={
                        this.state.activeSection === "/membership" ?
                            { backgroundColor: this.props.config.color.secondary }
                            : { backgroundColor: "transparent" }
                    }
                >
                    <Link to='/membership'>
                        <Ionicon
                            className='menu-name'
                            icon="md-card"
                            fontSize="22px"
                            color={this.state.activeSection === "/membership" ? "#fff" : "#c4c4c4"}
                        />
                        {this.props.dictionary.membership}
                    </Link>
                </li>,
                <li
                    key={6}
                    className={this.state.activeSection === "/purchases" ? "active-item" : ""}
                    style={
                        this.state.activeSection === "/purchases" ?
                            { backgroundColor: this.props.config.color.secondary }
                            : { backgroundColor: "transparent" }
                    }
                >
                    <Link to='/purchases'>
                        <SafeIcon
                            color={this.state.activeSection==="/purchases" ? "#fff" :"#c4c4c4"}
                            width={"22px"}
                            height={"22px"}
                            className='menu-name'
                        />
                        {this.props.dictionary.purchases} 
                    </Link>
                </li>,
                <li key={7}
                    className={this.state.activeSection==="/support" ? "active-item" : ""}
                    style={
                        this.state.activeSection==="/support" ?
                            {backgroundColor:this.props.config.color.secondary}
                            : {backgroundColor:"transparent"}
                    }
                >
                    <Link
                          className='navLink'
                          to='/support'
                    >
                        <SupportIcon
                            color={this.state.activeSection==="/support" ? "#fff" :"#c4c4c4"}
                            width={"20px"}
                            height={"20px"}
                            className='menu-name'
                        />
                        {this.props.dictionary.support}
                    </Link>
                </li>,
                <li key={8}
                    className={this.state.activeSection==="/support/faq" ? "active-item" : ""}
                    style={
                        this.state.activeSection==="/support/faq" ?
                            {backgroundColor:this.props.config.color.secondary}
                            : {backgroundColor:"transparent"}
                    }
                >
                    <Link
                          className='navLink'
                          to='/support/faq'
                    >
                        <Ionicon
                            className='menu-name'
                            icon="md-help-circle"
                            fontSize="22px"
                            color={this.state.activeSection==="/support/faq" ? "#fff" :"#c4c4c4"}
                        />
                        F.A.Q.
                    </Link>
                </li>
            ]
        }
        return extraMenu;
    }
    render (){
        if(this.props.dictionary.length===0 || this.props.config.length===0 )
            return <div className="center-align" style={{paddingTop:45}}><Circle background="transparent !important" color="#4285f4" className="center-align"/></div>;
        const logo = <img src={this.props.config.storageURL+this.props.config.logo} className="responsive-img logo" alt={this.props.config.nameModel} />;
        let navbarTypeStyle = this.props.landingPage=== 1 ? "new-navbar" : "nlp-navbar";
        return (
            <div
                className={ logged ? "new-navbarLogged" : navbarTypeStyle}
                style={{
                    backgroundColor: this.props.config.color.navbar
                }}
            >
                <Sidenav
                    open={this.state.sidebarOpen}
                    onSetOpen={this.onSetSidebarOpen}
                    sidenav={
                        <div style={{backgroundColor:this.props.config.color.navbar}} className="navbarDiv">
                            <button
                                className="listMenu-btn navbarMenu-btn"
                                onClick={() => { this.onSetSidebarOpen(false) }}
                            >
                                <MenuIcon
                                    color={"#fff"}
                                    width={"27px"}
                                    height={"27px"}
                                />
                            </button>
                            <h6 className="navbar-title">
                                Menu
                            </h6>
                            {logged ?
                                <SideItemsLogged
                                    activeSection={this.state.activeSection}
                                    buyCoins={this.buyCoins}
                                    botonNotify = {this.botonNotify}
                                    handleNotify = {this.state.handleNotify}
                                    config={this.props.config}
                                    dictionary={this.props.dictionary}
                                    logo={logo}
                                    logOut={this.logOut}
                                    admin={this.renderObject()}
                                    coins = {this.state.coins}
                                    canSellCoins = {this.state.canSellCoins}
                                    toElevate={this.props.toElevate}
                                />
                            :
                                <SideItems
                                    activeSection={this.state.activeSection}
                                    config={this.props.config}
                                    dictionary={this.props.dictionary}
                                    logo={logo}
                                />
                            }
                        </div>
                    }
                >
                    <button className="listMenu-btn" onClick={() => { this.onSetSidebarOpen(true) }}>
                        <MenuIcon
                            color={"#fff"}
                            width={"27px"}
                            height={"27px"}
                        />
                    </button>

                    { logged ?
                        <HeadItemsLogged
                            botonNotify = {this.botonNotify}
                            handleNotify = {this.state.handleNotify}
                            dictionary={this.props.dictionary}
                            config={this.props.config}
                            logOut={this.logOut}
                            logo={logo}
                            admin={this.renderObject()}
                            coins = {this.state.coins}
                            toElevate={this.props.toElevate}
                        />
                    :
                        <HeadItems
                            landingPage={this.props.landingPage}
                            openLogin={this.openLogin}
                            config={this.props.config}
                            dictionary={this.props.dictionary}
                            logo={logo}
                            modeLoginAgain={this.props.modeLoginAgain}
                        />
                    }
                    { (this.props.notification && this.state.permission !== "1" && this.state.permission !== '4')  ?
                        <Advice
                            link={(this.props.notification) ? this.props.notification.link : null}
                            message={(this.props.notification) ? this.props.notification.message : null}
                            config={this.props.config}
                        />
                        : null
                    }
                </Sidenav>
                {logged &&
                    <GetCoins
                        id="modal-donation-sidebar"
                        open={this.state.showModalCoins}
                        dictionary={this.props.dictionary}
                        config={this.props.config}
                        close={this.closeModalCoins}
                    />
                }
            </div>
        )
    };
}
export default SideNav;
