import React, { Component } from 'react';
import SideNav from '../../components/NavBar/SideNav';

class Compliances extends Component {
    render(){
        return(
            <div>
                <SideNav toElevate={this.props.toElevate} dictionary={this.props.dictionary} config={this.props.config}/>
                <div className="container main-container">
                    <div className='center'>
                        <h4>18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement</h4>
                        <h5>2257 Disclosure Statement of {this.props.config.hostname} </h5>
                    </div>
                    <p>
                        Compliant Content: All models, actors, actresses and other persons that appear in any visual portrayal of actual or simulated sexually explicit conduct appearing on, or otherwise contained in, this Website were over the age of eighteen (18) years at the time the visual image was produced. Records required for all depictions of actual or simulated sexually explicit conduct by Title 18 U.S.C. §2257 and 28 C.F.R. §75, et seq., are on file with the appropriate Records Custodian.
                    </p>
                    <p>
                        The title of this work is: {this.props.config.hostname}
                    </p>
                    <p>
                        All records required to be maintained by federal law are in the possession of the custodian of records, and are available for inspection during normal business hours at
                    </p>
                    <p>
                        <ul>
                            <li>44 W Monroe St. Phoenix, AZ 85003</li>
                            <li>Custodian of Records: Team Peach LLC</li>
                            <li>Email: <a href={"mailto:support@socialtechapps.com?subject=Support%20from%20"+this.props.config.hostname}  className="text-lighten-4 link-text" style={{color: this.props.config.color.secondary}} ><u>support@socialtechapps.com </u></a></li>
                        </ul>
                    </p>
                </div>
            </div>
        )
    }
}

export default Compliances;
