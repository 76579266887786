import React, {Component} from 'react';
import Modal from '../Modal';
import axios from 'axios';
import ReactCountryFlag from "react-country-flag"
import { Preloader, Oval } from 'react-preloader-icon';
import './style.css'
class ModalConfirmNumber extends Component {
    constructor(props){
        super(props);
        this.state={
            phone : "",
            country : "US",
            countries : [],
            code : "1",
            sent : false,
            token : "",
            error : null,
            success : false,
            disabled : false,
            resend : false,
            counter : 0,
            track : false,
            finishTries : false
        }
        this.numberRegex = /^[0-9]+$/;
        this.changeHandler = this.changeHandler.bind(this);
        this.changeHandlerCode = this.changeHandlerCode.bind(this);
        this.justNumbers = this.justNumbers.bind(this);
        this.resend = this.resend.bind(this)
    }

    componentDidMount = () => {
        this.getCountries()
    }

    changeHandler(e){
        this.setState({ [e.target.name]: e.target.value});
    }

    changeHandlerCode(e){
        let values = e.target.value.split("+");
        this.setState({
            country : values[0],
            code: values[1]
        })
    }

    resend(e) {
        this.setState({
            sent : false,
            token : null,
            error : null,
            success : false,
            disabled : false,
            track : false,
            finishTries : false
        })
    }

    justNumbers(e){
        let value = e.target.value;
        let name = e.target.name;
        if(!this.numberRegex.test(value)){
            value = value.replace(/\D/g,'')
            this.setState({ [name]: value});
        }
    }

    formatClock = (sec) => {
        let minutes = Math.floor(sec / 60)
        let seconds = sec % 60
        minutes = minutes < 10 ? "0" + minutes : minutes
        seconds = seconds < 10 ? "0" + seconds : seconds
        return minutes + ":" + seconds
    }

    trackMessage = async (ssid, attempt) => {
        let data = {
            ssid : ssid
        }
        return await axios.post('/api/v2/confirm/validate', data).then( async (res) => {
            if(res.data.status === "FAILURE" && res.data.message && attempt > 1){
                this.setState({
                    resend : false,
                    counter : 0,
                    sent : false,
                    disabled : false,
                    track : false,
                    error : this.props.dictionary.errorConfirmSend,
                })
            }
            else if(res.data.Success){
                this.setState({
                    track : false
                })
            }
            return res.data.Success
        }).catch( () => {
            return false
        })
    }

    sendMessage = async (e) => {
        if(this.state.phone === ""){
            return false
        }
        let data = {
            phone : this.state.phone,
            country : this.state.country,
            code : this.state.code
        };
        this.setState({
            disabled : true,
            resend : false,
            counter : 0,
            sent : false,
            track : false,
            finishTries : false
        })
        await axios.post('/api/v2/confirm/send', data).then( async (res) => {
            if(res.data.Success){
                this.setState({
                    sent : true,
                    error : null,
                    disabled : false,
                    counter : 60,
                    track : true
                })
                let attempt = res.data.att;
                let counter = setInterval(  () => {
                    if(this.state.counter <= 0){
                        this.setState({
                            resend : true
                        })
                        clearInterval(counter);
                    }
                    if(this.state.track && res.data.provider === 1 && this.state.counter <= 15 && this.state.counter % 5 === 0){
                        this.trackMessage(res.data.ssid, attempt)
                    }
                    this.setState({
                        counter: this.state.counter - 1
                    })
                }, 1000)
            }
            else{
                this.setState({
                    disabled : false,
                    error : this.props.dictionary[res.data.error]
                })
            }

        }).catch( e => {
            this.setState({
                disabled : false,
                error : this.props.dictionary.errorConfirmSend
            })
        })
    }

    confirmPhone = async (e) => {
        this.setState({
            disabled : true
        })
        let token = this.state.token;
        if(token.length !== 4){
            this.setState({
                error : this.props.dictionary.NotValidCode,
                finishTries : false,
                disabled : false
            })
            return false
        }
        let data = {
            token : token
        };
        await axios.post('/api/v2/confirm/try', data).then( async (res) => {
            if(res.data.Success){
                this.setState({
                    success : true,
                    disabled : false
                })
                setTimeout( () => {
                    this.props.showCoinsModal()
                },3000)
            }
            else if(res.data.Error){
                this.setState({
                    error : res.data.errorMessage,
                    finishTries : !res.data.tryAgain,
                    disabled : false
                })
            }
        })
    }

    getCountries(){
        axios.get('/api/v2/countries').then( async (res) => {
            if(res.data.Success){
                this.setState({
                    countries : res.data.countries,
                    country : res.data.country.code,
                    code : res.data.country.phone,
                })
            }
        }).catch( e => {
            this.setState({
                countries : []
            })
        })
    }

    drawCountries(){
        let countries = [...this.state.countries];
        return countries.map((el, index) => {
            return (
                <option key={index} value={el.code+ "+" + el.phone}>+{el.phone} {el.name}</option>
            )
        })
    }

    render(){
        let config = this.props.config
        return(
            <Modal
                id={"phone-modal"}
                open={this.props.open}
                closeAction={this.props.close}
                head={<h6>{this.props.dictionary.confirmPhone}</h6>}
                config={config}
                footer={
                    !this.state.success ?
                        <div className='actions'>
                            {!this.state.finishTries ?
                                <button
                                    style={{
                                        backgroundColor: config.color.secondary,
                                        color: config.color.secondaryText,
                                        marginTop: 0,
                                    }}
                                    onClick={(e) => (this.state.disabled) ?
                                        null : (!this.state.sent) ?
                                            this.sendMessage(e) :
                                            this.confirmPhone(e)}
                                    className="create-account-button log-btn"
                                >
                                    {this.state.disabled ?
                                        <Preloader
                                            use={Oval}
                                            size={26}
                                            strokeWidth={8}
                                            strokeColor={config.color.secondaryText}
                                            duration={800}
                                        />
                                        :
                                        (this.state.sent) ? this.props.dictionary.confirm : this.props.dictionary.send
                                    }
                                </button>
                                :
                                null
                            }
                            {this.state.sent ?
                                <ul>
                                    <li>
                                        <button
                                            onClick={(e) => this.state.counter <= 0 ?
                                                this.resend()
                                                :
                                                null}
                                            style={{
                                                backgroundColor: config.color.third,
                                                color: config.color.thirdText,
                                                marginTop: 15,
                                                marginBottom: 0
                                            }}
                                            className="create-account-button log-btn"
                                        >
                                            {this.props.dictionary.back}
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            onClick={(e) => this.state.counter <= 0 ?
                                                this.sendMessage(e)
                                                :
                                                null
                                            }
                                            style={{
                                                backgroundColor: config.color.third,
                                                color: config.color.thirdText,
                                                marginTop: 15,
                                                marginBottom: 0
                                            }}
                                            className="create-account-button log-btn"
                                        >
                                            {this.state.resend ?
                                                this.props.dictionary.retry
                                                :
                                                this.formatClock(this.state.counter)
                                            }
                                        </button>
                                    </li>
                                </ul>
                                : null
                            }
                        </div>
                        :
                        null
                    }

            >
                {this.state.success ?
                    <h4 className="success">{this.props.dictionary.CodeThanks}</h4>
                    :
                    <div>
                        <p>{this.props.dictionary.confirmText}</p>
                        <p>{this.props.dictionary.phoneAdvice}</p>
                        {!(this.state.sent) ?
                            <div className="phone-group">
                                {this.state.error ?
                                    <p className="error">{this.state.error}</p>
                                    :
                                    null
                                }
                                <div className="select-code">
                                    <ReactCountryFlag
                                        className="emojiFlag"
                                        countryCode={this.state.country}
                                        style={{
                                            fontSize: '1.5em',
                                            lineHeight: '1.5em',
                                        }}
                                    />
                                    {" +" + this.state.code}
                                </div>
                                <select
                                    name="country"
                                    id="country"
                                    onChange={this.changeHandlerCode}
                                    value={this.state.country + "+" + this.state.code}
                                >
                                    {this.drawCountries()}
                                </select>
                                <input
                                    type="text"
                                    name="phone"
                                    onChange={this.changeHandler}
                                    value={this.state.phone}
                                    placeholder="Phone number"
                                    onKeyUp={this.justNumbers}
                                />

                            </div>
                            :
                            <div>
                                {this.state.error ?
                                    <p className="error">{this.state.error}</p>
                                    :
                                    null
                                }
                                <p>{this.props.dictionary.MessageSentTo}+{this.state.code}{this.state.phone}</p>
                                {!this.state.finishTries ?
                                    <input
                                        type="text"
                                        name="token"
                                        onChange={this.changeHandler}
                                        value={this.state.token}
                                        placeholder="XXXX"
                                        onKeyUp={this.justNumbers}
                                        maxLength={4}
                                    />
                                    :
                                    null
                                }

                            </div>
                        }
                    </div>
                }
            </Modal>
        )
    }
}
export default ModalConfirmNumber;
