import React, {Component} from "react";
import Icon from "react-ionicons";
import './style.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ViewerElement from "./ViewerElement";

class MediaViewer extends Component {

    playVideo = async (e) => {

        //now if the element is a video element
        let video = e.target.nodeName ==="VIDEO"
            ? e.target
            : e.target.previousElementSibling.nodeName ==="VIDEO"
                ? e.target.previousElementSibling : null;

        if(video){
            let sibling = video.nextSibling; //idn if this is current using in a viewer component
            if(video.paused) {
                video.play();
                if(sibling) {
                    sibling.classList.add("playing");
                }
            }
            else{
                video.pause();
                if(sibling) {
                    video.nextSibling.classList.remove("playing");
                }
            }
        }
        return true;
    }

    drawMedia(){
        let media = [...this.props.media];
        let length = media.length;
        return media.map((item, index) => {
            return (
                <ViewerElement
                    media={item}
                    length={length}
                    config={this.props.config}
                    playVideo={this.playVideo}
                    key={index}
                    isPosts={this.props.isPosts}
                />
            )
        });
    }

    render() {
        let id = (this.props.id) ? this.props.id : "MediaViewer";
        let open = this.props.open;
        let media = [...this.props.media];
        return(
            <div
                id={id}
                className={(open) ? "open media-viewer" : "media-viewer"}
            >
                <span
                    className="close"
                    onClick={(this.props.closeAction) ? this.props.closeAction : null}
                >
                    <Icon
                        icon="md-close"
                        color="white"
                        fontSize="1.5em"
                    />
                </span>
                {
                    (media.length > 1) ?
                        <Carousel
                            showStatus={false}
                            dynamicHeight={false}
                            emulateTouch={true}
                            showThumbs={false}
                            selectedItem={this.props.defaultOpen}
                        >
                            {this.drawMedia()}
                        </Carousel>
                        :
                        <div className="only">
                            {this.drawMedia()}
                        </div>
                }


            </div>
        )
    }
}
export default MediaViewer;
