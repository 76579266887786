import React, { Component } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';
import SideNav from '../../components/NavBar/SideNav';
import Ionicon from 'react-ionicons';
import './styleRecover.css';

class RecoverPassword extends Component {
    constructor(props){
        super(props);
        this.changeHandler=this.changeHandler.bind(this);
        this.state={
            newPassword: '',
            confNewPassword: '',
            passwordError: false,
            repeatpwdError: false
        }
        this.fireTracking();
    }
    changePassword=async()=>{
        let error=false;
        document.getElementById("input_1").classList.remove("sign-error");
        document.getElementById("input_2").classList.remove("sign-error");
        this.setState({
            passwordError:false,
            repeatpwdError: false
        });
        if (this.state.newPassword.length < 6){
            document.getElementById("input_1").classList.add("sign-error");
            document.getElementById("input_1").focus();
            await this.setState({passwordError: this.props.dictionary.passwordError});
            error=true;
        }
       if(!this.state.confNewPassword || this.state.confNewPassword !== this.state.newPassword){
            document.getElementById("input_2").classList.add("sign-error");
            document.getElementById("input_2").focus();
            await this.setState({repeatpwdError: this.props.dictionary.repasswordError});
            error=true;
        }
        if(error) return;
        const newPassword={
            newPassword: this.state.newPassword,
            userId: this.props.userToken.userId,
            token: this.props.userToken.token
        }
        await axios.post('/api/v2/changePassword/',newPassword)
            .then(change=>{
                return change.data;
            })
        alert(this.props.dictionary.changedPw);
        window.location.href='/';
    }
    keyPress(event) {
        if (event.key === 'Enter') {
            this.changePassword()
        }
    }
    fireTracking = () => {
      ReactGA.pageview(window.location.pathname);
    }
    changeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render(){
        return(
            <div>
                <SideNav
                    toElevate={this.props.toElevate}
                    dictionary={this.props.dictionary}
                    config={this.props.config}
                />
                <div className="container main-container">
                    <div className="col input-field-recover">
                            <label htmlFor="input_1">
                                <Ionicon
                                    icon='ios-lock'
                                    fontSize="25px"
                                    color="rgb(92, 92, 92)"
                                />
                                {this.props.dictionary.newpw}
                            </label>
                        <input
                            id="input_1"
                            minLength="6"
                            maxLength="40"
                            onKeyPress={this.keyPress}
                            type="password"
                            name='newPassword'
                            onChange={this.changeHandler}
                            value={this.state.newPassword}
                        />
                        {this.state.passwordError ?
                            <div className="log-error-class">{this.state.passwordError}</div>
                        : null}
                    </div>
                    <div className="col input-field-recover">
                        <label htmlFor="input_2">
                            <Ionicon
                                icon='ios-lock'
                                fontSize="25px"
                                color="rgb(92, 92, 92)"
                            />
                            {this.props.dictionary.confirmNewPw}
                        </label>
                        <input
                            id="input_2"
                            minLength="6"
                            maxLength="40"
                            onKeyPress={this.keyPress}
                            type="password"
                            name='confNewPassword'
                            onChange={this.changeHandler}
                            value={this.state.confNewPassword}
                        />
                        {this.state.repeatpwdError ?
                            <div className="log-error-class">{this.state.repeatpwdError}</div>
                        : null}
                    </div>
                    <div className="center-align">
                        <button
                            style={{
                                backgroundColor: this.props.config.color.secondary,
                                color: this.props.config.color.secondaryText
                            }}
                            onClick={this.changePassword}
                            className="create-account-button log-btn"
                        >
                            {this.props.dictionary.send}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
export default RecoverPassword;
