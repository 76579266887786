import React, {Component} from 'react';
import Modal from '../Modal';

class ModalConfirmCancelation extends Component {

    render() {
        let config = this.props.config;

        return <Modal
            id={"ccbill-modal"}
            open={this.props.open}
            closeAction={this.props.close}
            head={<h6>{this.props.dictionary.cancelAnyTime}</h6>}
            config={config}
            footer={
                <div className='actions'>
                    <button
                            style={{
                                backgroundColor: config.color.secondary,
                                color: config.color.secondaryText,
                                marginTop: 0,
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                localStorage.setItem('showModalCancel', '1');
                                this.props.accepted();
                            }}
                            className="create-account-button log-btn"
                    >{this.props.dictionary.agree}</button>
                </div>
            }>
            <p>{this.props.dictionary.ccbillCancelLeyend.replace(/--contact--/g, window.location.host + '/support')}</p>
        </Modal>
    }
}

export default ModalConfirmCancelation;
