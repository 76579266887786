import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Route, Switch
} from 'react-router-dom';
import {Circle} from 'react-preloaders';
import axios from 'axios';
import ReactGA from 'react-ga';
import Toolboox from "./components/Toolbox/Toolbox";
import Home from './containers/Home';
import PublishPost from './containers/PublishPost';
import Analytics from "./containers/Analytics";
import Messages from './containers/Chat';
import Terms from './containers/Terms';
import Privacy from './containers/Privacy';
import Support from './containers/Support';
import FAQ from './containers/Support/FAQ';
import CreateAccount from './containers/Support/Blog/CreateAccount';
import GetMembership from './containers/Support/Blog/GetMembership';
import ForgotPassword from './containers/Support/Blog/ForgotPassword';
import RecoverPassword from './containers/RecoverPassword';
import Compliances from './containers/Compliances';
import ChangePassword from './containers/ChangePassword';
import ChangePasswordSupport from './containers/Support/Blog/ChangePassword';
import CancelMembership from './containers/Support/Blog/CancelMembership';
import PanelSupport from './containers/PanelSupport';
import Login from './containers/Login';
import Register from './containers/Register';
import Settings from './containers/Settings';
import io from "socket.io-client";
import Push from 'push.js';
import MembershipPayment from "./containers/Support/Blog/MembershipPayment/MembershipPayment";

import Feed from './containers/NewFeed';
import NewPost from './containers/NewPost';
import MediaBox from "./containers/MediaBox";
import Profile from './containers/NewProfile';
import Membership from './containers/Membership';
import Activity from './containers/Activity';
import Purchases from './containers/Purchases';
import HomeInactive from './components/HomeInactive';
import cookies from "js-cookie";

import './App.css';
import './theme/colors.css';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: null,
            loading: true,
            membership: false,
            permission: false,
            model: [],
            recoverMode: false,
            usrLanguage: false,
            config: [],
            dictionary: [],
            userActive: true,
            streaming: false,
            landingPage: 1,
            rating: null,
            isSafari: false,
            isIOS: false,
            description: null,
            statusModel: 1
        };
        this.getCoins = this.getCoins.bind(this);
        this.socketStream = null;

        //Proposal for run methods to elevate state from components
        this.toElevate = {
            pushNotification: this.notification.bind(this),
            getCoins: this.getCoins.bind(this),
            confirmPurchase: this.getModalConfirmPurshase.bind(this)
        };
        this.getPiCodes = this.getPiCodes.bind(this);
    }

    async componentDidMount() {
        this.isIOS();
        await this.uploadFront();
        if(this.state.statusModel){
	        await axios.get('/api/v2/authenticated').then(res => {
	            if (res.data) {
	                this.socketStream = io(
	                    "https://sockets.socialtechapps.com/",
	                    {
	                        path: "/api/v2/messages-sever/streaming",
	                        query: {
	                            username: cookies.get("username"),
	                            session_token: cookies.get("token"),
	                            host: window.location.host,
	                        },
	                        reconnection: true,
	                        reconnectionDelay: 1000,
	                        reconnectionDelayMax: 2000,
	                        reconnectionAttempts: Infinity,
	                    },
	                    {secure: true}
	                );
	                this.socketStream.on("reconnect_attempt", () => {
	                    this.socketStream.io.opts.query = {
	                        username: cookies.get("username"),
	                        session_token: cookies.get("token"),
	                        host: window.location.host,
	                    };
	                });
	                this.socketStream.on('SHOW-STREAMING', (data, host) => {
	                    const currHost = window.location.host;
	                    if (host === currHost) {
	                        if (this.state.permission !== 1 && this.state.permission !== 4) {
	                            this.setState({streaming: data.transmition});
	                        }
	                    }

	                });

	                setTimeout(() => {
	                    this.isStreaming();
	                }, 7000);
	                this.toElevate.authenticated=true;
	                this.setState({
	                    authenticated: true,
	                    loading: false,
	                    membership: res.data.membership,
	                    permission: res.data.permission,
	                    model: res.data.model,
	                });
	                return true;
	            } else {
	                this.setState({authenticated: false, loading: false});
	                return false;
	            }
	        }).catch(() => {
	            this.setState({authenticated: false, loading: false})
	        });
	    }
        this.recoverMode();
        this.fireTracking();
        document.addEventListener('contextmenu', event => event.preventDefault());
        setTimeout(() => {
            this.uploadBackground();
        }, 3000);
    }

    /**
     *
     * @param keyCountry
     * @param promo
     * @returns {Promise<void>}
     */
    getPiCodes = async (keyCountry = null, promo = null) => {
        return new Promise((resolve) => {
            axios.post('/api/v2/picode', {
                promo: promo,
                location: keyCountry
            })
                .then(res => {
                    return resolve(res.data);
                });
        })
    }

    isSafari() {
        const safari = !!(navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') === -1 &&
            navigator.userAgent.indexOf('FxiOS') === -1);
        this.setState({
            isSafari: safari
        });

        return safari;
    }

    isIOS() {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const isSafari = this.isSafari();
        const isIOS = !!((/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) || isSafari)
        this.setState({
            isIOS: isIOS
        });

        return isIOS;
    }

    /**
     * Initial configuration loaded from storage or request
     * @returns {Promise<void>}
     */
    async uploadFront() {
        const existUpdate = await axios.get('/api/v2/update/site/front')
            .then(resp => {
                this.setState({
                    landingPage: resp.data.landingPage.landingPage,
                    description: resp.data.landingPage.description ? resp.data.landingPage.description : null,
                    statusModel: resp.data.landingPage.statusModel,
                    rating: resp.data.rating ? resp.data.rating : null
                });
                return resp.data.lastUpdate;
            }).catch( err => {
            });

        const localUpdate = new Date(localStorage.getItem('lastUpdate'));
        const existConfig = !!localStorage.getItem('config');
        const existDictionary = !!localStorage.getItem('dictionary');

        if(existUpdate) {
            const lastUpdate = new Date(existUpdate);

            //Exist new update
            if (localUpdate < lastUpdate) {
                await this.setConfig();
                await this.getDictionary()
                localStorage.removeItem('footerData');
                localStorage.removeItem('metas');
                localStorage.setItem('lastUpdate', lastUpdate.toString());
            } else {
                if (!existConfig)
                    await this.setConfig();
                if (!existDictionary)
                    await this.getDictionary();

                let config = localStorage.getItem('config');
                if (config) {
                    config = JSON.parse(config);
                    let dictionary = localStorage.getItem('dictionary');
                    dictionary = JSON.parse(dictionary);
                    //Maybe can refactor
                    document.getElementById("bodyMain").style.backgroundColor = config.color.navbar;

                    this.setState({config: config, dictionary: dictionary})
                }
            }
        }

    }

    fireTracking() {
        ReactGA.initialize(this.state.config.googleAnalytics);
    }

    setConfig = async () => {
        await axios.post('/api/v2/siteConfig')
            .then(async res => {
                if(res.data) {
                    localStorage.setItem('config', res.data.model_front_config);
                    let config = JSON.parse(res.data.model_front_config);
                    await this.setState({config: config});
                }
            })
    };

    getDictionary = async () => {
        await axios.post('/api/v2/diccionary')
            .then(async res => {
                let dictionary = JSON.parse(res.data.dictionary);
                dictionary.languageKey = res.data.language;
                const dictionaryLocalSt = JSON.stringify(dictionary);
                localStorage.setItem('dictionary', dictionaryLocalSt);
                await this.setState({dictionary: dictionary});
            });
    };

    searchUserLanguage = async () => {
        return await axios.get("/api/v2/userLanguage")
            .then(resp => {
                this.setState({usrLanguage: resp.data || false});
                return resp.data;
            });
    };

    uploadBackground = async () => {
        let configP = localStorage.getItem('config');
        if(configP) {
            let configParse = JSON.parse(configP);
            setTimeout(() => {
                document.getElementById("bodyMain").style.backgroundColor = configParse.color.navbar;
            }, 1000);
        }
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    getCoins = async () => {
        return await axios.get('/api/v2/get/coins')
            .then(resp => {
                return resp.data.coins;
            });

    };

    isStreaming = () => {
        axios.get('/api/v2/isCurrentStreaming').then((res) => {
            if (res.data.streaming) {
                this.setState({streaming: true})
                let sidenav = document.getElementsByClassName("styles_content__1Phom")[0]; //put sidenav and notifymodal behind
                if (sidenav !== null && sidenav !== undefined) {
                    sidenav.style.zIndex = 0;
                }
            } else {
                this.setState({streaming: false})
            }
        })
    };

    async notificationPermission() {
        return Push.Permission.request(() => console.log(Push.Permission.DEFAULT));
    }

    async getModalConfirmPurshase(){
        let data;
        let params = Toolboox.searchParams();
        if(params && params.ans ){
            if(params.ans[0] === "Y"){
                if(this.state.authenticated){
                    if(((this.state.permission === 0 && this.state.membership) || this.state.permission === 4) && !params.x_parameters){
                        data = {
                            "showModalPushase": true,
                            "modalAction": "newMembership",
                        };
                        return (data);

                    } else if(params.x_parameters && ((this.state.permission === 0 && this.state.membership) || this.state.permission === 4)){
                        data = {
                            "showModalPushase": true,
                            "modalAction": "moreCoins",
                            "price": params.auth_amount,
                            "picode": params.x_parameters
                        };
                        return (data);
                    } else {
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYRETRY"
                        };
                        return (data);
                    }
                } else {
                    data = {
                        "showModalPushase": true,
                        "modalAction": "Error",
                        "errorText": "NMYRETRY"
                    };
                    return (data);
                }
            }
            else if(params.ans[0] === "N"){
                let nameError = params.ans.split("+");
                switch(nameError[0]) {
                    case "NMYNEGDB":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYNEGDB"
                        };
                        return (data); // eslint-disable-next-line
                        break;
                    case "NMYCEH":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYCEH"
                        };
                        return (data); // eslint-disable-next-line
                        break;
                    case "NDECLINED":
                    case "NDECLINEDE":
                    case "NCALLCENTER":
                    case "NDECLINEDINSFUNDS":
                    case "NMYINVAMT":
                    case "NDECLINEDCVV2":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NDECLINED"
                        };
                        return (data); // eslint-disable-next-line
                        break;
                    case "NMYBLOCKEDZIP":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYBLOCKEDZIP"
                        };
                        return (data); // eslint-disable-next-line
                        break;
                    case "NMYSERVNOTALLOWED":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYSERVNOTALLOWED"};
                        return (data); // eslint-disable-next-line
                        break;
                    case "NMYDNVEL":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYDNVEL"};
                        return (data); // eslint-disable-next-line
                        break;
                    case "NMYNOTACCEPTED":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYNOTACCEPTED"};
                        return (data); // eslint-disable-next-line
                        break;
                    case "NMYDENIED":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYDENIED"};
                        return (data); // eslint-disable-next-line
                        break;
                    case "NMYRETRY":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYRETRY"};
                        return (data); // eslint-disable-next-line
                        break;
                    default:
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYRETRY"};
                        return (data);
                }
            }
            else{
                let data = {"showModalPushase": false};
                return (data);
            }
        } else {
            let data = {"showModalPushase": false};
            return (data);

        }
    }

    async notification() {
        if (this.state.authenticated) {
            const ua = navigator.userAgent.toLowerCase();
            //Pay for it
            let isSafari = (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1);
            if (!isSafari) {
                await this.notificationPermission();
                if (Push.Permission.has()) {
                    // active
                    window.addEventListener('focus', () => {
                        this.setState({userActive: true})
                    });
                    // Inactive
                    window.addEventListener('blur', () => {
                        this.setState({userActive: false})
                    });
                    this.socket = io("https://sockets.socialtechapps.com/", {
                        path: "/api/v2/messages-sever/notification",
                        query: {
                            username: cookies.get("username"),
                            session_token: cookies.get("token"),
                            host: window.location.host,
                        },
                        reconnection: true,
                        reconnectionDelay: 1000,
                        reconnectionDelayMax: 2000,
                        reconnectionAttempts: Infinity,
                    });
                    this.socket.on("reconnect_attempt", () => {
                        this.socket.io.opts.query = {
                            username: cookies.get("username"),
                            session_token: cookies.get("token"),
                            host: window.location.host,
                        };
                    });
                    this.socket.on('RECEIVE_NOTIFICATION', (socketId, message) => {
                        if (window.location.pathname !== '/messages' || !this.state.userActive) {
                            sendNotification(message);
                        }
                    });
                    this.socketNotification = io(
                        "https://sockets.socialtechapps.com/",
                        {
                            path: "/api/v2/messages-sever/notificationDefault",
                            query: {
                                username: cookies.get("username"),
                                session_token: cookies.get("token"),
                                host: window.location.host,
                            },
                            reconnection: true,
                            reconnectionDelay: 1000,
                            reconnectionDelayMax: 2000,
                            reconnectionAttempts: Infinity,
                        }
                    );
                    this.socketNotification.on('RECEIVE_NOTIFICATION', (message, host) => {
                        const currHost = window.location.host;
                        if (host === currHost)
                            sendNotification(message);
                    });
                    const sendNotification = (message) => {
                        Push.create(this.state.config.nameModel, {
                            body: message,
                            icon: this.state.config.storageURL + this.state.config.logo,
                            timeout: 4000,
                            onClick: function () {
                                window.focus();
                                this.close();
                            }
                        });

                    };
                }
            }

        }

    }

    recoverMode = () => {
        const pathArray = window.location.pathname.split('/');
        if (pathArray[1] === 'recoverPassword') {
            const url_string = window.location.href;
            const url = new URL(url_string);
            const token = url.searchParams.get("token");
            axios.get('/api/v2/getTokenToRecoverPassword/' + token).then(res => {
                if (res.data.token) {
                    this.setState({
                        recoverMode: res.data
                    });
                }
            });
        }
    };

    renderObject = () => {
        //FOR REFACTOR
        if (!this.state.permission) {
            return (
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() =>
                            this.state.authenticated ? (
                                <Feed
                                    isSafari={this.state.isSafari}
                                    isIOS={this.state.isIOS}
                                    toElevate={this.toElevate}
                                    streaming={this.state.streaming}
                                    getCoins={this.getCoins}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    view={!!this.state.membership}
                                    statusModel={this.state.statusModel}
                                />
                            ) : (
                                <Home
                                	isActive={this.state.statusModel}
                                    description={this.state.description}
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    landingPage={this.state.landingPage}
                                    rating={this.state.rating}
                                />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/Login"
                        render={() =>
                            this.state.authenticated ? (
                                <Feed
                                    isSafari={this.state.isSafari}
                                    isIOS={this.state.isIOS}
                                    toElevate={this.toElevate}
                                    streaming={this.state.streaming}
                                    getCoins={this.getCoins}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    view={!!this.state.membership}
                                    statusModel={this.state.statusModel}
                                />
                            ) : (
                                <Login
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/register"
                        render={() =>
                            this.state.authenticated ? (
                                <Feed
                                    isSafari={this.state.isSafari}
                                    isIOS={this.state.isIOS}
                                    toElevate={this.toElevate}
                                    streaming={this.state.streaming}
                                    getCoins={this.getCoins}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    view={!!this.state.membership}
                                    statusModel={this.state.statusModel}
                                />
                            ) : (
                                <Register
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/posts"
                        render={() =>
                            this.state.authenticated ? (
                                <Feed
                                    isSafari={this.state.isSafari}
                                    isIOS={this.state.isIOS}
                                    toElevate={this.toElevate}
                                    streaming={this.state.streaming}
                                    getCoins={this.getCoins}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    view={!!this.state.membership}
                                    statusModel={this.state.statusModel}
                                />
                            ) : (
                                <Home
                                	isActive={this.state.statusModel}
                                    description={this.state.description}
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    landingPage={this.state.landingPage}
                                    rating={this.state.rating}
                                />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/messages"
                        render={() =>
                            this.state.authenticated ? (
                                <Messages
                                    isSafari={this.state.isSafari}
                                    isIOS={this.state.isIOS}
                                    streaming={this.state.streaming}
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    model={this.state.model}
                                    permission={this.state.permission}
                                    view={!!this.state.membership}
                                />
                            ) : (
                                <Home
                                	isActive={this.state.statusModel}
                                    description={this.state.description}
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    landingPage={this.state.landingPage}
                                    rating={this.state.rating}
                                />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/profile"
                        render={() =>
                            this.state.authenticated ? (
                                <Profile
                                    toElevate={this.toElevate}
                                    streaming={this.state.streaming}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    view={!!this.state.membership}
                                />
                            ) : (
                                <Home
                                	isActive={this.state.statusModel}
                                    description={this.state.description}
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    landingPage={this.state.landingPage}
                                    rating={this.state.rating}
                                />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/membership"
                        render={() =>
                            this.state.authenticated ? (
                                <Membership
                                    getPiCodes={this.getPiCodes}
                                    toElevate={this.toElevate}
                                    streaming={this.state.streaming}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    view={!!this.state.membership}
                                    statusModel={this.state.statusModel}
                                />
                            ) : (
                                <Home
                                	isActive={this.state.statusModel}
                                    description={this.state.description}
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    landingPage={this.state.landingPage}
                                    rating={this.state.rating}
                                />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/purchases"
                        render={() =>
                            this.state.authenticated ? (
                                <Purchases
                                    toElevate={this.toElevate}
                                    streaming={this.state.streaming}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    view={!!this.state.membership}
                                />
                            ) : (
                                <Home
                                	isActive={this.state.statusModel}
                                    description={this.state.description}
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    landingPage={this.state.landingPage}
                                />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/activity"
                        render={(props) =>
                            this.state.authenticated ? (
                                <Activity
                                    toElevate={this.toElevate}
                                    streaming={this.state.streaming}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    view={this.state.membership ? true : false}
                                />
                            ) : (
                                <Home
                                	isActive={this.state.statusModel}
                                    description={this.state.description}
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    landingPage={this.state.landingPage}
                                    rating={this.state.rating}
                                />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/terms"
                        render={() => (
                            <Terms
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/privacy"
                        render={() => (
                            <Privacy
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support"
                        render={() => (
                            <Support
                                getPiCodes={this.getPiCodes}
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={!!this.state.membership}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/faq"
                        render={() => (
                            <FAQ
                                getPiCodes={this.getPiCodes}
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={!!this.state.membership}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/create_account"
                        render={() => (
                            <CreateAccount
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/get_membership"
                        render={() => (
                            <GetMembership
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/recover_password"
                        render={() => (
                            <ForgotPassword
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/change_password"
                        render={() => (
                            <ChangePasswordSupport
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/cancel_membership"
                        render={() => (
                            <CancelMembership
                                getPiCodes={this.getPiCodes}
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/membership_payment"
                        render={() => (
                            <MembershipPayment
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/recoverPassword"
                        render={() =>
                            this.state.recoverMode ? (
                                <RecoverPassword
                                    toElevate={this.toElevate}
                                    key={this.state.recoverMode.userId}
                                    userToken={this.state.recoverMode}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                />
                            ) : (
                                <Home
                                	isActive={this.state.statusModel}
                                    description={this.state.description}
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    landingPage={this.state.landingPage}
                                    rating={this.state.rating}
                                />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/compliances"
                        render={() => (
                            <Compliances
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/changePassword"
                        render={() =>
                            this.state.authenticated ? (
                                <ChangePassword
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                />
                            ) : (
                                <Home
                                	isActive={this.state.statusModel}
                                    description={this.state.description}
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    landingPage={this.state.landingPage}
                                    rating={this.state.rating}
                                />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/join"
                        render={() =>
                            this.state.authenticated ? (
                                this.state.membership ? (
                                    <Feed
                                        isSafari={this.state.isSafari}
                                        isIOS={this.state.isIOS}
                                        toElevate={this.toElevate}
                                        streaming={this.state.streaming}
                                        getCoins={this.getCoins}
                                        dictionary={this.state.dictionary}
                                        config={this.state.config}
                                        view={true}
                                        statusModel={this.state.statusModel}
                                    />
                                ) : (
                                    <Feed
                                        isSafari={this.state.isSafari}
                                        isIOS={this.state.isIOS}
                                        toElevate={this.toElevate}
                                        streaming={this.state.streaming}
                                        getCoins={this.getCoins}
                                        dictionary={this.state.dictionary}
                                        config={this.state.config}
                                        view={!!this.state.membership}
                                        statusModel={this.state.statusModel}
                                    />
                                )
                            ) : (
                                <Home
                                	isActive={this.state.statusModel}
                                    description={this.state.description}
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    landingPage={this.state.landingPage}
                                    rating={this.state.rating}
                                />
                            )
                        }
                    />
                    <Route
                        exact
                        render={() =>
                            this.state.authenticated ? (
                                <Feed
                                    isSafari={this.state.isSafari}
                                    isIOS={this.state.isIOS}
                                    toElevate={this.toElevate}
                                    streaming={this.state.streaming}
                                    getCoins={this.getCoins}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    view={!!this.state.membership}
                                    statusModel={this.state.statusModel}
                                />
                            ) : (
                                <Home
                                	isActive={this.state.statusModel}
                                    description={this.state.description}
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    landingPage={this.state.landingPage}
                                    rating={this.state.rating}
                                />
                            )
                        }
                    />
                </Switch>
            );
        } else if (this.state.permission === 1 || this.state.permission === 4) {
            return (
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() => (
                            <Feed
                                isSafari={this.state.isSafari}
                                isIOS={this.state.isIOS}
                                streamingMdl={true}
                                toElevate={this.toElevate}
                                streaming={this.state.streaming}
                                getCoins={this.getCoins}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={true}
                                statusModel={this.state.statusModel}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/posts"
                        render={() => (
                            <Feed
                                isSafari={this.state.isSafari}
                                isIOS={this.state.isIOS}
                                streamingMdl={true}
                                toElevate={this.toElevate}
                                streaming={this.state.streaming}
                                getCoins={this.getCoins}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={true}
                                statusModel={this.state.statusModel}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/post"
                        render={() => (
                            <NewPost
                                toElevate={this.toElevate}
                                streaming={this.state.streaming}
                                getCoins={this.getCoins}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={true}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/mediaBox"
                        render={() => (
                            <MediaBox
                                toElevate={this.toElevate}
                                streaming={this.state.streaming}
                                getCoins={this.getCoins}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={true}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/messages"
                        render={() => (
                            <Messages
                                isSafari={this.state.isSafari}
                                isIOS={this.state.isIOS}
                                streaming={this.state.streaming}
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                model={this.state.model}
                                permission={this.state.permission}
                                view={!!this.state.membership}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/profile"
                        render={() => (
                            <Profile
                                toElevate={this.toElevate}
                                permission={this.state.permission}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={!!this.state.membership}
                            />
                        )}
                    />
                    {this.state.permission === 4 ? (
                        <Route
                            exact
                            path="/purchases"
                            render={() => (
                                <Purchases
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    toElevate={this.toElevate}
                                    view={!!this.state.membership}
                                />
                            )}
                        />
                    ) : (
                        ""
                    )}
                    <Route
                        exact
                        path="/activity"
                        render={(props) => (
                            <Activity
                                toElevate={this.toElevate}
                                permission={this.state.permission}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={this.state.membership ? true : false}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/analytics"
                        render={() => (
                            <Analytics
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={!!this.state.membership}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/settings"
                        render={() => (
                            <Settings
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={!!this.state.membership}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/admin/publish"
                        render={() => (
                            <PublishPost
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={!!this.state.membership}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/terms"
                        render={() => <Terms dictionary={this.state.dictionary}
                            config={this.state.config}
                            toElevate={this.toElevate}/>}
                    />
                    <Route
                        exact
                        path="/privacy"
                        render={() => <Privacy dictionary={this.state.dictionary}
                            config={this.state.config}
                            toElevate={this.toElevate}/>}
                    />
                    <Route
                        exact
                        path="/support"
                        render={() => (
                            <Support
                                getPiCodes={this.getPiCodes}
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={!!this.state.membership}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/faq"
                        render={() => (
                            <FAQ
                                getPiCodes={this.getPiCodes}
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={!!this.state.membership}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/create_account"
                        render={() => (
                            <CreateAccount
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/get_membership"
                        render={() => (
                            <GetMembership
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/recover_password"
                        render={() => (
                            <ForgotPassword
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/change_password"
                        render={() => (
                            <ChangePasswordSupport
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/cancel_membership"
                        render={() => (
                            <CancelMembership
                                getPiCodes={this.getPiCodes}
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/membership_payment"
                        render={() => (
                            <MembershipPayment
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/compliances"
                        render={() => (
                            <Compliances
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/changePassword"
                        render={() =>
                            this.state.authenticated ? (
                                <ChangePassword
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                />
                            ) : (
                                <Home
                                	isActive={this.state.statusModel}
                                    description={this.state.description}
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    landingPage={this.state.landingPage}
                                    rating={this.state.rating}
                                />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/panel/support"
                        render={() => (
                            <PanelSupport
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        render={() =>
                            this.state.authenticated ? (
                                <Feed
                                    isSafari={this.state.isSafari}
                                    isIOS={this.state.isIOS}
                                    streamingMdl={true}
                                    toElevate={this.toElevate}
                                    streaming={this.state.streaming}
                                    getCoins={this.getCoins}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    view={true}
                                    statusModel={this.state.statusModel}
                                />
                            ) : (
                                <Home
                                	isActive={this.state.statusModel}
                                    description={this.state.description}
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    landingPage={this.state.landingPage}
                                    rating={this.state.rating}
                                />
                            )
                        }
                    />
                </Switch>
            );
        } else if (this.state.permission === 2 || this.state.permission === 3 || this.state.permission === 5) {
            return (
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() => (
                            <Feed
                                isSafari={this.state.isSafari}
                                isIOS={this.state.isIOS}
                                toElevate={this.toElevate}
                                streaming={this.state.streaming}
                                getCoins={this.getCoins}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={true}
                                statusModel={this.state.statusModel}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/posts"
                        render={() => (
                            <Feed
                                isSafari={this.state.isSafari}
                                isIOS={this.state.isIOS}
                                toElevate={this.toElevate}
                                streaming={this.state.streaming}
                                getCoins={this.getCoins}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={true}
                                statusModel={this.state.statusModel}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/post"
                        render={() => (
                            <NewPost
                                toElevate={this.toElevate}
                                streaming={this.state.streaming}
                                getCoins={this.getCoins}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={true}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/mediaBox"
                        render={() => (
                            <MediaBox
                                toElevate={this.toElevate}
                                streaming={this.state.streaming}
                                getCoins={this.getCoins}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={true}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/messages"
                        render={() => (
                            <Messages
                                isSafari={this.state.isSafari}
                                isIOS={this.state.isIOS}
                                streaming={this.state.streaming}
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                model={this.state.model}
                                permission={this.state.permission}
                                view={!!this.state.membership}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/profile"
                        render={() => (
                            <Profile
                                toElevate={this.toElevate}
                                streaming={this.state.streaming}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={!!this.state.membership}
                            />
                        )}
                    />

                    <Route
                        exact
                        path="/membership"
                        render={() =>
                            <Membership
                                getPiCodes={this.getPiCodes}
                                toElevate={this.toElevate}
                                streaming={this.state.streaming}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={!!this.state.membership}
                                statusModel={this.state.statusModel}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/purchases"
                        render={() =>
                            <Purchases
                                toElevate={this.toElevate}
                                streaming={this.state.streaming}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={!!this.state.membership}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/activity"
                        render={(props) => (
                            <Activity
                                toElevate={this.toElevate}
                                streaming={this.state.streaming}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={this.state.membership ? true : false}
                            />
                        )}
                    />
                    <Route exact path="/terms" render={(props) => <Terms
                        toElevate={this.toElevate}
                        dictionary={this.state.dictionary}
                        config={this.state.config}/>}
                    />
                    <Route
                        exact
                        path="/privacy"
                        render={() => <Privacy
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />}
                    />
                    <Route
                        exact
                        path="/support"
                        render={() => (
                            <Support
                                getPiCodes={this.getPiCodes}
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/faq"
                        render={() => (
                            <FAQ
                                getPiCodes={this.getPiCodes}
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/create_account"
                        render={() => (
                            <CreateAccount
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/get_membership"
                        render={() => (
                            <GetMembership
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/recover_password"
                        render={() => (
                            <ForgotPassword
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/change_password"
                        render={() => (
                            <ChangePasswordSupport
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/cancel_membership"
                        render={() => (
                            <CancelMembership
                                getPiCodes={this.getPiCodes}
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/support/blog/membership_payment"
                        render={() => (
                            <MembershipPayment
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                toElevate={this.toElevate}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/compliances"
                        render={() => (
                            <Compliances
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                toElevate={this.toElevate}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/changePassword"
                        render={() =>
                            this.state.authenticated ? (
                                <ChangePassword
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    toElevate={this.toElevate}
                                />
                            ) : (
                                <Home
                                	isActive={this.state.statusModel}
                                    description={this.state.description}
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    landingPage={this.state.landingPage}
                                    rating={this.state.rating}
                                />
                            )
                        }
                    />
                    {this.state.permission === 2 ? (
                        <Route
                            exact
                            path="/analytics"
                            render={() => (
                                <Analytics
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    toElevate={this.toElevate}
                                    view={!!this.state.membership}
                                />
                            )}
                        />
                    ) : (
                        ""
                    )}
                    {this.state.permission === 2 ? (
                        <Route
                            exact
                            path="/panel/support"
                            render={() => (
                                <PanelSupport
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                />
                            )}
                        />
                    ) : ( "" )}
                    <Route
                        exact
                        render={() =>
                            this.state.authenticated ? (
                                <Feed
                                    isSafari={this.state.isSafari}
                                    isIOS={this.state.isIOS}
                                    toElevate={this.toElevate}
                                    streaming={this.state.streaming}
                                    getCoins={this.getCoins}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    view={true}
                                    statusModel={this.state.statusModel}
                                />
                            ) : (
                                <Home
                                	isActive={this.state.statusModel}
                                    description={this.state.description}
                                    toElevate={this.toElevate}
                                    dictionary={this.state.dictionary}
                                    config={this.state.config}
                                    landingPage={this.state.landingPage}
                                    rating={this.state.rating}
                                />
                            )
                        }
                    />
                </Switch>
            );
        }
    }

    render() {
        if((this.state.loading
            || this.state.dictionary.length === 0
            || this.state.config.length === 0)
            && this.state.statusModel === 1)
        {
            return (
                <div className="center-align" style={{paddingTop: 45}}>
                    <Circle
                        customLoading={(this.state.loading
                            || this.state.dictionary.length === 0
                            || this.state.config.length === 0)}
                        color="#4285f4"
                        className="center-align"
                    />
                </div>
            );
        } else if(this.state.statusModel === 0){
            return (
                <div className="center-align" style={{paddingTop: 45}}>
                    <HomeInactive/>
                </div>
            );
        }

        return (
            <Router>
                {this.renderObject()}
            </Router>
        );
    }
}

export default App;
