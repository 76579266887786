import React, {Component} from 'react';
import Modal from "../../Modal";
import CoinsIcon from '../../Svg/CoinsIcon';

class UsersDonatedPost extends Component {

    constructor(props){
        super(props);
        this.state = {
            modalAction : this.props.modalAction ? this.props.modalAction : null
        }
    }

    componentDidMount=async ()=> {
        this.setState({
            modalAction : this.props.modalAction
        });
    }

    renderUsers = () =>{
        const users = [...this.props.users];
        return users.map( (el, index) => {
            return(
                <li key={index}>
                    {el.user}
                    <span className="coin-quantity">
                        {el.coins} {this.props.dictionary.coins}
                    </span>
                </li>
            )
        })
    }
    drawModalDonators(){
        let dictionary = this.props.dictionary;
        let modalOptions = {
            donators : {
                button : dictionary.agree,
                head : "Top users",
                action :this.uploadHandler,
                body :
                    <div>
                        <ol className="topuser">
                            {this.renderUsers()}
                        </ol>
                    </div>
            }
        }
        let option = this.state.modalAction;
        let config = this.props.config;
        return (
            option ?
                <Modal
                    id={"ModalDonators"}
                    open={this.props.showModal}
                    closeAction={this.props.close}
                    head={<h4>
                            <CoinsIcon
                                width={"30px"}
                                height={"30px"}
                                colorPrincipal={"#ffc2b2"}
                                className={"top-coins"}
                            />
                            {modalOptions[option].head}
                            <CoinsIcon
                                width={"30px"}
                                height={"30px"}
                                colorPrincipal={"#ffc2b2"}
                                className={"top-coins"}
                            />
                        </h4>}
                    config={config}
                    footer={
                        <div className='center-align actions'>
                            <button
                                style={{
                                    backgroundColor:config.color.secondary,
                                    color: config.color.secondaryText,
                                    marginTop: 0,
                                    marginBottom: 20
                                }}
                                disabled={this.state.disabled}
                                onClick={this.props.close}
                                className="create-account-button log-btn"
                            >
                                {modalOptions[option].button}
                            </button>
                        </div>
                    }
                >
                    {modalOptions[option].body}
                </Modal>
                :
                null
        )
    }
    render() {
        return(
            <div className="modal-EditProfile">
                {this.props.showModal ?
                    this.drawModalDonators()
                : null }
            </div>

        )
    }
}
export default UsersDonatedPost;