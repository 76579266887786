import React from 'react';

const TermsEnglish = ({ hostname, color }) => {
    return (
         <div
            id="container-support"
            className="container main-container center-align"
            style={{
                zIndex: 0,
                backgroundColor : color.principal
            }}
        >
            <h3 className='center'>
                Terms and Conditions of Service
            </h3>
            <p>
                These Terms and Conditions of Service (the “Terms”) is a legal document that explains your
                rights and obligations as a user, subscriber, and member of the <b>{hostname}</b> social media
                website and application service (hereafter the “Website”).
            </p>
            <p>
                <b>
                    I. Introduction
                </b>
            </p>
            <p>
                The following contains the general terms and conditions of contract which govern your use of
                the Website. The Website Terms constitute a legal agreement between you and Team Peach,
                LLC (“we,” “our,” “us,” or “Company”) related to your use of the Website. We reserve the right
                to update and/or change these Terms as needed, including but not limited to changes based on
                our community, content database, legal issues, or market trends. Any changes that are made to
                these Terms will not apply retroactively, and will not apply to any dispute of which both parties
                had actual notice on or prior to the date of the change or amendment. Except as otherwise stated
                in these Terms, all amended terms shall be effective immediately after they are posted at the
                following link: {hostname}.
            </p>
            <p>
                By using or accessing the Website in any way, you expressly acknowledge and agree to be
                bound by these Terms and the Privacy Policy (the “Privacy Policy”) which is fully incorporated
                herein by this reference. Should you disagree with anything stated in these Terms, please
                discontinue your use of the Website and deactivate your account (as more fully described
                herein). The Website may be provided to you with a translation of these Terms. You agree that
                any translation of the Terms is provided for your convenience only, and that the English
                language version of the Terms will govern your relationship with the Company and use of the
                Website. If there is any contradiction between the English language version and its translation in
                a different language, then the English language version shall take precedence.
            </p>
            <p>
                <b>
                    II. Site Usage Agreement
                </b>
            </p>
            <p>
                <p>
                        <b>
                        a. Your Use of The Website
                    </b>
                </p>

                This site is owned and operated by the Company. All media, audio, video, and related
                informational materials in any medium provided on the Website, including text, captions, or
                information (collectively referred to as &quot;Content&quot;) are owned by the Company and/or by our
                Content providers. All Content is protected by U.S. and international copyright laws. We may
                restrict, terminate, or remove your access to this Website at any time, or restrict, terminate, or
                remove any Content for any reason. Though we endeavor to provide accurate information, this
                Website may contain technical or other mistakes, inaccuracies or typographical errors.
                Additionally, this site and any Content may include historically and culturally important Content
                and text that may contain subjects that reflect the social attitudes and circumstances of a
                particular time or location. You should be aware that your use of the Website may expose you to
                Content that may seem inappropriate to its context, or text that could be potentially offensive.
                While the responsibility for the Content uploaded remains with our third-party Content makers,
                we endeavor to identify and respond to any copyright or trademark infringement claims upon

                receipt of notice. If you have identified a file that you believe represents an infringement please
                notify us via the contact information below, so that we may take the appropriate measures.
            </p>
            <p>
                <p>
                    <b>b. Age Restriction</b>
                </p>
                The Website is only offered and available to Users who are 18 years of age or older. By using the
                Website, you represent and warrant that you are at least 18 years of age, that you are of legal age
                to form a binding contract with the Company. If you do not meet all of these conditions, you
                must not access or use the Website.
            </p>
            <p>
                <p>
                    <b>c. How It Works</b>
                </p>
                The Website is a social media platform that allows you to signup for a subscription and user
                profile, which in turn allows you to view Content posted on the Website, and to pay to view
                Content through additional paid options. We do not warrant that the website is compatible with
                all devices and operating systems. It is your sole responsibility to determine whether or not the
                Website is compatible with your device.
            </p>
            <p>
                <p>
                    <b>d. Use, Account Registration, Subscription, and Payment</b>
                </p>
                Subject to all of the terms, conditions, limitations and restrictions contained in these Terms, we
                grant to you a conditional, revocable, non-transferable, non-sublicensable, non-exclusive and
                limited license to use the Website for your own lawful and personal use only. You acknowledge
                and agree that this license may be revoked and terminated by the Company, at any time and for
                any reason (including, without limitation, if you violate these Terms or any applicable law).
            </p>
            <p>
                To become a user, you must register and create an account on the Website by providing a valid
                email address, username, and password. You must ensure that all information you provide is
                correct, current, and complete. You agree that all information you provide as part of your use of
                the Website fully complies with our Privacy Policy. In order to view the Content, you will need
                to provide details of a payment method via a Payment Provider. A “Payment Provider” shall be
                defined as any third party approved by Company which enables you to make payments for your
                subscription. You agree that your registration is for your own personal use, and you will not sell,
                rent, or transfer your account or any Content to any third party. The Company reserves the right,
                at any time, to verify the information which you provide to the Company, as well as your
                compliance with these Terms. If we are unable to verify your information, we reserve the right to
                suspend your account.
            </p>
            <p>
                You are fully responsible for any and all activities that occur on your account, and you are
                responsible for keeping your login details confidential and secure. You agree not to disclose your
                account details to any other person or entity, and to immediately notify the Company if you
                believe someone has used or is using your account without your permission, or if your account
                has been subject to any other breach of security. You also agree to ensure that you log out of
                your account at the end of each session. Be sure to exercise caution when accessing your account
                from a public or shared network or computer, so that others are not able to access, view or record
                your password or other personal information. The Company reserves the right to disable any
                username, password, or other identifier, whether selected by you or provided by the Company, at
                any time, and at the Company’s sole discretion, including if the Company believes you have
                violated any provision of these Terms.
            </p>
            <p>
                <p>
                    <b>e. Conduct</b>
                </p>
                You acknowledge and agree that you are solely responsible for your own actions and use of the
                Website, including all consequences and repercussions of said use. By accepting these Terms,
                you affirm, warrant, and represent that you shall not post, publish, transmit, or do anything which
                is illegal, unlawful, harassing, harmful, threatening, tortuous, abusive, defamatory, obscene,
                libelous, or an invasive of one&#39;s privacy. You also agree that you shall not post, publish,
                transmit, or make available in any way, software containing viruses or any other computer code,
                files, or programs designed to destroy, interrupt, limit the functionality of, monitor, or
                persistently reside in or on any computer software or hardware telecommunications equipment.
            </p>
            <p>
                You expressly acknowledge and agree that Website shall have the right to determine in its sole
                and unfettered discretion, what action shall be taken in the event of any discovered or reported
                violation of the Terms contained herein. Should the Company determine, in its sole discretion,
                that you have violated these Terms, the Company may suspend or terminate your membership.
            </p>
            <p>
                <p>
                    <b>f. Account Deactivation</b>
                </p>

                Should you wish to deactivate your membership, then you may do so in your account section.
                Should you desire to deactivate your account, deactivation will take place as soon as reasonably
                possible. Upon deactivation, you will no longer have access to the Content or Website. Any
                subscriptions will be deleted and cannot be subsequently renewed.
            </p>
            <p>
                <p>
                    <b>g. Intellectual Property</b>
                </p>
                The Website and its entire contents, features, and functionality (including but not limited to all
                information, software, text, displays, images, video, and audio, and the design, selection, and
                arrangement thereof), are owned by the Company, Company licensors and Content creators, or
                other providers of such material, and are protected by copyright, trademark, patent, trade secret,
                and other intellectual property or proprietary rights laws.
            </p>
            <p>
                You are prohibited from reproducing, distributing modifying, creating derivative works of,
                publicly displaying, publicly performing, republishing, downloading, exhibiting, or transmitting
                any of the Content on the Website, unless expressly stated otherwise on the Website. All rights
                not expressly granted in these Terms are reserved by the Company. Any use of the Website not
                expressly permitted by these Terms is a breach of these Terms and may violate copyright,
                trademark, and other laws.
            </p>
            <p>
                The Company name, trade name, logo and all related products and service names, designs, and
                slogans are trademarks of the Company and/or the Company’s affiliates or licensors. You are
                prohibited from using these trademarks without our prior written consent of the Company or
                applicable rights-holder(s). All other names, logos, product and service names, designs, and
                slogans on the Website are the trademarks of their respective owners.
            </p>
            <p>
                <p>
                    <b>
                        h. Disclaimer of Warranties; Limitations of Liability
                    </b>
                </p>

                <p>By using the Website, you acknowledge and agree, as following:</p>
                <ul className="terms-list">
                    <li>
                        To the fusllest extent permitted by law, the Company disclaim any and all warranties,
                    express or implied, with respect to accuracy, usefulness, completeness or reliability of the
                    Website, or the results of your use of the Website. The Company disclaim all liability and
                    responsibility arising from any reliance placed on such materials by you or any other
                    visitor to the Website, or by anyone who may be informed of any of its contents. The
                    Company also does not warrant or guarantee that the Website and all of its services and
                    features will be available at any particular time or location; that the Website and all of its
                    services and features will be secure, uninterrupted, and error-free; that any defect or error
                    will be corrected; or that the Website and all of its services and features will be free of
                    viruses and other harmful components. You are responsible for implementing sufficient
                    procedures and checkpoints to satisfy your particular requirements for antivirus
                    protection and accuracy of data input and output, and for maintaining a means external to
the Website for any reconstruction of any lost data.
                    </li>
                    <li>
                        To the fullest extent provided by law, the Company will not be liable for any loss or
                        damage caused by a distributed denial-of-service attack, viruses, or other technologically
                        harmful material that may infect your device equipment, device programs, data, or other
                        proprietary material, due to your use of the Website or any services or items obtained
                        through the Website or to your downloading of any material posted on it, or on any
                        website linked to it.
                    </li>
                    <li>
                        Your use of the Website and its services and features will be solely and entirely at your
                        own risk.
                    </li>
                    <li>
                        THE WEBSITE AND CONTENT ARE PROVIDED BY COMPANY ON AN &quot;AS IS&quot;
                        BASIS AND AS AVAILABLE, WITHOUT ANY WARRANTY OR
                        REPRESENTATION OF ANY KIND, WHETHER EXPRESS, IMPLIED,
                        STATUTORY OR OTHERWISE. TO THE FULLEST EXTENT PERMITTED BY
                        APPLICABLE LAW, COMPANY EXPRESSLY DISCLAIMS ANY AND ALL
                        WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION
                        WARRANTIES OF MERCHANTABILITY, ACCURACY, FITNESS FOR A
                        PARTICULAR PURPOSE, NON-INFRINGEMENT AND THE IMPLIED
                        CONDITIONS OF SATISFACTORY QUALITY AND ACCEPTANCE AS WELL AS
                        ANY LOCAL JURISDICTIONAL ANALOGUES TO THE ABOVE AND OTHER
                        IMPLIED OR STATUTORY WARRANTIES. WE MAKE NO REPRESENTATIONS
                        THAT THE COMPANY OR WEBSITE WILL MEET YOUR REQUIREMENTS.
                    </li>
                    <li>
                        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                        SHALL COMPANY OR ITS DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES,
                        AGENTS, DISTRIBUTORS, ATTORNEYS OR CONTRACTORS (COLLECTIVELY,
                        THE &quot;COMPANY PARTIES&quot;) BE LIABLE FOR ANY COMPENSATORY,
                        INDIRECT, INCIDENTAL, CONSEQUENTIAL OR SPECIAL DAMAGES, LOSS OF
                        DATA, INCOME OR PROFIT, LOSS OF OR DAMAGE TO PROPERTY, OR ANY
                        CLAIMS OF YOU OR OTHER THIRD PARTIES WHATSOEVER WITH RESPECT
                        TO THE WEBSITE REGARDLESS OF THE LEGAL THEORY ON WHICH THE
                        CLAIM IS BASED, WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE,
                        WARRANTY, OR OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF THE
                        POSSIBILITY OF SUCH DAMAGES. TO THE FULLEST EXTENT PERMITTED BY
                        APPLICABLE LAW, COMPANY AND THE COMPANY PARTIES SHALL NOT BE
                        LIABLE FOR ANY LOSSES OR DAMAGES WHATSOEVER RESULTING FROM
                        ANY EVENTS BEYOND OUR REASONABLE CONTROL, INCLUDING BUT NOT
                        LIMITED TO, FAILURE OF THE INTERNET OR ANY VIRUS, DELAY OR
                        INTERRUPTION IN OPERATION OR SERVICE OR FAILURE OF
                        PERFORMANCE, WHETHER OR NOT RESULTING FROM AN ACT OF GOD,
                        COMMUNICATIONS FAILURE, THEFT OR OTHERWISE. TO THE FULLEST
                        EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE
                        MAXIMUM AGGREGATE LIABILITY OF COMPANY AND THE COMPANY
                        PARTIES (JOINTLY) ARISING OUT OF OR RELATING TO THESE TERMS OR
                        YOUR USE OF THE WEBSITE EXCEED THE GREATER OF (I) THE AMOUNT
                        PAID, IF ANY, BY YOU TO US OR (II) $100. THE LIMITATIONS SET FORTH IN
                        THESE TERMS WILL NOT LIMIT OR EXCLUDE LIABILITY FOR OUR GROSS
                        NEGLIGENCE, FRAUD, OR INTENTIONAL, MALICIOUS OR RECKLESS
                        MISCONDUCT, OR FOR PERSONAL INJURY OR PROPERTY DAMAGE CAUSED
                        BY YOUR USE OF THE WEBSITE.
                    </li>
                </ul>
            </p>
            <p>
                <b>h. Indemnification: </b>
                By using the Website, you agree to indemnify and hold harmless the Company and our
                employees, agents, representatives, successors, attorneys, and assigns from and against any and
                all claims, demands, causes of action, actions, suits, proceedings, judgments, orders, damages,
                liabilities, losses, costs and expenses (including, without limitation, reasonable attorneys&#39; fees
                and legal costs) arising out of or related to the Website services, the Content, violation of the
                these Terms or any applicable law,
            </p>
            <p>
                <b>i. Law and Jurisdiction: </b>
                Should a dispute arise related to these Terms, the Website, or the Content otherwise, a court of
                competent jurisdiction sitting in the State of Arizona, County of Maricopa shall have exclusive
                jurisdiction. Any dispute regarding these Terms, the Website, or the Content shall be construed
                and interpreted under Arizona law and applicable federal statutes.
            </p>
            <p>
                <p><b>j. Waiver and Severability: </b></p>

                No waiver of any term or condition set out in these Terms shall be deemed a further or
                continuing waiver of such term or condition or a waiver of any other term or condition, and any
                failure to assert a right or provision under these Terms shall not constitute a waiver of such right
                or provision. If any provision of these Terms is held by a court or other tribunal of competent
                jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be
                eliminated or limited to the minimum extent such that the remaining provisions of the Terms will
                continue in full force and effect.
            </p>
            <p>
                <b>III. Notices &amp; Contact: </b>
            </p>
            <p>
                If you have any questions, comments, complaints or concerns about the Website, please contact
                our support team at <a href="mailto:legal@teampeach.app" className="text-lighten-4 link-text" style={{ color: color.secondary }} >legal@teampeach.app</a>
            </p>
        </div>
    );
};
export default TermsEnglish;
