import React, { Component } from 'react';
import 'emoji-mart/css/emoji-mart.css'
import {Picker} from "emoji-mart";
import DropdownList from "../../../DropdownList";
import cookie from "js-cookie";
import axios from "axios";
import MediaBoxList from "../../../MediaBox/MediaBoxList";
import Icon from "react-ionicons";
import Timer from "../../../Timer";
import ChatImages from "./ChatImages/ChatImages";
import NotAvailable from "../../../NotAvailable";

class ChatSender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEmojiPanel: false,
            message: "",
            show: false,
            isSending: false
        };
        this.inputMessage = React.createRef();
        this.toggleEmojiPanel = this.toggleEmojiPanel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addEmoji = this.addEmoji.bind(this);
        this.triggerDropdown = this.triggerDropdown.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { doDonation } = this.props;
        if (doDonation && doDonation !== prevProps.doDonation) {
            this.donate();
        }

    }

    triggerDropdown(e) {
        e.preventDefault();
        this.setState({ show: !this.state.show });
    }

    handleChange(e) {
        this.setState({ message: e.target.value });
    }

    async handleSubmit(e) {
        e.preventDefault();
        const message = this.state.message.replace(/<\/?[^>]+(>|$)/g, "");
        let files = [...this.props.files];
        const messageLength = 4 * (message.length/3);
        if ((message.trim() !== "" || files.length > 0) && messageLength <= 990) {
            this.setState({isSending: true});
            const response = await this.props.sendMessage(message, null, files);
            this.setState({ isSending: false, message: response ? "" : message });
        }
    }

    donate() {
        const coinsToDonate = this.props.coinsToDonate;
        const messageData = `
        <span>
            <i class="material-icons">card_giftcard</i>
        </span>
        ${cookie.get("username")} ${
            this.props.dictionary.hDonated
        } ${coinsToDonate} ${this.props.dictionary.coins}
        `;
        const message = {
            created_time: new Date(),
            messages: messageData,
            model_message: 0,
            modeloname: this.props.config.nameModel,
            user_message: 1,
            username: cookie.get("username"),
            token: cookie.get("token"),
            id_user: this.props.idUser,
            media: [],
            hasMedia: false,
            index: null,
            media_requested: false,
            media_sended: 0,
        };
        axios
            .post("/api/v2/spend/credit/donation/", {
                amount: coinsToDonate,
                mensaje: Buffer.from(messageData).toString("base64"),
            })
            .then((resp) => {
                if (resp.data.valid) {
                    this.props.changeCoinsHandler(resp.data.newCoinCredit);
                    this.props.socket.emit(
                        "SEND_MESSAGE",
                        this.props.modelData.modeluser,
                        message
                    );
                }
                if (resp.data.error === "No credit") this.props.modalBuy(true);
                if (resp.data.error === "Precio not found")
                    this.props.modalNotAvailableHandler(true);

            });
        this.props.donate();
        this.props.doDonationHandler(false);
    }

    addEmoji = async (emoji) => {
        let position = this.inputMessage.current.selectionEnd;
        let message = this.inputMessage.current.value;
        let length = message.length;
        if(length <= 738){
            let part1 = message.substr(0, position);
            let part2 = message.substr(position)
            let newMessage = part1 + emoji.native + part2;
            await this.setState({ message: newMessage});
            position = position + 2;
        }
        this.inputMessage.current.focus();
        this.inputMessage.current.selectionEnd = position;
    }

    toggleEmojiPanel() {
        this.setState({ showEmojiPanel: !this.state.showEmojiPanel });
    }

    drawPostPreview (styleInput) {
        let post = this.props.post;
        let config = this.props.config
        let image = (post.mediabox) ?
            config.storageURL + '/' + config.storage + "/message/model/" + post.img
            :
            config.storageURL + '/' + config.storage + "/posts/img/" + post.img
        return(
            <div
                className="link-preview"
                style={styleInput}>
                <span
                    className="close"
                    style={{color : config.color.chat.bottonText}}
                    onClick={() => this.props.toElevate.unsetPost()}
                >
                    <Icon
                        icon="md-close"
                        color={config.color.chat.bottonText}
                        fontSize="1.5em"
                    />
                </span>
                <p>
                    <div className="img">
                        <img alt={'postPreview'} className={'no-select'} src={image}/>
                        <div
                            className="media-element-coat"
                        >
                        </div>
                    </div>
                    <div className={'link-preview-post-name'}>{post.name}</div>
                </p>
            </div>
        )
    }

    drawActions(){
        if(this.props.menuItems.length === 0)
            return null;

        return <DropdownList
            items={
                this.props.menuItems
            }
            trigger={
                <button
                    type={"button"}
                    className={"v2-chat-send-options"}
                    onClick={this.triggerDropdown}
                    id="openSender"
                >
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="10"
                            cy="10"
                            r="9.5"
                            transform="rotate(90 10 10)"
                            fill={this.props.config.color.secondary}
                        />
                        <path
                            d="M10.0017 11.0232L6.77501 7.79655C6.66345 7.685 6.48257 7.685 6.371 7.79655C6.25947 7.90814 6.25947 8.089 6.371 8.20056L9.79968 11.6292C9.91125 11.7408 10.0921 11.7408 10.2037 11.6292L13.6324 8.20056C13.742 8.08705 13.7388 7.90618 13.6253 7.79655C13.5146 7.68962 13.3391 7.68962 13.2284 7.79655L10.0017 11.0232Z"
                            fill={
                                this.props.config.color.chat.bottonText
                            }
                            stroke={
                                this.props.config.color.chat.bottonText
                            }
                        />
                    </svg>
                </button>
            }
        />
    }

    render() {
        let styleEmojiPanel = this.state.showEmojiPanel
            ? { position: "absolute", bottom: "70px" }
            : { display: "none", position: "absolute", bottom: "70px" };

        let fill = this.state.showEmojiPanel
            ? this.props.config.color.chat.bottonText || "#FFF"
            : this.props.config.color.chat.background || "transparent";

        let styleInput = {
            color: this.props.config.color.chat.bottonText,
            backgroundColor: this.props.config.color.chat.titleAndBotton,
        };
        let styleContainer = {
            backgroundColor: "transparent",
            color: this.props.config.color.chat.principalText,
        };

        let mediabox = this.props.isModel && this.props.loadMediaBox
            ? <MediaBoxList
            config={this.props.config}
            dictionary={this.props.dictionary}
            id={this.props.idMediaBox}
            getChoosen={this.props.getChoosen}
            userToSend={this.props.userFrom}
            handler={this.props.mediaBoxHandler}
            limit={this.props.limitMediaBox}
        />
        : null;
        let mediaInput = this.props.files.length > 0
            ? <ChatImages
                config={this.props.config}
                dictionary={this.props.dictionary}
                media={this.props.files}
                remove={this.props.removeFile}
                uploading={this.props.uploadingPost}
            />
            : null;

        return (
            <div style={styleContainer} className={"v2-chat-sender"}>
                <button
                    className={"v2-chat-show-emoji-panel"}
                    onClick={this.toggleEmojiPanel}
                >
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="10"
                            cy="10"
                            r="9.5"
                            stroke={this.props.config.color.secondary}
                            fill={fill}
                        />
                        <path
                            d="M5.65662 8.48481C5.70053 7.94609 6.07822 6.86865 7.23764 6.86865C8.39706 6.86865 8.68692 7.94609 8.68692 8.48481"
                            stroke={this.props.config.color.secondary}
                        />
                        <path
                            d="M14.1417 12.1212C14.0188 13.0639 12.9612 14.9495 9.71485 14.9495C6.46847 14.9495 5.65688 13.0639 5.65688 12.1212"
                            stroke={this.props.config.color.secondary}
                        />
                        <path
                            d="M11.3135 8.48481C11.3574 7.94609 11.7351 6.86865 12.8945 6.86865C14.0539 6.86865 14.3438 7.94609 14.3438 8.48481"
                            stroke={this.props.config.color.secondary}
                        />
                    </svg>
                </button>
                <Picker
                    theme={'auto'}
                    sheetSize={16}
                    emojiSize={18}
                    perLine={8}
                    native={true}
                    showPreview={false}
                    useButton={false}
                    title={""}
                    onSelect={this.addEmoji}
                    style={styleEmojiPanel}
                    include={["people", "foods"]}
                />
                <form onSubmit={this.handleSubmit}>
                    {this.props.post ?
                        this.drawPostPreview(styleInput)
                        : null
                    }
                    <input
                        style={styleInput}
                        className={
                            (this.props.menuItems.length === 0) ?
                                "v2-chat-sender-input v2-chat-sender-input-full"
                                :
                                "v2-chat-sender-input"
                        }
                        placeholder={"Your message"}
                        value={this.state.message}
                        onChange={this.handleChange}
                        type={"text"}
                        maxLength={740}
                        disabled={this.state.isSending}
                        ref={this.inputMessage}
                    />
                    {!this.props.recording
                    ? (
                        <button
                            className={
                                (this.props.menuItems.length === 0) ?
                                    "v2-chat-send-message-btn v2-chat-send-message-btn-full"
                                    :
                                    "v2-chat-send-message-btn"
                            }
                            type={"submit"}
                            disabled={this.state.isSending}
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M19.0858 0.0709069C19.2941 -0.0382594 19.5458 -0.0199261 19.7375 0.115907C19.9291 0.252573 20.0275 0.484239 19.9933 0.717571L17.7016 16.1342C17.6741 16.3167 17.5666 16.4792 17.4083 16.5759C17.3091 16.6359 17.1966 16.6667 17.0833 16.6667C17.015 16.6667 16.9466 16.655 16.8816 16.6334L8.14584 13.6475L16.7358 3.2984L5.63502 12.79L0.422536 11.0084C0.186703 10.9275 0.0208703 10.7142 0.00170373 10.4642C-0.0166295 10.215 0.114203 9.97837 0.335869 9.86254L19.0858 0.0709069Z"
                                    fill={this.props.config.color.secondary}
                                />
                                <path
                                    d="M7.29167 18.5417V14.6767L10.6808 15.835L8.42 18.9117C8.3 19.075 8.11167 19.1667 7.91667 19.1667C7.85167 19.1667 7.78584 19.1567 7.72167 19.1358C7.46501 19.0508 7.29167 18.8117 7.29167 18.5417Z"
                                    fill={this.props.config.color.secondary}
                                />
                            </svg>
                        </button>
                    ) : (
                        <div>
                            <button
                                className={"v2-chat-send-message-btn close"}
                                type={"button"}
                                onClick={() => this.props.cancelRecording()}
                            >
                                <Icon
                                    icon="md-close"
                                    color={this.props.config.color.secondary}
                                />
                            </button>
                            <Timer
                                start={this.props.startTimer}
                                color={this.props.config.color.chat.bottonText}
                            />
                        </div>
                    )}
                </form>
                {this.props.recording ? (
                    <button
                        className={"v2-chat-send-message-btn"}
                        type={"button"}
                        onClick={() => this.props.stopRecording()}
                    >
                        <Icon
                            icon="md-checkmark"
                            color={this.props.config.color.secondary}
                        />
                    </button>
                ) : null}
                {this.drawActions()}
                {mediaInput}
                {mediabox}
                <NotAvailable
                    config={this.props.config}
                    dictionary={this.props.dictionary}
                    open={this.props.notAvailable}
                    close={this.props.closeNotAvailable}
                />

            </div>
        );
    }
}

export default ChatSender;
