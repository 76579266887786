import React, {Component} from 'react';
import axios from "axios";
import io from "socket.io-client";
import cookies from "js-cookie";
import {isMobile} from 'react-device-detect';
import ChatConversation from "../../components/Chat/ChatConversation";
import Chat from "../../components/Chat/Chat";
import NavBar from "../../components/NavBar/SideNav";
import getParams from "../../components/SearchParams/SearchParams";
import './chat.css'
import { Link } from 'react-router-dom';
import {Circle} from "react-preloaders";

/**
 * show conversations or chat
 * @property int permission
 * @property obj dictionary, config
 * @property mixed model
 */
class ChatContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modelData: null,
            userData: null,
            showConversation: true,
            //UserInformation can turn to object REFACTOR
            userFrom: null,
            usernameFrom: null,
            userImageFrom: null,
            post: null,
            canSellCoins: false,
            showModule: false,
            openModalBroadcastMessage: false,
            filterConversation: 0
        };

        //For ChatTitle
        this.props.toElevate.showConversation = this.showConversation.bind(this);

        //For ConversationItem
        this.props.toElevate.onClickConversation = this.onClickConversation.bind(this);

        //For ConversationItem
        this.props.toElevate.unsetPost = this.unsetPost.bind(this);

        this.socket = io("https://sockets.socialtechapps.com/", {
            path: "/api/v2/messages-sever/messages",
            query: {
                username: cookies.get("username"),
                session_token: cookies.get("token"),
                host: window.location.host,
            },
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 2000,
            reconnectionAttempts: Infinity,
        });

        this.socket.on("reconnect_attempt", () => {
            this.socket.io.opts.query = {
                username: cookies.get("username"),
                session_token: cookies.get("token"),
                host: window.location.host,
            };
        });
    }

    componentDidMount() {
        this.getModelData();
        this.getUserData();
        this.hasCodeMedia();
        this.getPostInfo();
    }

    getModelData() {
        axios.post('/api/v2/modelInfo')
            .then(res => {
                if (res.data !== undefined)
                    this.setState({modelData: res.data});

            })
            .catch(err => {
                console.log('error getting model');
            });
    }

    getUserData() {
        axios.post('/api/v2/userInfo')
            .then(res => {
                if (res.data !== undefined)
                    this.setState({userData: res.data});

            })
            .catch(err => {
                console.log('error getting User');
            });
    }

    hasCodeMedia() {
        axios.get('/api/v2/model/have/code/coin')
            .then((res) => {
                const canSellCoins = res.data.haveCoin;
                if (canSellCoins) {
                    axios.get('/api/v2/model/have/module/price')
                        .then((res) => {
                            if (res.data) {
                                let showModule = {};
                                res.data.map((item, index) => {
                                    return showModule[item.module_name] = 1;
                                })
                                this.setState({
                                    canSellCoins: true,
                                    showModule: showModule
                                });
                            } else {
                                this.setState({
                                    canSellCoins: true,
                                    showModule: false
                                });
                            }
                        })
                } else {
                    this.setState({
                        canSellCoins: false,
                        showModule: false
                    });
                }
            });
    }

    getPostInfo() {
        const params = getParams();
        let post = params.post;
        if(post) {
            const data = {
                params: {
                    post: post
                }
            };

            axios.get('/api/v2/getPostById', data)
                .then(async (res) => {
                    if (res.status === 200)
                        this.setState({post: res.data});

                });
        }
    }

    /**
     * Remove params from url
     */
    unsetPost = () => {
        this.setState({post: null})
        if (window.location.href.split("?").length > 1)
            window.history.replaceState({}, document.title, "/messages");
    }

    /**
     * Set the view and params for load a chatView
     * receive from chatConversation/conversationItem
     * @param params = {
     *       userId,
     *       username,
     *       userImage,
     *       filter: 0|1|2
     *   }
     */
    onClickConversation(params) {
        this.setState({
            showConversation: false,
            userFrom: params.userId,
            usernameFrom: params.username,
            userImageFrom: params.userImage,
            filterConversation: params.filter
        });
    }

    /**
     * Set state to mount ChatConversation or ChatView
     * receive from chatConversation/conversationItem
     */
    showConversation(filter = this.state.filterConversation) {
        this.setState({
            showConversation: !this.state.showConversation, filterConversation: filter
        });
    }

    /**
     * Should be a method of evaluate more complex conditions for show the conversation
     * @returns boolean
     */
    canViewConversation() {
        return (this.props.permission === 1 && this.state.showConversation);
    }

    render() {
        if(!(!!this.state.userData) || !(!!this.state.modelData))
            return (<div className="center-align" style={{paddingTop: 45}}>
                <Circle
                    color="#4285f4"
                    className="center-align"
                />
            </div>);

        let openChat = this.canViewConversation();
        const content = (openChat) ?
            <ChatConversation config={this.props.config}
                              dictionary={this.props.dictionary}
                              toElevate={this.props.toElevate}
                              modelData={this.state.modelData}
                              userData={this.state.userData}
                              canSellCoins={this.state.canSellCoins}
                              showModule={this.state.showModule}
                              filterConversation={this.state.filterConversation}
                              isSafari={this.props.isSafari}
                              isIOS={this.props.isIOS}
                              permission={this.props.permission}
                              socket={this.socket}
            />
            :
            <Chat config={this.props.config}
                  dictionary={this.props.dictionary}
                  toElevate={this.props.toElevate}
                  modelData={this.state.modelData}
                  userData={this.state.userData}
                  isSafari={this.props.isSafari}
                  isIOS={this.props.isIOS}
                  permission={this.props.permission}
                  userFrom={this.state.userFrom}
                  usernameFrom={this.state.usernameFrom}
                  userImageFrom={this.state.userImageFrom}
                  filterConversation={this.state.filterConversation}
                  socket={this.socket}
                  post={this.state.post}
                  isMobile={isMobile}
            />;

        let contentStyles = {
            backgroundColor: this.props.config.color.principal,
            color: this.props.config.color.principalText
        };

        return <div style={{backgroundColor: this.props.config.color.navbar}}>
            <NavBar 
                toElevate={this.props.toElevate}
                dictionary={this.props.dictionary}
                config={this.props.config}
                view={this.props.view}
                notification={this.props.streaming
                    ? {link: "/streaming", message: "Model is streaming NOW!"}
                    : null}
            />
            <div className={(!this.props.view ? 'v2-container-without-membership' : 'v2-content')} style={contentStyles}>
                {(this.props.view || this.props.permission === 1 || this.props.permission === 4) ? 
                    <div id={"chat-container"} className={'v2-chat-container'}>
                        {content}
                    </div>
                :
                    <div className="center-align">
                        <h5>
                            Chat
                        </h5>
                        <hr
                            style={{
                                backgroundColor: this.props.config.color.principalText,
                                opacity: 0.3
                            }}
                        />
                        <Link
                            style={{
                                backgroundColor:this.props.config.color.secondary,
                                color: this.props.config.color.secondaryText
                            }}
                            className="link-membership subcribe-now-button"
                            to={'/membership'}
                        >
                            {this.props.dictionary.getToChat}
                        </Link>
                    </div>
                }
            </div>
        </div>;
    }
}

export default ChatContainer;
