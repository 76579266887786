import React from 'react';
import {
    Link
  } from 'react-router-dom';

const SideItems= ({config, logo, dictionary, open, activeSection})=>{
    return(
        <div>
            <ul className="itemNoLogged">
                <li className={activeSection==="/" ? "active-item" : ""} >
                    <Link to='/'>
                        {dictionary.home}
                    </Link>
                </li>
                <li className={activeSection==="/terms" ? "active-item" : ""} >
                    <Link to='/terms'>
                        {dictionary.terms}
                    </Link>
                </li>
                <li className={activeSection==="/privacy" ? "active-item" : ""} >
                    <Link to='/privacy'>
                        {dictionary.privacy}
                    </Link>
                </li>
                <li className={activeSection==="/support/" ? "active-item" : ""} >
                    <Link to='/support/'>
                        {dictionary.support}
                    </Link>
                </li>
                <li className={activeSection==="/support/faq" ? "active-item" : ""} >
                    <Link to='/support/faq'>
                        F.A.Q.
                    </Link>
                </li>
            </ul>
        </div>
    )
}
export default SideItems;
