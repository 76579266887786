import React from 'react';

const LikeIcon= ({color, width, height, className = null,colorStroke=null,widthStroke=0})=>{
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 -1 20 24"
            className={className}
        >
            <path
                fill={color}
                strokeWidth={widthStroke}
                stroke={colorStroke}
                d="M3.333 6.667H.667c-.368 0-.667.298-.667.666V18c0 .368.298.667.667.667h2.666c.368 0 .667-.299.667-.667V7.333c0-.368-.298-.666-.667-.666zM19.987 8.434c-.118-1.034-1.084-1.767-2.125-1.767h-5.197c.442-.791.679-3.028.668-3.942C13.316 1.212 12.063 0 10.55 0H10c-.368 0-.667.298-.667.667 0 1.541-.6 4.324-1.732 5.456-.762.762-1.413 1.038-2.268 1.465v10.003c1.308.436 2.969 1.076 5.5 1.076h4.36c1.437 0 2.557-1.331 2-2.737.848-.231 1.474-1.01 1.474-1.93 0-.26-.05-.508-.14-.737 1.429-.39 1.955-2.155.963-3.263.364-.407.565-.964.497-1.566z"
            />
        </svg>

    )
}
export default LikeIcon;
