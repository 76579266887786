import React, { Component } from "react";
import NavBar from "../../components/NavBar/SideNav";
import Tabs from "../../components/Tabs";
import Watermark from "../../components/Settings/Watermark";
import WelcomeMessage from "../../components/Settings/WelcomeMessage";

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            option : 2,
            options : [
                {
                    option : 1,
                    name : props.dictionary.watermark,
                },
                {
                    option : 2,
                    name : props.dictionary.welcomeMessages,
                }
            ]
        }
    }

    changeTab = (tab) => {
        this.setState({option : tab})
    }

    drawOption(){
        let option = this.state.option;
        switch (option) {
            case 1 :
                return (
                    <Watermark
                        dictionary={this.props.dictionary}
                        config={this.props.config}
                    />
                )
            case 2:
                return (
                    <WelcomeMessage
                        dictionary={this.props.dictionary}
                        config={this.props.config}
                    />
                )
            default :
                return (
                    <Watermark
                        dictionary={this.props.dictionary}
                        config={this.props.config}
                    />
                )
        }
    }

    render() {

        return (
            <div className="redesign"
                 style={{
                     backgroundColor: this.props.config.color.navbar,
                 }}
            >
                <NavBar
                    toElevate={this.props.toElevate}
                    dictionary={this.props.dictionary}
                    config={this.props.config}
                    view={this.props.view}
                />
                <div
                    className="container main-container"
                    style={{
                        backgroundColor: this.props.config.color.principal,
                        color: this.props.config.color.principalText
                    }}
                >
                    <h5
                        style={{
                            color: this.props.config.color.principalText,
                            textAlign:"center"
                        }}
                    >
                        {this.props.dictionary.settings || "Settings"}
                    </h5>
                    <Tabs
                        tabs={this.state.options}
                        changeTab={this.changeTab}
                        config={this.props.config}
                        tab={this.state.option}
                    />
                    {this.drawOption()}
                </div>

            </div>
        );
    }

}
export default Settings;
