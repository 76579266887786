import React, { Component } from "react";
import ResumeRow from './ResumeRow'

class Resume extends Component {

    // constructor(props) {
    //     super(props);
    // }

    render() {
        let dictionary = this.props.dictionary;
        let superTotal = this.props.superTotal
        return (
            <div>
                <p className="sales-total">
                    Total
                    <span>
                        ${superTotal.supertotal}
                    </span>
                </p>
                <div className="sales-info">
                    <p className="sales-info-title">{dictionary.sales}</p>
                    <ResumeRow
                        label={dictionary.newMembers}
                        dot="#692FE1"
                        ammount={this.props.formatMoney(superTotal.totalNews)}
                    />
                    <ResumeRow
                        label={dictionary.renewal}
                        dot="#E39DCD"
                        ammount={this.props.formatMoney(superTotal.totalRenews)}
                    />
                    <ResumeRow
                        label={dictionary.coins}
                        dot="#5AB6E2"
                        ammount={this.props.formatMoney(superTotal.totalCoins)}
                    />
                    <hr/>
                    <ResumeRow
                        label="Subtotal"
                        dot="#5AE2B1"
                        ammount={this.props.formatMoney(superTotal.superSubTotal)}
                    />
                    <p className="sales-info-title second-list">{dictionary.fee}</p>
                    <ResumeRow
                        label={dictionary.processFees}
                        dot="#692FE1"
                        ammount={"-"+this.props.formatMoney(superTotal.superProcessFee)}
                    />
                    <ResumeRow
                        label={dictionary.refunds}
                        dot="#E39DCD"
                        ammount={this.props.formatMoney(superTotal.superTotalRefunds)}
                    />
                    <ResumeRow
                        label={dictionary.chargebacks}
                        dot="#5AB6E2"
                        ammount={this.props.formatMoney(superTotal.superChargeback)}
                    />
                    <ResumeRow
                        label={dictionary.adjustments}
                        dot="#692FE1"
                        ammount={"-"+this.props.formatMoney(superTotal.supeAdjustments)}
                    />
                    <hr/>
                    <ResumeRow
                        label={"Total" + dictionary.fee}
                        dot="#5AE2B1"
                        ammount={this.props.formatMoney(superTotal.totalCuota)}
                    />
                </div>
            </div>
        );
    }
}

export default Resume;
