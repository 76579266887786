import React from 'react';

const UserIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 18 21"
            className = {className}
        >
            <path 
                fill={color}  
                d="M9 .083c.84 0 1.662.25 2.361.717.7.466 1.244 1.13 1.566 1.907.321.776.405 1.631.241 2.455-.164.825-.568 1.582-1.163 2.176-.594.595-1.351 1-2.176 1.164-.824.164-1.679.08-2.455-.242-.777-.322-1.44-.867-1.908-1.566-.467-.698-.716-1.52-.716-2.36 0-1.128.448-2.209 1.245-3.006C6.792.531 7.873.083 9 .083zM9 20.2c-1.683 0-3.34-.417-4.823-1.213C2.694 18.192 1.43 17.041.5 15.638c.042-2.819 5.667-4.363 8.5-4.363s8.457 1.544 8.5 4.363c-.93 1.403-2.194 2.554-3.677 3.35C12.34 19.782 10.683 20.2 9 20.2z"
            />
        </svg>

    )
}
export default UserIcon;
