import React, { Component } from 'react';
import {
    Link
} from 'react-router-dom';
import axios from 'axios';
import './style.css';

class FooterCompliances extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {}
        }
    }

    async setData() {
        const siteInformation = localStorage.getItem('footerData');
        if (siteInformation)
            this.setState({data: JSON.parse(siteInformation)});
        else
            axios.get('/api/v2/footer')
                .then(res => {
                    if (res.status === 200 && res.data) {
                        this.setState({data: res.data});
                        localStorage.setItem('footerData', JSON.stringify(res.data));
                    }
                })
                .catch(err => {
                    console.log(err);
                });
    }

    componentDidMount() {
        this.setData();
    }

    render() {
        const color = this.props.color;
        // const dictionary = this.props.dictionary;
        const data = this.state.data
        if(window.location.hostname==="carotrueba28.com"){
            data.entertainer_brand_link = "#";
            data.entertainer_brand_alias = "Caro Trueba";
        }
        const brand = {
            name: data.entertainer_brand_alias || "Team Peach ",
            link: data.entertainer_brand_link || "https://teampeach.app",
        };
        return (
            <div style={{backgroundColor: color.principal}} className="main-container-footer">
                <div className='fake-space'/>
                <div className='footer-container' style={{backgroundColor: color.principal}}>
                    <div>
                        <p><Link to='/compliances' style={{color: color.principalText}}>18 U.S.C. 2257 Record-Keeping
                            Requirements Compliance Statement</Link></p>
                        {brand
                            ?
                            <p>
                                <a style={{color: color.secondary}} href={brand.link}>
                                    Powered by {brand.name}
                                </a>
                            </p>
                            : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default FooterCompliances;
