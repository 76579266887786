import React, {Component} from 'react';
import axios from "axios";
import Modal from "../Modal";
import ModalConfirmNumber from "../ModalConfirmNumber";
import {Oval, Preloader} from 'react-preloader-icon';
import "./getCoins.css";

class GetCoins extends Component {
    constructor(props) {
        super(props);

        this.state = {
            option: 0,
            catalogCoin: [],
            baseUri: window.location.hostname === "localhost"
                ? 'http://localhost:5000'
                : 'https://cms.socialtechapps.com',
            confirmed: false,
            showConfirmBuy: false,
            processingBuy: false,
            isBuyConfirm: null
        }

        this.handleCoinChange = this.handleCoinChange.bind(this);
        this.showCoinsModal = this.showCoinsModal.bind(this);
        this.buyCoins = this.buyCoins.bind(this);
    }

    componentDidMount() {
        this.hasEverBuyCoins();
        this.loadCatalog();
    }

    handleCoinChange(e) {
        const option = e.target.value;
        this.setState({option});
    }

    showCoinsModal() {
        this.setState({
            confirmed: true
        });
    }

    hasEverBuyCoins() {
        axios.get('/api/v2/get/userIsConfirmed')
            .then(resp => {
                this.setState({confirmed: resp.data.confirmed})
            });
    }

    loadCatalog() {
        axios.get('/api/v2/extra/charge/catalog/')
            .then(res => {
                if (res.status === 200) {
                    this.setState({
                        catalogCoin: res.data
                    });
                }
            });
    }

    buyCoins = (e) => {
        e.preventDefault();
        const {option, catalogCoin} = this.state;
        if (catalogCoin[option]) {
            const {url, paymentProcessor} = catalogCoin[option];
            if (paymentProcessor === 1) {
                if (url)
                    window.location.href = url;
            } else {
                const {k} = catalogCoin[option];
                this.setState({processingBuy: true});
                axios.post('/api/v2/ccbill/purchase-coin', {k})
                    .then(res => {
                        let isBuyConfirm = false;
                        if (res.status === 200)
                            isBuyConfirm = res.data;

                        this.setState({processingBuy: false, isBuyConfirm});
                    });
            }
        }
    }

    drawOptions() {
        let dictionary = this.props.dictionary;
        let catalog = [...this.state.catalogCoin];
        return catalog.map((item, index) => {
            return (
                <option key={index} value={index}>
                    {item.coins} {dictionary.coins} - $ {item.price}{" "}
                    {item.currency}
                </option>
            );
        });
    }

    drawModalComponents() {
        const {dictionary, config, close} = this.props;
        const {option, catalogCoin, showConfirmBuy, processingBuy, isBuyConfirm} = this.state;
        const size = catalogCoin.length;
        const purchaseStyle = {
            backgroundColor: config.color.secondary,
            color: config.color.secondaryText,
        };

        let content = <div><p>{dictionary.notAviable}</p></div>;
        let footer = <div className="actions">
            <button style={purchaseStyle}
                    onClick={() => close()}
            >
                {dictionary.close}
            </button>
        </div>

        if (size) {
            const {paymentProcessor} = catalogCoin[option];
            content = <div className="input-select-coins">
                <p>{dictionary.coins}</p>
                <select onChange={this.handleCoinChange}
                        style={{color: config.color.principalText}}
                >
                    {this.drawOptions()}
                </select>
            </div>;
            footer = <div className="actions">
                <button style={purchaseStyle}
                    onClick={
                        paymentProcessor === 1
                            ? (e) => this.buyCoins(e)
                            : (e) => {
                                this.setState({showConfirmBuy: true})
                            }
                    }
                >
                    {dictionary.buy}
                </button>
            </div>;
        }

        if (showConfirmBuy) {
            const {price, currency} = catalogCoin[option];
            const priceLeyend = price + " " + currency;
            content = <div>
                <p>{dictionary.ccbillMsgCoinPurchase
                    .replace(/--button--/g, dictionary.buy)
                    .replace(/--quantity--/g, priceLeyend)}
                </p>
            </div>
            footer = <div className="actions">
                <button
                    style={{
                        backgroundColor: config.color.third,
                        color: config.color.thirdText,
                    }}
                    onClick={(e) => {
                        this.setState({showConfirmBuy: false, option: 0})
                    }}
                >
                    {dictionary.back}
                </button>
                <button style={purchaseStyle}
                    onClick={(e) => this.buyCoins(e)}
                >
                    {dictionary.buy}
                </button>
            </div>;
        }

        if (processingBuy) {
            content = <div className={'flex-center'}>
                <Preloader use={Oval}
                           size={60}
                           strokeWidth={6}
                           strokeColor={config.color.principalText}
                           duration={600}/>
            </div>;
            footer = null;
        }

        if(isBuyConfirm !== null){
            content = isBuyConfirm
                ? <div>
                    <p>{dictionary.successfulPurchase}</p>
                </div>
                : <div>
                    <p>{dictionary.purchaseError}</p>
                </div>
            footer = isBuyConfirm
                    ? null
                : <div className="actions">
                <button
                    style={{
                        backgroundColor: config.color.third,
                        color: config.color.thirdText,
                    }}
                    onClick={(e) => {
                        this.setState({isBuyConfirm: null})
                    }}
                >
                    {dictionary.back}
                </button>
            </div>;
        }


        return {content, footer};
    }

    render() {
        const {dictionary, config, id, open, close} = this.props;
        if (!this.state.confirmed)
            return <ModalConfirmNumber
                open={open}
                dictionary={dictionary}
                config={config}
                close={close}
                showCoinsModal={this.showCoinsModal}
            />

        const modalComponents = this.drawModalComponents();
        return (<Modal
                id={id || "modalBuyCoin"}
                head={<h4>{dictionary.buy} {dictionary.coins}</h4>}
                config={config}
                footer={modalComponents.footer}
                open={open}
                closeAction={() => {
                    this.setState({
                        showConfirmBuy: false,
                        processingBuy: false,
                        isBuyConfirm: null,
                        option: 0
                    });
                    close();
                }}
            >
                {modalComponents.content}
            </Modal>
        );
    }
}

export default GetCoins;
