import axios from "axios";
import moment from "moment";

let toolbox = {};

// Maybe can use exportable functions
// import { searchParams } from "/components/Toolbox/Toolbox";
toolbox.searchParams = () => {
    let params = {};
    let parts = window.location.search.substring(1).split('&');
    for (let i = 0; i < parts.length; i++) {
        var nv = parts[i].split('=');
        if (!nv[0]) continue;
        params[nv[0]] = nv[1] || true;
    }
    return params;
};

/**
 *
 * @param {string} module Catalogue site module 1, 2, 3 ...
 *
 * @param {error} error Error object widh error and stack properties
 *
 * @param {*} options some custome values
 */

toolbox.sendError = (error, module = null, options = {}) =>{

    const { response } = error;

    let errorConstructor = {
        options,
        module
    };

    if (response){
        if (response.config){
            options.detailsError = {
                urlError: response.config.url,
                response: response.config.method,
                dataPassed: response.config.data
            };
        }
        errorConstructor.stack = response.data || null;
        errorConstructor.error = response.status || null;
    }
    else errorConstructor.error = error;

    axios.post('/api/v2/send/error', errorConstructor);

    console.error(response);

};

toolbox.isBottom = (el) => {
    return Math.floor(el.getBoundingClientRect().bottom) <= window.innerHeight + (window.innerHeight * .75);
}


toolbox.formateDatePayment=(dateFormat, lenguage) =>{
    let localdateFormat;
    if(lenguage ==="es"){
        moment.locale( "es");
        localdateFormat = moment(dateFormat).format("D MMM YYYY");
    }
    else if(lenguage ==="en") {
        moment.locale( "en");
        localdateFormat = moment(dateFormat).format("MMM D YYYY");
    }
    else {
        moment.locale( "es");
        localdateFormat = moment(dateFormat).format("D MMM YYYY");
    }
    return localdateFormat;
}

toolbox.isSafari=() => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export default toolbox;
