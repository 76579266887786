import React, { Component } from "react";
import axios from "axios";
import cookie from "js-cookie";
import moment from "moment";
import Resume from './Resume';
import Graph from './Graph';
import { Circle } from "react-preloaders";
import './style.css';
import Toolboox from "../../Toolbox/Toolbox";

class Ventas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            averageEarnings: 0,
            periodEarninNews: [],
            periodEarninRenews: [],
            periodEarninCoins: [],
            superTotal: {},
            subtotal: [],
            periodEarningDays: [],
            onlyModel: "2",
            dataset: [],
            baseUri: window.location.hostname === "localhost"
                ? 'http://localhost:5000'
                : 'https://cms.socialtechapps.com',
            loadingEarning: true
        }
    }

    componentDidMount() {
        this.loadEarningPeriod(this.state.onlyModel);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(
            prevProps.period.start !== this.props.period.start
            || prevProps.period.end !== this.props.period.end
            || prevState.onlyModel !== this.state.onlyModel

        ) {
            this.loadEarningPeriod(this.state.onlyModel);
        }
    }


    changePorcentage = (e, option) => {
        this.setState({onlyModel : option})
    }

    loadEarningPeriod = (onlyModel) =>{
        let start = this.props.period.start;
        let end = this.props.period.end;
        this.setState({
            averageEarnings : 0,
            periodEarninNews : [],
            periodEarninRenews : [],
            periodEarninCoins : [],
            superTotal: {},
            subtotal: [],
            loadingEarning: true
        });
        axios
            .post(
                this.state.baseUri + "/api/v2/external/analytics/earning/model/",
                {
                    username: cookie.get("username"),
                    token: cookie.get("token"),
                    hostname:
                        (window.location.hostname === "localhost" || window.location.hostname === "beta.myladydusha.com")
                            ? "lawanderslover.com"
                            : window.location.hostname
                },
                {
                    params: {
                        firstInterval: start,
                        lastInterval: end,
                        onlyModel: onlyModel
                    }
                }
            )
            .then(async res => {
                if(!res.data){
                    this.setState({
                        loadingEarning: false
                    });
                    return false;
                }
                const dates = res.data.map(day => {
                    return moment(day.date).format("MMM D");
                });

                let totalNews = [];
                let totalRenews = [];
                let totalCoins = [];
                let supertotal = [];
                let subtotal = [];
                //let tableDataPeriodEarning = [];
                let superTotalNews = 0;
                let superTotalRenews = 0;
                let superTotalCoins = 0;
                let superDuperTotal = 0;
                //variables to know period
                let firstSaturday = false;
                let havePayPeriod = false;
                let superProcessFee = 0;
                let superTotalRefunds = 0;
                let superChargeback = 0;
                let supeAdjustments = 0;
                let superChargebackFee = 0;
                let supeRefundsFee = 0;
                res.data.map((day, index) => {
                    /*
                if have pay period
                sortDate checkbox it activates
                */
                    let date = moment(day.date).day();
                    if (date === 6 && !firstSaturday) {
                        firstSaturday = true;
                    }
                    if (date === 5 && firstSaturday && !havePayPeriod) {
                        havePayPeriod = true;
                    }
                    //sum to get Totales
                    superTotalNews += parseFloat(day.totalNews || 0);
                    superTotalRenews += parseFloat(day.totalRenews || 0);
                    superTotalCoins += parseFloat(day.totalCoins || 0);
                    superDuperTotal += parseFloat(day.supertotal || 0);
                    superProcessFee += parseFloat(day.processFee || 0);
                    superTotalRefunds += parseFloat(day.totalRefounds || 0);
                    superChargeback += parseFloat(day.chargeback || 0);
                    supeAdjustments += parseFloat(day.adjustements || 0);
                    superChargebackFee += parseFloat(
                        day.countChargeback * 12.5 || 0
                    );
                    supeRefundsFee -= parseFloat(day.countRefunds || 0);
                    //Graph values
                    const sumSubtotal =
                        day.totalNews +
                        day.totalCoins +
                        day.totalRenews ;
                    totalNews.push(day.totalNews);
                    totalRenews.push(day.totalRenews);
                    totalCoins.push(day.totalCoins);
                    supertotal.push(day.supertotal);
                    subtotal.push(sumSubtotal);
                    return true;
                });
                const average = (
                    parseFloat(superDuperTotal) / supertotal.length
                ).toFixed(2);
                const superSubTotal =
                    superTotalNews +
                    superTotalRenews +
                    superTotalCoins;
                superChargeback -= superChargebackFee;
                superTotalRefunds += supeRefundsFee;
                //superProcessFee-= superTotalRefunds;
                const totalCuota = superSubTotal - superDuperTotal;
                const superTotal2 = {
                    date: "Total",
                    totalNews: this.props.formatMoney(superTotalNews),
                    totalRenews: this.props.formatMoney(superTotalRenews),
                    totalCoins: this.props.formatMoney(superTotalCoins),
                    supertotal: this.props.formatMoney(superDuperTotal),
                    superSubTotal: this.props.formatMoney(superSubTotal),
                    superProcessFee: superProcessFee,
                    superTotalRefunds: superTotalRefunds,
                    superChargeback: superChargeback,
                    supeAdjustments: supeAdjustments,
                    totalCuota: this.props.formatMoney(totalCuota)
                };
                this.setState({
                    averageEarnings: average,
                    havePayPeriod: !havePayPeriod,
                    periodEarningDays: dates,
                    periodEarninNews: totalNews,
                    periodEarninRenews: totalRenews,
                    periodEarninCoins: totalCoins,
                    superTotal: superTotal2,
                    subtotal: subtotal,
                    loadingEarning: false
                    //tableDataPeriodEarning : tableDataPeriodEarning
                });
                this.getDataSet();
            }).catch( e => {
                this.setState({
                    loadingEarning: false
                })
        });
    }

    getDataSet = () => {
        let dictionary = this.props.dictionary
        let dataset = [
            {
                label               : dictionary.newMembers,
                borderColor         : '#692FE1',
                fill                : false,
                borderWidth         : 1,
                pointRadius         : 4,
                pointBorderWidth    : 2.5,
                pointBorderColor    : '#692FE1',
                data                : this.state.periodEarninNews
            },
            {
                label               : dictionary.renewal,
                borderColor         : '#E39DCD',
                fill                : false,
                borderWidth         : 1,
                pointRadius         : 4,
                pointBorderWidth    : 2.5,
                pointBorderColor    : '#E39DCD',
                data                : this.state.periodEarninRenews
            },
            {
                label               : dictionary.coins,
                borderColor         : '#5AB6E2',
                fill                : false,
                borderWidth         : 1,
                pointRadius         : 4,
                pointBorderWidth    : 2.5,
                pointBorderColor    : '#5AB6E2',
                data                : this.state.periodEarninCoins
            },
            {
                label               : 'Subtotal',
                borderColor         : '#5AE2B1',
                fill                : false,
                borderWidth         : 1,
                pointRadius         : 4,
                pointBorderWidth    : 2.5,
                pointBorderColor    : '#5AE2B1',
                data                : this.state.subtotal
            }

        ]
        this.setState({dataset : dataset})
    }

    render() {
        let dictionary = this.props.dictionary;
        let period = this.props.period;
        let show = this.props.show;
        let loadingEarning = <div className="loaderElement large" >
            <Circle background={"transparent !important"} color={this.props.config.color.secondary} />
        </div>;
        return (
            <div className={(show) ? "show sales" : "sales"}>
                <div className="percentage-erning">
                    <h6>
                        * {this.props.dictionary.sales} USD&nbsp;
                        ({
                            Toolboox.formateDatePayment(period.start, this.props.dictionary.languageKey)
                        } &rarr; {
                        Toolboox.formateDatePayment(period.end, this.props.dictionary.languageKey)
                    })
                    </h6>
                    <label>
                        <input
                            type="radio"
                            name="onlyModel"
                            className="input-st"
                            value="2"
                            onChange={(e) => this.changePorcentage(e, "2")}
                            checked={this.state.onlyModel === "2"}
                        />
                        <span className="span-st">
                            {dictionary.mySplit +
                            " (" +
                            this.props.percentage +
                            "%)"}
                        </span>
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="onlyModel"
                            className="input-st"
                            value="0"
                            onChange={(e) => this.changePorcentage(e,"0")}
                            checked={this.state.onlyModel === "0"}
                        />
                        <span className="span-st">Total (100%)</span>
                    </label>
                    {this.state.loadingEarning
                        ? loadingEarning
                        :
                        <>
                            <Resume
                                dictionary={dictionary}
                                superTotal={this.state.superTotal}
                                formatMoney={this.props.formatMoney}
                            />
                            <Graph
                                dictionary={dictionary}
                                superTotal={this.state.superTotal}
                                formatMoney={this.props.formatMoney}
                                dataset={this.state.dataset}
                                periodEarningDays={this.state.periodEarningDays}
                                config={this.props.config}
                            />
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default Ventas;
