import React from 'react';
import { Link } from 'react-router-dom';
import CoinsIcon from '../../../Svg/CoinsIcon';
import HomeIcon from '../../../Svg/HomeIcon';
import MessagesIcon from '../../../Svg/MessagesIcon';
import LogoutIcon from '../../../Svg/LogoutIcon';
import UserIcon from '../../../Svg/UserIcon';
import ListIcon from '../../../Svg/ListIcon';

const SideItemsLogged = ({activeSection, logOut, admin, dictionary, config, coins, canSellCoins, toElevate, buyCoins}) => {

    return(
        <div style={{width: 200}} >
            <ul className="itemLogged">
                <li
                    className={activeSection==="/" ? "active-item" : ""}
                    style={
                        activeSection==="/" ?
                            {backgroundColor:config.color.secondary}
                        : {backgroundColor:"transparent"}
                    }
                >
                    <Link to='/'>
                        <HomeIcon
                            className="menu-name"
                            color={activeSection ==="/" ? "#fff" : "#c4c4c4"}
                            width={"20px"}
                            height={"18px"}
                        />
                        {dictionary.home}
                    </Link>
                </li>
                <li
                    className={activeSection==="/messages" ? "active-item" : ""}
                    style={
                        activeSection==="/messages" ?
                            {backgroundColor:config.color.secondary}
                        : {backgroundColor:"transparent"}
                    }
                >
                    <Link to='/messages'>
                        <MessagesIcon
                            color={activeSection ==="/messages" ? "#fff" : "#c4c4c4"}
                            width={"20px"}
                            height={"18px"}
                            className="menu-name"
                        />
                        {dictionary.messages}
                    </Link>
                </li>
                <li
                    className={activeSection==="/profile" ? "active-item" : ""}
                    style={
                        activeSection==="/profile" ?
                            {backgroundColor:config.color.secondary}
                        : {backgroundColor:"transparent"}
                    }
                >
                    <Link to='/profile'>
                        <UserIcon
                            color={activeSection ==="/profile" ? "#fff" : "#c4c4c4"}
                            width={"20px"}
                            height={"20px"}
                            className="menu-name"
                        />
                        {dictionary.profile}
                    </Link>
                </li>
                { admin }
                <li
                    className={activeSection==="/activity" ? "active-item" : ""}
                    style={
                        activeSection==="/activity" ?
                            {backgroundColor:config.color.secondary}
                        : {backgroundColor:"transparent"}
                    }
                >
                    <Link to='/activity'>
                        <ListIcon
                            color={activeSection ==="/activity" ? "#fff" : "#c4c4c4"}
                            width={"20px"}
                            height={"20px"}
                            className="list-menu menu-name"
                        />
                        {dictionary.activity}
                    </Link>
                </li>
                { canSellCoins ?
                    <li>
                        <button
                            className='btn btn-coin'
                            onClick={buyCoins}
                            style={{
                                backgroundColor:config.color.principal,
                                color:config.color.secondary
                            }}
                        >
                            <CoinsIcon
                                color={config.color.secondary}
                                colorPrincipal={config.color.principal}
                                width={"21px"}
                                height={"21px"}
                            />
                            {(coins > 999) ? "999+" : coins}
                        </button>
                        <div className='hide'>

                        </div>
                    </li>
                : null }
                <li>
                    <a href='#LogOut' onClick={logOut}>
                        <LogoutIcon
                            color={"#c4c4c4"}
                            width={"20px"}
                            height={"20px"}
                            className="menu-name"
                        />
                        {dictionary.logout}
                    </a>
                </li>
            </ul>
        </div>
    )
};
export default SideItemsLogged;
