import React, { Component } from 'react';
import cookie from 'js-cookie';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import axios from 'axios';
import { Circle } from 'react-preloaders';
import Ionicon  from 'react-ionicons'
import SideNav from '../../components/NavBar/SideNav';
import SupportIcon from '../../components/Svg/SupportIcon';
import SupportModal from '../../components/Support/SupportModal';
import '../Login/styleLogin.css';
import './style.css';

class Support extends Component {
    constructor(props){
        super(props);
        this.changeHandler=this.changeHandler.bind(this);
        this.changeHandlerSelect=this.changeHandlerSelect.bind(this);
        this.keyPress=this.keyPress.bind(this);
        this.state={
            name: cookie.get('username') || '',
            email:  cookie.get('email') || '',
            subject: '',
            subjectOther: '',
            message:'',
            pageSupport:false,
            nombreError: false,
            emailError:false,
            subjectError:false,
            messageError:false,
            classMessageSend: '',
            showModal: false,
            showForm: false
        };
        this.fireTracking();
    }
    fireTracking = () => {
      ReactGA.pageview(window.location.pathname);
    }
    changeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    changeHandlerSelect(e) {
        if(e.target.selectedIndex === 0){
            return;
        } else if (e.target.selectedIndex === 7) {
            this.setState({ [e.target.name]: e.target.value, showForm: true});
        } else {
            this.setState({
                [e.target.name]: e.target.value,
                showForm: false,
                showModal: true,
                modalAction : "support",
                optionSupp: e.target.selectedIndex
            });
        }
    }
    closeModal = async () => {
        this.setState({showModal : false})
    }
    openOther = async () => {
        this.setState({subject: this.props.dictionary.FAQ.q6, showModal : false, showForm: true})
    }
    supportMe = async () =>{
        let error=false;
        document.getElementById("input_0").classList.remove("sign-error");
        document.getElementById("input_1").classList.remove("sign-error");
        document.getElementById("input_2").classList.remove("sign-error");
        document.getElementById("input_3").classList.remove("sign-error");

        this.setState({
            nombreError:false,
            emailError: false,
            subjectError: false,
            messageError: false
        });
        if (this.state.name.length < 3){
            document.getElementById("input_0").classList.add("sign-error");
            document.getElementById("input_0").focus();
            await this.setState({nombreError: this.props.dictionary.nombreError});
            error=true;
        }
        if (this.state.email.length < 3 || !this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
            document.getElementById("input_1").classList.add("sign-error");
            document.getElementById("input_1").focus();
            await this.setState({emailError:this.props.dictionary.emailError});
            error=true;
        }
        if (this.state.subjectOther.length < 4){
            document.getElementById("input_2").classList.add("sign-error");
            document.getElementById("input_2").focus();
            await this.setState({subjectError:this.props.dictionary.obligatory});
            error=true;
        }
        if (this.state.message.length < 20){
            document.getElementById("input_3").classList.add("sign-error");
            document.getElementById("input_3").focus();
            await this.setState({messageError:this.props.dictionary.messageError});
            error=true;
        }
        if(error) return;
        const support={
            name: this.state.name,
            email: this.state.email,
            subject: this.state.subjectOther,
            message: this.state.message
        }
        axios.post('/api/v2/support', support)
        .then(res=>{
            document.getElementById("btnSendSupport").disabled = true;
            document.getElementById("btnSendSupport").classList.add("disable-button");
            this.setState({classMessageSend:'hide'});
            setTimeout(()=>{
                window.location.href='/';
            }, 6000);
        })
    }
    keyPress(event) {
        if (event.key === 'Enter') {
            this.supportMe()
        }
    }
    showSupportPage=()=>{
        const flag = this.state.pageSupport;
        this.setState({recoverMode: !flag});
    }
    drawFAQ(){
        let subjects = [
            {'id': "q1", 'title': this.props.dictionary.FAQ.q1 },
            {'id': "q8", 'title': this.props.dictionary.FAQ.q8 },
            {'id': "q2", 'title': this.props.dictionary.FAQ.q2 },
            {'id': "q3", 'title': this.props.dictionary.FAQ.q3 },
            {'id': "q4", 'title': this.props.dictionary.FAQ.q4 },
            {'id': "q5", 'title': this.props.dictionary.FAQ.q5 },
            {'id': "q6", 'title': this.props.dictionary.FAQ.q6 }
        ];
        return subjects.map((el, index) => {
            return (
                <option key={index + 1} value={el.title}>{el.title}</option>
            )
        })
    }
    renderObject(){
        if(this.props.dictionary.length===0 || this.props.config.length===0 ) return <div className="center-align" style={{paddingTop:45}}><Circle background="transparent !important" color="#4285f4" className="center-align"/></div>;
        let logged = cookie.get('token') && this.props.toElevate && this.props.toElevate.authenticated;
        return(
            <div className="redesign-log-in">
                <div className={this.state.classMessageSend + "message-container"}>
                    <h5 className={"center-align"}>
                        <SupportIcon
                            color={this.props.config.color.principalText}
                            width={"2em"}
                            height={"2em"}
                        />
                    </h5>
                    <h5
                        className="sup-title left-align"
                        style={{
                            color: this.props.config.color.principalText,
                        }}
                    >
                        {this.props.dictionary.siteProblemTitle}
                        {/*this.props.dictionary.siteProblem*/}
                    </h5>
                    <div className="input-field-login">
                        <label htmlFor="input_subject">
                            <Ionicon
                                icon='md-create'
                                fontSize="25px"
                                color="rgb(92, 92, 92)"
                            />
                            {this.props.dictionary.subject}
                        </label>
                        <select
                            name='subject'
                            id="input_subject"
                            onChange={this.changeHandlerSelect}
                            value={this.state.subject}
                            className="center-align register-inputs"
                            onKeyPress={this.keyPress}
                            type="text"
                            style= {{
                                color: this.props.config.color.principalText
                            }}
                        >
                            <option value={0}>{this.props.dictionary.seloption}</option>
                            {this.drawFAQ()}
                        </select>
                    </div>
                    { this.state.showForm ?
                        <div>
                            <div className="input-field-login">
                                <input
                                    id="input_2"
                                    placeholder={this.props.dictionary.subject}
                                    className="new-subject center-align register-inputs"
                                    onKeyPress={this.keyPress}
                                    type="text"
                                    maxLength="120"
                                    name='subjectOther'
                                    onChange={this.changeHandler}
                                    value={this.state.subjectOther}
                                _/>
                                {this.state.subjectError ?
                                    <div className="log-error-class">{this.state.subjectError}</div>
                                : null}
                            </div>
                            <div className="input-field-login">
                                <label htmlFor="input_0">
                                    <Ionicon
                                        icon='ios-person'
                                        fontSize="25px"
                                        color="rgb(92, 92, 92)"
                                    />
                                    {this.props.dictionary.name}
                                </label>
                                <input
                                    id="input_0"
                                    type="text"
                                    name='name'
                                    maxLength="50"
                                    className={(logged ? "disabled-style" : "") + " center-align register-inputs"}
                                    onKeyPress={this.keyPress}
                                    placeholder={this.props.dictionary.name}
                                    onChange={this.changeHandler}
                                    value={logged ? this.state.name: null}
                                    disabled={logged ? true : false}
                                />
                                {this.state.nombreError ?
                                    <div className="log-error-class">{this.state.nombreError}</div>
                                : null}
                            </div>
                            <div className="input-field-login">
                                <label htmlFor="input_1">
                                    <Ionicon
                                        icon='ios-mail'
                                        fontSize="25px"
                                        color="rgb(92, 92, 92)"
                                    />
                                    {this.props.dictionary.email}
                                </label>
                                <input
                                    type="email"
                                    maxLength="120"
                                    id="input_1"
                                    name='email'
                                    value={logged ? this.state.email : null}
                                    onKeyPress={this.keyPress}
                                    className="center-align register-inputs"
                                    placeholder={this.props.dictionary.email}
                                    onChange={this.changeHandler}
                                />
                                {this.state.emailError ?
                                    <div className="log-error-class">{this.state.emailError}</div>
                                : null}
                            </div>
                            <div className="input-field-login">
                                <label htmlFor="input_3">
                                    <Ionicon
                                        icon='ios-create'
                                        fontSize="25px"
                                        color="rgb(92, 92, 92)"
                                    />
                                    {this.props.dictionary.message}
                                </label>
                                <textarea
                                    id="input_3"
                                    onKeyPress={this.keyPress}
                                    className="textarea-border textareaFilled"
                                    maxLength="250"
                                    name='message'
                                    onChange={this.changeHandler}
                                    value={this.state.message}
                                    placeholder={this.props.dictionary.message}
                                />
                                {this.state.messageError ?
                                    <div className="log-error-class">{this.state.messageError}</div>
                                : null}
                            </div>
                            <div className="center-align">
                                <button
                                    onClick={this.supportMe}
                                    style={{
                                        backgroundColor: this.props.config.color.secondary,
                                        color: this.props.config.color.secondaryText
                                    }}
                                    className="log-btn large-send-button"
                                    id="btnSendSupport"
                                >
                                    {this.props.dictionary.send}
                                </button>
                            </div>
                            <div
                                style={{
                                    color: this.props.config.color.secondary
                                }}
                                className={!this.state.classMessageSend ? 'hidemensajeSuccess' : 'center success-support'}
                            >
                                {this.props.dictionary.mensajeSuccess}
                            </div>
                            <Link to="/support/faq">
                                <button
                                    style={{
                                        color: this.props.config.color.principalText,
                                        borderColor: this.props.config.color.principalText
                                    }}
                                    className="faq-btn"
                                >
                                    {this.props.dictionary.frequentQA}
                                </button>
                            </Link>
                        </div>
                    : null }
                </div>

                <h6
                    className="sup-title left-align payment"
                >
                    {this.props.dictionary.paymentProblemTitle}
                    {/*this.props.dictionary.billingProblem*/}
                </h6>
                {this.renderPaymentContact()}
            </div>
        )
    }
    renderPaymentContact(){
        if(window.location.hostname==="localhost"
            || window.location.hostname === "mypeachdemo.com"
            || window.location.hostname === "adileneidalie96.com"
            || window.location.hostname === "ccbill.socialtechapps.com"
            || window.location.hostname === "beta.myladydusha.com") {
            return(
                <div style={{ textAlign: "left"}}>

                    <div>{this.props.dictionary.chargesMadeBy} <b>Epoch</b> {this.props.dictionary.visit}:</div>
                    <div>
                        <a
                        className='link-text'
                        href='https://epoch.com/'
                        style={{color: this.props.config.color.secondary}}
                    >
                        https://epoch.com
                        </a>
                    </div>
                    <br/>
                    <div>{this.props.dictionary.chargesMadeBy} <b>CCBill</b> {this.props.dictionary.visit}:</div>
                    <div>
                        <a
                            className='link-text center-align'
                            href='https://support.ccbill.com'
                            style={{color: this.props.config.color.secondary}}
                        >
                            https://support.ccbill.com
                        </a>
                    </div>


                    <ul className="list-contact-info">
                        <li><b>{this.props.dictionary.CCBillContInf}</b></li>
                        <li>{this.props.dictionary.emailAdress}: <a className='link-text' style={{color: this.props.config.color.secondary}} href="mailto:support@ccbill.com">support@ccbill.com</a></li>
                        <li>{this.props.dictionary.phoneNumber}: <a className='link-text' style={{color: this.props.config.color.secondary}} href="tel:+18885969279">+1 (888) 596-9279</a></li>
                    </ul>
                    <ul className="list-contact-info">
                        <li><b>{this.props.dictionary.companyInfo}</b></li>
                        <li>Team Peach LLC</li>
                        <li>Middletown, Delaware</li>
                        <li>United States</li>
                    </ul>
                </div>
            );
        }
        return(
            <h6 className='center-align'>
                <a
                    className='link-text'
                    href='https://epoch.com/'
                    style={{color: this.props.config.color.secondary}}
                >
                    https://epoch.com/
                </a>
            </h6>
        );
    }
    render(){
        return(
            <div
                className="redesign"
                style={{
                    backgroundColor:this.props.config.color.navbar,
                    color: this.props.config.color.principalText
                }}
            >
                <SideNav
                    toElevate={this.props.toElevate}
                    dictionary={this.props.dictionary}
                    config={this.props.config}
                    view={this.props.view ? this.props.view : false}
                />
                <div
                    id="container-support"
                    className="container main-container center-align"
                    style={{
                        zIndex: 0,
                        backgroundColor : this.props.config.color.principal
                    }}
                >
                    {this.renderObject()}
                </div>
                {this.state.showModal ?
                    <SupportModal
                        dictionary={this.props.dictionary}
                        config={this.props.config}
                        changeHandlerSelect={this.changeHandlerSelect}
                        closeModal={this.closeModal}
                        subject={this.state.subject}
                        optionSupp={this.state.optionSupp}
                        showModal={this.state.showModal}
                        modalAction={this.state.modalAction}
                        openOther={this.openOther}
                        getPiCodes={this.props.getPiCodes}
                    />
                : null }
            </div>
        )
    }
}
export default Support;
