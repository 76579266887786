import React, { Component } from "react";
import {Line} from "react-chartjs-2";

class MasiveGraph extends Component {

    // constructor(props) {
    //     super(props);
    // }

    render() {
        let dictionary=this.props.dictionary;
        return (
            <div>
                <div className="activity-graph">
                    <Line
                        height={250}
                        data={{
                            labels: this.props.masiveSendDays,
                            datasets: [{
                                label: dictionary.masiveValues,
                                backgroundColor     : 'rgba(220,220,220,0.1)',
                                borderColor         : 'rgba(220,220,220,1)',
                                borderWidth         : 1,
                                pointRadius         : 4,
                                pointBorderWidth    : 2.5,
                                pointBorderColor    : 'rgba(220,220,220,1)',
                                data: this.props.masiveValues
                            }]
                        }} options={this.props.options}/>
                </div>
                <div className="legends">
                    <label>
                        <span
                            className="dot"
                            style={{backgroundColor: "rgba(220,220,220,1)"}}
                        ></span>
                        {dictionary.massMsnSold}
                    </label>
                </div>
            </div>
        );
    }
}

export default MasiveGraph;
