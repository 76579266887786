import React, { Component } from 'react';
import MediaBoxElement from "../MediaBoxElement";
import axios from "axios";
import './style.css';
import Modal from '../../Modal';
import Checkbox from "../../Checkbox";
import Promise from "bluebird";
import {Cube} from 'react-preloaders'

class MediaBoxSender extends Component{
    constructor(props) {
        super(props);
        this.state = {
            mediaBox : [],
            choosen : null,
            idMediaBox : null,
            step : 0,
            sendTo : [],
            userSendMediaBox : [],
            sendToAll : true,
            message : "",
            sending : false,
            error : null,
            success : null
        }
        this.handleTextarea = this.handleTextarea.bind(this)
    }

    componentDidMount() {
        this.getMediaBox()
    }

    resetSender = () => {
        this.setState({
            choosen : null,
            step : 0,
            sendTo : [],
            idMediaBox : null,
            userSendMediaBox : [],
            sendToAll : true,
            message : "",
            sending : false,
            error : null,
            success : null
        })
    }

    closeSender = () => {
        this.props.closeMediaBoxSender();
        this.setState({
            choosen : null,
            step : 0,
            sendTo : [],
            idMediaBox : null,
            userSendMediaBox : [],
            sendToAll : true,
            message : "",
            sending : false,
            error : null,
            success : null
        })
    }

    handleTextarea = (e) => {
        let value = e.target.value;
        this.setState({message : value})
    }

    sendMediaBoxFile = async (id) => {
        this.setState({
            loading : true,
            error : null
        })
        await axios.get('/api/v2/stored/media/users', {params: {media: id}}).then(async (res) => {
            let users = res.data;
            if(users.length) {
                this.setState({step: 1, idMediaBox: id});
                this.setState({
                    userSendMediaBox: res.data,
                    openModalMediaBox: true,
                    mediaBoxResponse: id,
                    loading : false
                });
                this.setRecieversUsers()
            }
            else{
                this.setState({
                    loading : false,
                    error : this.props.dictionary.mediaboxNotFoundUsers
                })
            }
        })
    }

    setRecieversUsers = async() => {
        let options = [...this.state.userSendMediaBox];
        let sendTo = [];
        await options.map( (op, i) => {
            return sendTo.push(op.user);
        })
        await this.setState({sendTo : sendTo});
    }

    prevStep = async () => {
        this.setState({step : 0, error : null});
    }

    nextStep = async () => {
        let choosen = this.state.choosen;
        if(choosen !== null){
            let media = this.state.mediaBox;
            let id = media[choosen].id;
            await this.sendMediaBoxFile(id);
        }
    }

    playVideo = async (e) => {
        let video = e.target;
        if(video.paused) {
            video.play();
            video.nextSibling.classList.add("playing");
        }
        else{
            video.pause();
            video.nextSibling.classList.remove("playing");
        }
    }

    chooseImage = (index, remove = false) => {
        if(remove){
            this.setState({choosen : null})
        }else{
            this.setState({choosen : index})
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    getMediaBox = async () => {
        let post = this.props.post ? true : false;
        let user = this.props.userToSend ? this.props.userToSend : null
        await axios.get('/api/v2/stored/media',{
            params : {
                post : post,
                user : user
            }
        }).then(async (resp) => {
            let mediaBox = resp.data;
            this.setState({mediaBox : mediaBox})
        }).catch(err => {

        })
    }

    sendTo = async(e, user, index) => {
        let sendTo = [...this.state.sendTo];
        let data = [...this.state.userSendMediaBox];
        if(!data[index].checked){
            sendTo.push(user);
            data[index].checked = true
        }
        else{
            let pos = sendTo.indexOf(user);
            if(pos >= 0){
                sendTo.splice(pos, 1)
            }
            data[index].checked = false
        }
        await this.setState({sendTo : sendTo, userSendMediaBox : data});
    }
    sendToAll = async(e) => {
        let data = [...this.state.userSendMediaBox];
        let change = !this.state.sendToAll
        await this.setState({sendToAll : change})
        for (let check of data) {
            check.checked = change;
        }
        if(change){
            await this.setRecieversUsers();
            await this.setState({ userSendMediaBox : data})
        }
        else{
            await this.setState({sendTo : [], userSendMediaBox : data });
        }

    }

    sendToRecievers = async (e) => {
        let sendTo = [...this.state.sendTo];
        let message = this.state.message;
        let media = this.state.idMediaBox;
        //console.log(sendTo, message, media)
        if(sendTo.length > 0){
            this.setState({sending : true});
            await axios.post('/api/v2/stored/media/send',{media : media, users : sendTo, message: Buffer.from(message).toString('base64')}).then( async (res) => {
                if(res.data.Success){
                    let users = res.data.users;
                    let messageSocket = res.data.messageSocket;
                    await new Promise.map( users, async (el, index) => {
                        messageSocket['messages'] = message;
                        messageSocket["id_user"] = el.user;
                        messageSocket["index"] = el.msg;
                        messageSocket["privateMessage"] = el.msg;
                        this.props.socket.emit('SEND_MESSAGE', el.username, messageSocket);
                    }).then(() => {
                        this.setState({sending : false, success : this.props.dictionary.mediaboxSent});
                        return true;
                    })

                }
                else{
                    this.setState({error : this.props.dictionary.mediaboxWrong})
                }
            })
        }
        else{
            this.setState({error : this.props.dictionary.mediaboxNotUser})
        }
    }

    drawMediaBox(media) {
        let config = this.props.config;
        let dictionary = this.props.dictionary;
        let choosen = this.state.choosen;
        return media.map( (el, index) => {
            let isChoosen = (choosen === index) ? true : false;
            return(
                <MediaBoxElement
                    media={el}
                    index={index}
                    key={index}
                    config={config}
                    dictionary={dictionary}
                    playVideo={this.playVideo}
                    choose={this.chooseImage}
                    isChoosen={isChoosen}
                />
            )
        })
    }

    drawOptions(){
        let options = [...this.state.userSendMediaBox];
        return options.map( (op, i) => {
            return(
                <li key={i}>
                    <Checkbox
                        className='checkboxUserMediaBox'
                        checked={op.checked}
                        onChange={(e) => this.sendTo(e, op.user, i)}
                        id={"user_" + op.user}
                        label={op.username}
                        value={op.user}
                    />
                </li>
            );
        });
    }

    drawSender() {
        return(
            <div className="modal-sender">
                <p>{this.props.dictionary.selectUsrToSnd}</p>
                <textarea
                    name="messageMediaBox"
                    placeholder={this.props.dictionary.write}
                    value={this.state.message}
                    onChange={this.handleTextarea}
                ></textarea>
                <ul>
                    <li>
                        <Checkbox
                            name='checkall'
                            checked={this.state.sendToAll}
                            id="checkall"
                            onChange={(e) => this.sendToAll(e)}
                            label={this.props.dictionary.selectAll}
                            value="0"
                        />
                    </li>
                    {this.drawOptions()}
                </ul>
                <p>{this.state.error}</p>
            </div>
        )
    }

    render(){
        let config = this.props.config;
        let dictionary = this.props.dictionary;
        let media = this.state.mediaBox;
        return(
            <Modal
                id="MediaBoxSender"
                head={<h4>Media Box</h4>}
                config={this.props.config}
                footer={
                    <div className="actions">
                        <ul>
                            {this.state.step > 0 && !this.state.success && !this.state.sending ?
                                <li>
                                    <button
                                        style={{
                                            backgroundColor: config.color.secondary,
                                            color: config.color.secondaryText
                                        }}
                                        onClick={(e) => this.prevStep()}
                                    >
                                        {dictionary.back}
                                    </button>
                                </li>
                                : null
                            }
                            <li>
                                <button
                                    style={{
                                        backgroundColor: config.color.secondary,
                                        color: config.color.secondaryText
                                    }}
                                    onClick={(this.state.step === 0) ?
                                        (e) => this.nextStep()
                                        :
                                        this.state.success ?
                                            (e) => this.resetSender()
                                            :
                                            (e) => this.sendToRecievers()
                                    }
                                >
                                    {this.state.success ?
                                        dictionary.back
                                        :
                                        dictionary.send
                                    }
                                </button>
                            </li>
                        </ul>
                    </div>

                }
                open={this.props.showMediaBoxSender}
                closeAction={this.closeSender}
            >
                {this.state.loading ||this.state.sending ?
                    <div className="modal-sender">
                        <Cube background="transparent !important" color={this.props.config.color.secondary}/>
                    </div>
                        :
                        (this.state.step === 0) ?
                            <div>
                                <p>{this.state.error}</p>
                                {this.drawMediaBox(media)}
                            </div>
                            :
                            this.state.success ?
                                this.state.success
                                :
                                this.drawSender()
                }
            </Modal>
        )
    }
}

export default MediaBoxSender;
