import React, {Component} from 'react';
import axios from "axios";
import AudioRecorder from 'audio-recorder-polyfill';
import Icon from "react-ionicons";
import { Preloader, Oval } from 'react-preloader-icon';
import Promise from "bluebird";
import cookie from "js-cookie";
import ChatTitle from "./ChatTitle";
import ChatSender from "./ChatSender";
import ChatGlobe from "./ChatGlobe";
import images from '../../Images';
import GetCoins from "../../GetCoins";
import DeleteModal from "./DeleteModal";
import MediaViewer from "../../MediaViewer";
import ModalConfirmMedia from "./ChatSender/ModalConfirmMedia";
import ModalConfirmBroadcast from "./ChatSender/ModalConfirmBroadcast";
import UploadFiles from "../../UploadFiles/UploadFiles";
import Toolboox from "../../Toolbox/Toolbox";
import MarkAsResolve from './MarkAsResolve';

/**
 * @prop obj config, dictionary, toElevate, modelData, userData
 * @prop bool isSafari, isIOS
 * @prop int permission
 */
class Chat extends Component {
    constructor(props) {
        super(props);

        if (this.props.permission === undefined || this.props.permission === null)
            throw console.error("Permission undefinded at chat constructor");

        this.inputFile = React.createRef();

        this.state = {
            loading: true,
            uriMessages: this.props.permission === 1
                ? "/api/v2/getModelMessages"
                : "/api/v2/getMessage",
            messages: [],
            messagesContent: <li key={'void'}>&nbsp;</li>,
            offsetMessages: 15,
            isModel: this.props.permission === 1,
            showModalDonate: false,
            modalBuy: false,
            modalNotAvailable: false,
            coinsToDonate: 10,
            doDonation: false,
            coins: 0,
            files: [],
            filesUploading: 0,
            saveAudio: true,
            audioFile: null,
            recording: false,
            startTimer: new Date(),
            canSellCoins: false,
            showModule: false,
            idUser: null,
            typeMultimediaToBuy: null,
            notAvailable: false,
            buyCoins: false,
            totalFiles: 0,
            filesToUpload: 0,
            processingFiles: [],
            uploadingPost: false,
            requireMedia: false,
            menuItems: [],
            idMediaBox: "MediaboxListMessages",
            mediaAnswering: null,
            mediaBoxHandler: true,
            scrollPos: 0,
            runningTimes: 0,
            openDeleteModal: false,
            messageToDelete: null,
            limitMediaBox: false,
            openViewer: false,
            mediaViewerItems: [],
            mediaViewerItemOnly: [],
            mediaViewerItemDefault: 0,
            drawingCanvas: false,
            showDownBtn: false,
            awaitingMessages: 0,
            loadMediaBox : false,
            pricesmodal : [],
            sendMediaMessageUser : null,
            messageBroadcast: null,
            confirmMediaModalOpen : false,
            confirmBroadcastModalOpen : false,
            fileAccepted: 'image/jpg, image/png, video/*',
            openDeleteMessageResolveModal: false,
            messageToMarkResolved: null
        };

        this.recorder = null;
        this.tries = 0;

        this.intervalAwaiting = null;

        this.axiosInstance = axios.create({
            baseURL: 'https://socialtechapps.com/',
            //baseURL: 'https://localhost:5000',
            timeout: 60000
        });
        this.axiosMedia = this.axiosMedia.bind(this);
        this.chatContainer = React.createRef();
        this.globeToScroll = React.createRef();
        this.mediaBox = React.createRef();
        this.onScrollListener = this.onScrollListener.bind(this);
        this.modalBuy = this.modalBuy.bind(this);
        this.coinstHandler = this.coinstHandler.bind(this);
        this.doDonation = this.doDonation.bind(this);
        this.changeCoinsHandler = this.changeCoinsHandler.bind(this);
        this.modalNotAvailable = this.modalNotAvailable.bind(this);
        this.toggleViewer = this.toggleViewer.bind(this);
        this.scrollToEnd = this.scrollToEnd.bind(this);
        this.markMessageAsResolve = this.markMessageAsResolve.bind(this);
        this.sendMessage = this.sendMessage.bind(this);

        props.socket.on("RECEIVE_MESSAGE", (socketId, data) => {
            let offsetMessages = this.state.offsetMessages;
            this.setState({offsetMessages: offsetMessages + 1});
            this.addMessage(data);
        });

        props.socket.on('RECEIVE_MESSAGE_DELETED', (socketId, data) => {
            if (this.props.permission !== 1 && !data.idMessage && !data.answerMessage){
                this.deleteMessageRecieve(data);
            }
            else if (data && data.idMessage && data.answerMessage){
                this.answerMediaRequestRecieve(data.idMessage);
            }
        });

    }

    componentDidMount() {
        this.intervalAwaiting = setInterval(() => {
            this.getMessagesAwaiting()
        }, 5000);

        if (this.props.isIOS)
            window.MediaRecorder = AudioRecorder;

        this.getMessages();
        this.hasCodeMedia();
        this.getUser();
        this.getPricesModal()
        this.axiosInstance.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                if (this.tries < 500) {
                    this.tries++;
                    return this.axiosInstance.request(error.config);
                } else {
                    this.tries = 0;
                    const formData = error.config.data;
                    const msg = formData.get("message");
                    const messages = [...this.state.messages];
                    messages.map(async (el, index) => {
                        if (el.index === parseInt(msg)) {
                            messages[index]["pendingFiles"] = [...this.state.images];
                            if (await this.disableMessageSent(msg)) {
                                messages[index]["error"] = 1;
                                messages[index]["procesando"] = 1;
                            }
                        }
                    });
                }
                return false;
            }
        );
    }
    componentWillUnmount() {
        if(this.intervalAwaiting) {
            clearInterval(this.intervalAwaiting);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.intervalAwaiting && prevState.awaitingMessages){
            this.intervalAwaiting = setInterval(() => {
                this.getMessagesAwaiting()
            }, 5000);
        }
    }

    disableMessageSent = async (msg) => {
        return await axios.post("/api/v2/message/disableMessage/", {message: msg})
            .then(async (res) => {
                return !!res.data.Success;
            })
            .catch(err => {
                console.log(err);
                return false
            });
    };

    /**
     * if toggleViewer close disappear the <Mediaviwer />
     * and the item select return an event instead a integer
     * @param itemSelected number|event
     */
    toggleViewer(itemSelected = 0) {

        this.setState({
            openViewer: !this.state.openViewer,
            // For carousel mediaViewerItemDefault: itemSelected,
            mediaViewerItemOnly: Number.isInteger(itemSelected)
                ? [
                    this.state.mediaViewerItems[itemSelected]
                ]
                : []
        });
    }

    acceptTypes = (types) => {
        this.setState({
            fileAccepted : types
        })
    }

    triggerDeleteModal = (msg = null, model = null) => {
        if (msg) {
            this.setState({messageToDelete: {msg: msg, model: model}, openDeleteModal: true})
        } else {
            this.setState({messageToDelete: null, openDeleteModal: false})
        }
    }

    triggerMarkMessageAsResolve = (msg = null, model) =>{
        if (msg) {
            this.setState({ messageToMarkResolved: { msg: msg, model: model }, openDeleteMessageResolveModal: true });
        }
        else{
            this.setState({ messageToMarkResolved: null, openDeleteMessageResolveModal: false });
        }
    }

    markMessageAsResolve = async (idMessage) => {
        // eslint-disable-next-line
        if (idMessage){
            const messageSocket = {
                username: cookie.get('username'),
                token: cookie.get('token'),
                massive: false,
                message: {
                    idMessage,
                    answerMessage: true
                }
            };
            this.props.socket.emit('DELETE_MESSAGE', this.props.usernameFrom, messageSocket);
        }
    }

    deleteMessage = async (msg, send = false) => {
        let offsetMessages = this.state.offsetMessages;
        let messages = this.state.messages;
        let toDelete = null;
        // eslint-disable-next-line
        await messages.map((item, index) => {
            if (item.privateMessage === msg) {
                toDelete = index;
            }
        });
        if (toDelete !== null) {
            const messageSocket = {
                username: cookie.get('username'),
                token: cookie.get('token'),
                massive: false,
                message: msg
            };
            if (send) {
                this.props.socket.emit('DELETE_MESSAGE', this.props.usernameFrom, messageSocket);
            }

            messages.splice(toDelete, 1);
            this.setState({offsetMessages: (offsetMessages - 1), messages: messages});
            this.createContent(messages, false).catch(err => console.log(err));
        }
    }

    answerMediaRequestRecieve = (msg) =>{
        let messages = this.state.messages;
        messages.find((item) => {
            if (item.privateMessage === msg) {
                item.media_sended = 1;
                this.setState({ messages: messages });
                this.createContent(messages, false).catch(err => console.log(err));
                return item;
            }
        });
    }

    deleteMessageRecieve = async (msg, objectMessage={}) => {
        let offsetMessages = this.state.offsetMessages;
        let messages = this.state.messages;
        let toDelete = null;
        // eslint-disable-next-line array-callback-return
        await messages.find((item, index) => {
            if (item.privateMessage === msg) {
                toDelete = index;
                return item;
            }
        });
        if (toDelete !== null) {
            messages.splice(toDelete, 1);
            this.createContent(messages, false).catch(err => console.log(err));
            this.setState({ offsetMessages: (offsetMessages - 1), messages: messages });
        }
    };

    getMessagesAwaiting = () => {
        if (this.state.messages.length > 0 && this.state.awaitingMessages > 0) {
            let messages = [...this.state.messages]
            this.createContent(messages, false).catch(err => console.log(err));
        }
        else if(this.intervalAwaiting) {
            clearInterval(this.intervalAwaiting);
            this.intervalAwaiting=null;
        }
    }

    addMessage = async (data) => {
        if (this.state.isModel) {
            if (this.props.usernameFrom === data.userSended) {
                let messages = [...this.state.messages, data]
                this.setState({messages: messages});
                await this.createContent(messages, false);
                if (data.mediaAnswering) {
                    this.setAnsweredMessage(data.mediaAnswering);
                }
                this.scrollToEnd();
            }
        } else {
            let messages = [...this.state.messages, data]
            this.setState({messages: messages});
            await this.createContent(messages, false);
            if (data.mediaAnswering) {
                this.setAnsweredMessage(data.mediaAnswering);
            }
            this.scrollToEnd();
        }
    }

    closeNotAvailable = async () => {
        this.setState({notAvailable: false})
    }

    closeBuyCoins = async () => {
        this.setState({buyCoins: false})
    }

    openBuyCoins = async () => {
        this.setState({buyCoins: true})
    }

    openRequireMedia = async (e) => {
        e.preventDefault()
        this.setState({requireMedia: true})
    }

    closeRequireMedia = async () => {
        this.setState({requireMedia: false})
    }

    openMediaBox = async (e) => {
        e.preventDefault();
        await this.setState({loadMediaBox : true})
        if (document.getElementById(this.state.idMediaBox)) {
            setTimeout(() => {
                document
                .getElementById(this.state.idMediaBox)
                .classList.add("open");
            }, 350);
        }
    };

    getPack = async (messageKey, price) => {
        this.setState({
            messageBroadcast : {
                id: messageKey,
                price : price
            },
            confirmBroadcastModalOpen : true
        })
    };

    getBroadcast = async (id) => {

        await axios.post('/api/v2/spend/credit/broadcast', {
            message: id
        }).then((resp) => {
            if (resp.data.valid) {
                this.setState({
                    messageBroadcast : null
                })
                this.getMessages()
            } else {
                this.setState({
                    buyCoins: true,
                    messageBroadcast : null
                });
            }
        });
    }

    startRecording = async (e) => {
        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices
                .getUserMedia({audio: true})
                .then(async (stream) => {
                    let options = {
                        mimeType: "audio/webm",
                    };
                    if (this.props.isIOS) {
                        this.recorder = new MediaRecorder(stream);
                    } else {
                        this.recorder = new MediaRecorder(stream, options);
                    }
                    this.recorder.addEventListener(
                        "dataavailable",
                        async (e) => {
                            if (this.state.saveAudio) {
                                let audio = {file: e.data, progress: 0}
                                await this.setState({
                                    audio: audio,
                                });
                                await this.sendMessage("", audio);
                            }
                            this.setState({saveAudio: true});
                        }
                    );
                    this.recorder.start();
                    this.setState({recording: true, startTimer: new Date()});
                })
                .catch((e) => console.log(e));
        }
    };

    stopRecording = async () => {
        this.recorder.stop();
        this.recorder.stream.getTracks()[0].stop();
        await this.setState({recording: false});
    };

    cancelRecording = async () => {
        this.recorder.stop();
        this.recorder.stream.getTracks()[0].stop();
        this.setState({saveAudio: false, recording: false, mediaAnswering: null, limitMediaBox: false});
    }



    fileChangedHandler = async (e) => {

        const files = e.target.files;
        this.setState({ filesUploading: files.length });
        let currFiles = await new Promise.map(files, async (file, i) => {
            let typeFormat = file ? images.validateFormat(file.type) : '';
            if (typeFormat === 'video' || typeFormat === 'image') {
                if (file.type.match('image/jpeg.*')) {
                    let blob = await images.rotateImage(file, i);
                    return { file: blob, type: typeFormat, method: "upload", progress: 0 };
                } else {
                    return { file: file, type: typeFormat, method: "upload", progress: 0 };
                }
            }
        }).then(async (res) => {
            return await res;
        });
        if(!this.props.isIOS && !Toolboox.isSafari()){
            let list = new DataTransfer();
            let file = new File(["content"], "filename.jpg");
            list.items.add(file);

            let myFileList = list.files;
            this.inputFile.current.files = myFileList;
        }
        this.setState({
            files: currFiles
        });
    }

    getChoosen = async (arr) => {
        let files = [...this.state.files];
        let newFiles = files.concat(arr);
        this.setState({files: newFiles});
    };

    removeFile = async (index) => {
        let files = [...this.state.files];
        files.splice(index, 1);
        if (files.length === 0) {
            this.setState({mediaAnswering: null, limitMediaBox: false})
        }
        this.setState({files: files})
    }

    triggerFileInput(e) {
        e.preventDefault();
        this.inputFile.current.click();
    }

    hasCodeMedia = async () => {
        const canSellCoins = await axios.get('/api/v2/model/have/code/coin')
            .then((res) => {
                return res.data.haveCoin;
            });
        if (canSellCoins) {
            await axios.get('/api/v2/model/have/module/price')
                .then((res) => {
                    if (res.data) {
                        let showModule = {};
                        res.data.map((item, index) => {
                            return showModule[item.module_name] = 1;
                        })
                        this.setState({
                            canSellCoins: true,
                            showModule: showModule
                        });
                    } else {
                        this.setState({
                            canSellCoins: true,
                            showModule: false
                        });
                    }
                    this.getMenu();
                })
        } else {
            this.setState({
                canSellCoins: false,
                showModule: false
            });
            this.getMenu();
        }

    };

    getMenu = async () => {
        let menuItems = [];
        if (this.state.isModel) {
            menuItems = [
                [
                    "",
                    "#",
                    "",
                    "#000",
                    (e) => this.openMediaBox(e),
                    "MediaboxIcon",
                    "17px",
                ],
                ["", "#", "md-camera", "#000", (e) => this.triggerFileInput(e)],
                ["", "#", "md-mic", "#000", (e) => this.startRecording(e)],
            ];
        } else {
            if (this.state.showModule["media-messages"]) {
                menuItems.push([
                    "",
                    "#",
                    "md-camera",
                    "#000",
                    (e) => this.triggerFileInput(e)
                ]);
                menuItems.push([
                    "",
                    "#",
                    "md-mic",
                    "#000",
                    (e) => this.startRecording(e),
                ]);
            }
        }
        this.setState({menuItems: menuItems})
    }

    getUser = async () => {
        const usuario = await axios.post("/api/v2/userInfo").then((res) => {
            this.setState({idUser: res.data.userId});
        });
        return usuario;
    };

    setRequestMedia = (type) => {
        this.setState({typeMultimediaToBuy: type})
    }

    sendRequireMedia = async () => {
        let type = this.state.typeMultimediaToBuy;
        let iconBlock;
        switch (type) {
            case 3:
                iconBlock = '<i class="material-icons">mic</i>';
                break;
            case 4:
                iconBlock = '<i class="material-icons">image</i>';
                break;
            case 5:
                iconBlock = '<i class="material-icons">videocam</i>';
                break;
            default:
                iconBlock = '';

        }
        const messageData = `<span> ${iconBlock} </span>`;
        this.closeRequireMedia()
        this.sendMessage(messageData, null, [], true);

    }

    setAnsweredMessage = async (mediaAnswered) => {
        let messages = this.state.messages;
        // eslint-disable-next-line
        messages.map((el) => {
            if (el.privateMessage === mediaAnswered || el.index === mediaAnswered) {
                el["media_sended"] = true
            }
        })
    }

    answerMediaRequest = async (e, target, media) => {
        e.preventDefault();
        await this.setState({
            mediaAnswering: target,
            limitMediaBox: (media === 4) ? "img" : (media === 5) ? "video" : false
        });
        if (media === 3) {
            this.startRecording(e);
        } else {
            let types = media === 4 ? 'image/jpg, image/png' : 'video/*'
            this.acceptTypes(types)
            document.getElementById("openSender").click();
            setTimeout( () => {
                this.acceptTypes('image/jpg, image/png, video/*');
            }, 5000)
        }
        window.scrollTo(0,document.body.scrollHeight);
    }

    sendMessage = (message, audio = null, files = [], request = false) => {
        return new Promise( async resolve => {
            if (message === '' && audio === null && files.length === 0)
                return resolve(false);

            let media = [];
            if (audio)
                media.push(audio)
            else
                media = [...files];

            const type = (this.state.typeMultimediaToBuy)
                ? this.state.typeMultimediaToBuy
                : null;
            const processingMedia = (media.length) ? 1 : 0;
            const getTime = new Date();
            const isModel = this.state.isModel;
            const post = (this.props.post) ? this.props.post : null;
            const userId = (this.props.userFrom) ? this.props.userFrom : this.state.idUser;
            const objMessage = {
                mensaje: Buffer.from(message).toString("base64"),
                isUser: isModel ? 0 : 1,
                isModel: isModel ? 1 : 0,
                idUser: userId,
                processingMedia: 0,
                moduleId: type,
                post: post
            };
            const messageToSend = {
                created_time: getTime,
                messages: message,
                model_message: isModel ? 1 : 0,
                modeloname: this.props.config.nameModel,
                user_message: isModel ? 0 : 1,
                username: cookie.get("username"),
                token: cookie.get("token"),
                id_user: userId,
                media: [],
                hasMedia: false,
                index: null,
                is_broadcast: 0,
                price: null,
                pagado: null,
                userSended: (this.props.usernameFrom) ? this.props.usernameFrom : cookie.get("username"),
                processingMedia: processingMedia,
                media_requested: type,
                procesando: 0,
                error: 0,
                privateMessage: null,
                mediaAnswering: null,
                post: post
            };
            if (request) {
                await this.sendMediaRequest(objMessage, messageToSend);
            } else if (isModel) {
                await this.sendModelMessage(objMessage, messageToSend, media);
            } else {
                if(media.length){
                    this.setState({
                        confirmMessage : objMessage,
                        confirmMessageSocket : messageToSend,
                        confirmMessageMedia : media,
                        confirmMediaModalOpen : true
                    })
                }
                else{
                    await this.sendUserMessage(objMessage, messageToSend, media);
                }

            }
            this.props.toElevate.unsetPost();
            return resolve(true);
        });

    };

    triggerConfirmModalMedia = () => {
        this.setState({
            confirmMediaModalOpen : !this.state.confirmMediaModalOpen
        })
    }

    triggerConfirmBroadcastMedia = () => {
        this.setState({
            confirmBroadcastModalOpen : !this.state.confirmBroadcastModalOpen
        })
    }

    sendMediaRequest = async (insert, socket) => {
        await axios.post('/api/v2/req/message/multimedia', insert)
            .then(async (resp) => {
                if (resp.data.valid) {
                    socket["privateMessage"] = resp.data.msg;
                    this.props.socket.emit('SEND_MESSAGE', this.props.modelData.modeluser, socket);
                    this.setState({typeMultimediaToBuy : null})
                } else {
                    if (resp.data.error === "No credit")
                        this.setState({buyCoins: true})
                    this.setState({images: [], audio: null, typeMultimediaToBuy : null})
                }
            });
    }

    sendModelMessage = async (insert, socket, media) => {
        return new Promise( async resolve => {
            const lengthMedia = media.length;
            if (lengthMedia === 0) {
                this.props.socket.emit(
                    "SEND_MESSAGE",
                    this.props.usernameFrom,
                    socket
                );
                await axios
                    .post("/api/v2/sendMessageModel", insert)
                    .then(async (res) => {
                        await axios.put("/api/v2/enableMessage/" + res.data.success)
                            .catch((err) => {
                                console.log(err);
                            });
                        return resolve(true);
                    })
                    .catch((err) => {
                        return resolve(false);
                    });
            } else {
                const sentMessage = await axios.post('/api/v2/sendMessageModel', insert)
                        .then(res => {
                            const sentMessage = res.data.success;
                            if(sentMessage) {
                                this.setState({uploadingPost: sentMessage});
                                return sentMessage;
                            }

                            return false
                        })
                        .catch(err => {
                            console.log(err);

                            return false
                        });
                if(sentMessage) {
                    socket.hasMedia = lengthMedia;
                    socket.index = sentMessage;
                    socket.privateMessage = sentMessage;
                    socket.procesando = 1;
                    socket.error = 0;
                    socket.mediaAnswering = this.state.mediaAnswering;
                    this.props.socket.emit('SEND_MESSAGE', this.props.usernameFrom, socket);
                    resolve(true);
                    let processingFiles = this.state.processingFiles;
                    processingFiles[sentMessage] = media;
                    this.setState({
                        totalFiles: lengthMedia,
                        filesToUpload: lengthMedia,
                        processingFiles: processingFiles,
                        awaitingMessages: lengthMedia
                    });
                    await Promise.map( media, async(item, index) => {
                        return (item.method === "mediabox")
                            ? await this.mediaBoxMedia(item.mediaBoxId, index, sentMessage)
                            : await UploadFiles.uploadFiles(item.file,{
                                index,
                                message: sentMessage,
                                type: "message",
                                totalFiles: this.state.totalFiles,
                                mediaAnswering: this.state.mediaAnswering,
                                folder: '/message/',
                            }, this.axiosMedia);
                        }
                    );

                }
                resolve(false);
            }
        });
    }

    sendUserMessage = async (insert, socket, media) => {
        return new Promise( async resolve => {
            if (media.length === 0) {
                this.props.socket.emit(
                    "SEND_MESSAGE",
                    this.props.modelData.modeluser,
                    socket
                );
                await axios
                    .post("/api/v2/sendMessageUsr", insert)
                    .then(async (res) => {
                        await axios.put("/api/v2/enableMessage/" + res.data.success);
                        return resolve(true);
                    })
                    .catch((err) => {
                        console.log(err);
                        return resolve(false);
                    });
            } else {
                await axios.post('/api/v2/spend/credit/send/media', { size: media.length })
                    .then(async (resp) => {

                        if (resp.data.valid) {
                            await axios
                                .post('/api/v2/sendMessageUsr', insert)
                                .then(async res => {
                                    let sentMessage = res.data.success;
                                    await this.setState({uploadingPost: sentMessage});
                                    socket.hasMedia = media.length;
                                    socket.index = sentMessage;
                                    socket.privateMessage = sentMessage;
                                    socket.procesando = 1;
                                    socket.error = 0;
                                    await this.setState({totalFiles: media.length, filesToUpload: media.length, awaitingMessages: media.length});
                                    this.props.socket.emit('SEND_MESSAGE', this.props.modelData.modeluser, socket);
                                    resolve(true);
                                    let processingFiles = this.state.processingFiles;
                                    processingFiles[sentMessage] = media;
                                    this.setState({processingFiles: processingFiles})

                                    await Promise.map( media, async(item, index) => {
                                        if (item.method === "mediabox") {
                                            await this.mediaBoxMedia(item.mediaBoxId, index, sentMessage);
                                        } else {
                                            /*await this.uploadFiles(item, index, sentMessage)
                                                .then(async resp => {
                                                    return true;
                                                });*/
                                            await UploadFiles.uploadFiles(item.file,{
                                                index,
                                                message: sentMessage,
                                                type: "message",
                                                totalFiles: this.state.totalFiles,
                                                mediaAnswering: this.state.mediaAnswering,
                                                folder: '/message/',
                                            }, this.axiosMedia)
                                                .then(resp=>{
                                                    return resp;
                                                })
                                                .catch(error=>{
                                                    return false;
                                                });
                                        }
                                    });
                                })
                                .catch(err => {
                                    resolve(false);
                                    //console.log(err)
                                });

                        } else {
                            if (resp.data.error === "No credit")
                                this.setState({buyCoins: true})
                            if (resp.data.error === "Precio not found")
                                this.setState({notAvailable: true})
                            this.setState({images: [], audio: null});
                            return resolve(false);
                        }
                    });
            }
        });

    }

    mediaBoxMedia = async (mediabox, index, message) => {
        return await axios.post('/api/v2/message/media/mediabox', {
            mediabox: mediabox,
            message: message,
            total: this.state.totalFiles,
            mediaAnswering: this.state.mediaAnswering
        })
            .then(resp => {
                let images = [...this.state.files];
                if (images[index]) {
                    images[index]["progress"] = 100;
                    let filesToUpload = this.state.filesToUpload;
                    filesToUpload--;
                    this.setState({filesToUpload: filesToUpload});
                    if (filesToUpload === 0) {
                        this.setState({
                            files: [],
                            audio: null,
                            uploadingPost: null,
                            enableSend: true,
                            mediaAnswering: null,
                            limitMediaBox: false,
                            mediaBoxHandler: !this.state.mediaBoxHandler
                        })
                    } else {
                        this.setState({files: images});
                    }
                }
            })
            .catch(err => {
                return false
            })
    }


    uploadFiles = async (file, index, message) => {
        const formData = new FormData();
        formData.append('message', message);
        formData.append('user', cookie.get('username'));
        formData.append('hostname',
            window.location.hostname === 'localhost'
                ? 'modelos-deploy-dot-private-social-media.appspot.com' /*myladydusha.com*/
                : window.location.hostname
        );
        if (this.state.isModel) {
            formData.append('isModel', true);
            formData.append('mediaAnswering', this.state.mediaAnswering);
        }
        formData.append('token', cookie.get('token'));
        formData.append('file', file.file);
        formData.append('numTotalImages', this.state.totalFiles);

        return await this.axiosMedia(formData, index);
    };

    axiosMedia = async (url="api/v2/message/media", formData, option) => {
        try {
            return axios.put(url, formData, {
                onUploadProgress: progressEvent => {
                    let images = [...this.state.files];
                    if (images[option.index]) {
                        let progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
                        images[option.index]["progress"] = progress;
                        let filesToUpload = this.state.filesToUpload;
                        if (progress === 100) {
                            filesToUpload--;
                            this.setState({filesToUpload: filesToUpload});
                        }
                        if (filesToUpload === 0) {

                            this.setState({
                                files: [],
                                audio: null,
                                uploadingPost: null,
                                enableSend: true,
                                mediaAnswering: null,
                                limitMediaBox: false,
                                mediaBoxHandler: !this.state.mediaBoxHandler
                            })
                        } else {
                            this.setState({files: images});
                        }
                    }
                },
                headers: {
                    'Content-Type': formData.type
                }
            }).then(async (res) => {
                if(res){
                    return true;
                }
            }).catch(async (res) => {
                return res;
            });
        } catch (e) {
            axios.post('/api/v2/error/message/admin', {
                error: e
            });
        }
    };

    retrySendMessage = async (e, message, indice) => {
        const arrFiles = [...this.state.processingFiles];
        const files = arrFiles[message];
        let messages = this.state.messages;
        let mediaAnswering = null;
        await messages.map(async (item, index) => {
            if (item.privateMessage === message) {
                messages[index]["error"] = 0;
                messages[index]["procesando"] = 1;
                if (!item.hasMedia) {
                    messages[index]["hasMedia"] = files.length;
                }
                messages[index]["index"] = item.privateMessage;
                mediaAnswering = item.mediaAnswering;
            }
            return true;
        });
        this.setState({totalFiles: files.length, mediaAnswering: mediaAnswering, filesToUpload: files.length, awaitingMessages: files.length })
        await files.map(async (el, index) => {
            if (el.method === "mediabox") {
                await this.mediaBoxMedia(el.mediaBoxId, index, message);
            } else {
               await UploadFiles.uploadFiles(el,{
                    index,
                    message,
                    type: "message",
                }, this.axiosMedia);

                /*
                this.uploadFiles(el, index, message);
                */
            }

        })
    }

    retryMessage = async (e, message) => {
        e.preventDefault();
        await axios.get("/api/v2/message/retry/" + message).then(async (res) => {
            let messages = this.state.messages;
            await messages.map(async (item, index) => {
                if (item.privateMessage === message) {
                    messages[index]["error"] = 0;
                    messages[index]["procesando"] = 1;
                    if (!item.hasMedia) {
                        messages[index]["hasMedia"] = item.media.length;
                    }
                    messages[index]["index"] = item.privateMessage;
                }
                return true;
            });
            const formData = new FormData();
            formData.append('msg', message);
            formData.append('user', cookie.get('username'));
            formData.append('hostname', window.location.hostname === 'localhost' ? 'private-social-media.appspot.com' : window.location.hostname);
            formData.append('token', cookie.get('token'));
            if (this.state.isModel) {
                formData.append("isModel", true);
            }
            return axios.post('https://socialtechapps.com/api/v2/message/retry/', formData).then(async (res) => {
                //return axios.post('http://localhost:5001/api/v2/message/media', formData).then(async (res) => {
            })
        });

    }

    coinstHandler(coinsToDonate) {
        this.setState({
            coinsToDonate: coinsToDonate,
        });
    }

    donate = async () => {
        this.setState({
            showModalDonate: !this.state.showModalDonate,
        });
    };

    modalBuy(e) {
        this.setState({
            modalBuy: e,
        });
    }

    modalNotAvailable(e) {
        this.setState({
            modalNotAvailable: e,
        });
    };

    doDonation(e) {
        this.setState({
            doDonation: e,
        });
    }

    changeCoinsHandler(coins) {
        this.setState({
            coins: coins,
        });
    }

    scrollToEnd() {
        let chatContainer = this.chatContainer.current;
        if (chatContainer)
            chatContainer.scrollTo(0, chatContainer.scrollHeight);
    }

    scrollContainer() {
        let chatContainer = this.chatContainer.current;
        let globeToScroll = this.globeToScroll.current;
        if (globeToScroll) {
            globeToScroll.scrollIntoView();
        } else {
            if (chatContainer !== null) {
                let isSafari = !!this.props.isSafari;
                let currHeight = chatContainer.scrollHeight;
                let to = currHeight !== 0
                    ? currHeight / (this.state.offsetMessages / 15)
                    : 1;
                to = (!isSafari)
                    ? (chatContainer.scrollHeight - currHeight)
                    : to;
                chatContainer.scrollTo(0, to);
            }
        }
    }

    async onScrollListener() {
        let container = this.chatContainer.current;
        let scroll = container.scrollTop;
        // this.setState({scrollPos: scroll});
        let scrollPosInvert = Math.round(((container.scrollHeight - container.offsetHeight) - container.scrollTop));
        if (scrollPosInvert >= 1 && !this.state.loading && !this.state.showDownBtn)
            this.setState({showDownBtn: true});
        if (scrollPosInvert <= 1 && !this.state.loading && this.state.showDownBtn)
            this.setState({showDownBtn: false});

        if (scroll === 0 && !this.state.loading && (this.state.offsetMessages <= this.state.messages.length)) {
            this.scrollContainer();
            //container.scrollTop = container.scrollHeight
            this.setState({loading: true});
            let newOffset = this.state.offsetMessages + 15;
            this.setState({offsetMessages: newOffset});
            await this.getMessages(newOffset, false);
        }
    }

    findURL = (text) =>{
        if (!text) return "";
        const urlExpretion = /([--:\w?@%&+~#=]*\.[a-z]{2,4}\/{0,2})((?:[?&](?:\w+)=(?:\w+))+|[--:\w?@%&+~#=]+)?/gm;
        let textArray = text.split(" ");
        textArray = textArray.map(textFound => {
            let urlFound = textFound.match(urlExpretion)
            if (urlFound && urlFound.length){
                let URLParser = textFound;
                if (!textFound.match(/^https?:\/\//i) && !textFound.match(/^http?:\/\//i)) {
                    URLParser = 'http://' + textFound;
                }
                let newTextURL = '<a href="' + URLParser + '" target="_blank">' + textFound + '</a>';
                return newTextURL;
            }
            return textFound;
        });
        return textArray.join(" ");
    }

    getMessages(offset = this.state.offsetMessages) {
        this.setState({loading: true});
        let params = this.state.isModel
            ? {offset: offset, userId: this.props.userFrom}
            : {offset: offset}

        axios.post(this.state.uriMessages, params)
            .then(res => {
                let messages = [];

                if (res.data.messages && res.data.messages[0] !== undefined)
                    messages = res.data.messages;
                //For distinct response wrap TO REFACT
                if (res.data.messages === undefined && res.data.length > 0)
                    messages = res.data;

                this.setState({messages: messages});
                this.createContent(messages).catch(err => console.log(err));
            }).catch(err => {
                console.log(err);

                this.setState({loading: false});
            });
    }

    /**
     * Create the globes for chat content
     * @param messages array of objects
     * @param doScroll move the scroll to the last chat globe
     * @returns {Promise<void>}
     */
    async createContent(messages = [...this.state.messages], doScroll = true) {
        let actualMessages = messages;
        let messagesCount = (actualMessages.length - 1);
        let globeToScroll = messagesCount > 14
            ? 15
            : messagesCount;

        if ((messagesCount + 1) > 0 /*&& isPainting*/) {
            let media = [];
            let awaitingMessages = 0;
            await Promise.map(actualMessages, async (elem, index) => {

                let awaiting;
                if (!elem.urlParser){
                    elem.messages = this.findURL(elem.messages);
                    elem.urlParser = 1;
                }

                if (this.state.isModel)
                    awaiting = (elem.procesando && !elem.error)
                        || ((elem.error || elem.procesando)
                            && elem.user_message === 1)
                else
                    awaiting = (elem.procesando && !elem.error)
                        || ((elem.error || elem.procesando)
                            && elem.model_message === 1)

                if (awaiting && elem.index) {
                    awaitingMessages += 1;
                    axios.get("/api/v2/message/media/" + elem.index,
                        {
                            params: {
                                total: elem.hasMedia
                            }
                        }).then(async (res) => {
                        if (res.data.Error !== undefined) {
                            actualMessages[index]["error"] = 1;
                            actualMessages[index]["procesando"] = 1;
                        } else if (res.data.length > 0) {
                            actualMessages[index].media = res.data;
                            actualMessages[index]["procesando"] = 0;
                            actualMessages[index]["error"] = 0;
                            actualMessages[index]["hasMedia"] = false;
                        }
                    })
                }

                let canViewMedia = elem.media.length > 0
                    && (!elem.error && !elem.procesando)
                    && ((elem.is_broadcast === 1 && !!elem.pagado && elem.price !== 0)
                        || (elem.is_broadcast === 1 && elem.price === 0)
                        || elem.is_broadcast === 0
                    );

                if (canViewMedia) {
                    const auxMedia = await Promise.all(elem.media.map(async el => {
                        const mediaItem = await el;
                        if (mediaItem.audio === null)
                            return {
                                id: true,
                                image: mediaItem.img,
                                video: mediaItem.video,
                                user: elem.user_message === 1 ? (elem.id_user) : false
                            };
                    }));
                    media.push(...auxMedia.filter(elem => elem !== undefined));
                }

                //UPS, dirty code but in a future refactor try to use forwardRef
                let toScroll = (index === globeToScroll)
                    ? <b ref={this.globeToScroll}/>
                    : null;

                return <ChatGlobe
                            userData={this.props.userData}
                            toElevate={this.props.toElevate}
                            config={this.props.config}
                            data={elem}
                            toScroll={toScroll}
                            isModel={this.state.isModel}
                            userFrom={this.props.userFrom}
                            dictionary={this.props.dictionary}
                            answerMediaRequest={this.answerMediaRequest}
                            index={index}
                            key={index}
                            retryMessage={this.retryMessage}
                            retrySendMessage={this.retrySendMessage}
                            getPack={this.getPack}
                            triggerDeleteModal={this.triggerDeleteModal}
                            triggerMarkMessageAsResolve ={this.triggerMarkMessageAsResolve}
                            openDeleteModal={this.state.openDeleteModal}
                            openDeleteMessageResolveModal={this.state.openDeleteMessageResolveModal}
                            mediaPosition={media.length - 1}
                            toggleMediaViewer={this.toggleViewer}
                            fileAccepted={this.fileAccepted}
                />;
            }).then(res => {
                this.setState({
                    loading: false,
                    messagesContent: res,
                    mediaViewerItems: media,
                    awaitingMessages: awaitingMessages
                });
                //Maybe can remove with a style height if the height is fixed
                if (doScroll) {
                    setTimeout(() => {
                        this.scrollContainer();
                    }, 250);
                }
            });
        }
        else
            this.setState({loading: false});
    }

    getDataTitle() {
        let result = {
            image: null,
            name: null,
            showBackBtn: this.state.isModel,
            showDonateBtn: !this.state.isModel /*permission*/,
        };

        if (this.state.isModel) {
            result.image = this.props.userImageFrom;
            result.name = this.props.usernameFrom;
        } else {
            result.image =
                this.props.modelData && this.props.modelData.modelImage
                    ? this.props.config.storageURL +
                    "/" +
                    this.props.config.storage +
                    "/profiles/" +
                    this.props.modelData.modelImage
                    : null;
            result.name =
                this.props.modelData && this.props.modelData.modelname
                    ? this.props.modelData.modelname
                    : null;
        }

        return result;
    }

    setDrawingChatImages(isDrawing = false) {
        this.setState({drawingCanvas: !!isDrawing});
    }

    drawCanvas(size) {
        let canvas = [];
        for (let i = 0; i < size; i++) {
            canvas.push(<canvas key={i} id={"canvasToRotate" + i} className="hide"></canvas>)
        }
        return canvas;
    }

    getPricesModal = async () => {
        await axios.get('/api/v2/messages/requestPrices').then(async (res) => {
            let prices = res.data;
            prices.map( (el) => {
                if(el.id === 2){
                    this.setState({
                        sendMediaMessageUser : el.monedas
                    })
                    return el;
                }
            })
            await this.setState({pricesmodal: prices});
        })
    };

    render() {
        let dataTitle = this.getDataTitle();
        let reqMessages =
            this.state.showModule["audio-message-req"]
            || this.state.showModule["image-message-req"]
            || this.state.showModule["video-message-req"];

        const content = this.state.loading
            ? <div className="v2-chat-conversation-loader">
                <Preloader
                    use={Oval}
                    size={50}
                    strokeWidth={6}
                    strokeColor={this.props.config.color.secondary}
                    duration={1000}
                />
            </div>
            : this.state.messagesContent;

        let styleDownBtn = {
            backgroundColor: this.props.config.color.secondary
        }
        let showDownBtn = this.state.showDownBtn
            ? <button style={styleDownBtn} onClick={this.scrollToEnd} className={'v2-chat-up-btn'}>
                <Icon icon="ios-arrow-down" color={this.props.config.color.principalText}/>
            </button>
            : null;
        let mediaViewer = this.state.mediaViewerItemOnly.length > 0
            ? <MediaViewer
                open={this.state.openViewer}
                media={this.state.mediaViewerItemOnly}
                defaultOpen={0}
                /* For carousel media={this.state.mediaViewerItems}
                 defaultOpen={this.state.mediaViewerItemDefault} */
                closeAction={this.toggleViewer}
                config={this.props.config}

            />
            : null;
        return <div>
            {this.drawCanvas(this.state.filesUploading)}
            <ChatTitle config={this.props.config}
                       dictionary={this.props.dictionary}
                       isModel={this.state.isModel}
                       toElevate={this.props.toElevate}
                       name={dataTitle.name}
                       image={dataTitle.image}
                       filterConversation={this.props.filterConversation}
                       showBackBtn={dataTitle.showBackBtn}
                       showDonateBtn={dataTitle.showDonateBtn}
                       donate={this.donate}
                       modalBuy={this.state.modalBuy}
                       modalBuyHandler={this.modalBuy}
                       showModalDonate={this.state.showModalDonate}
                       coinstHandler={this.coinstHandler}
                       doDonation={this.doDonation}
                       coins={this.state.coins}
                       modalNotAvailableHandler={this.modalNotAvailable}
                       modalNotAvailable={this.state.modalNotAvailable}
                       requireMedia={this.state.requireMedia}
                       openRequireMedia={this.openRequireMedia}
                       closeRequireMedia={this.closeRequireMedia}
                       showModule={this.state.showModule}
                       canSellCoins={this.state.canSellCoins}
                       typeMultimediaToBuy={this.state.typeMultimediaToBuy}
                       sendRequireMedia={this.sendRequireMedia}
                       setRequestMedia={this.setRequestMedia}
                       pricesmodal={this.state.pricesmodal}
            />
            <ul onScroll={this.onScrollListener}
                ref={this.chatContainer}
                id={"chat-body"}
                className={"v2-chat-body"}>
                {content}
            </ul>
            {showDownBtn}
            <ChatSender config={this.props.config}
                        dictionary={this.props.dictionary}
                        toElevate={this.props.toElevate}
                        modelData={this.props.modelData}
                        userData={this.state.userData}
                        isModel={this.state.isModel}
                        userFrom={this.props.userFrom}
                        usernameFrom={this.props.usernameFrom}
                        files={this.state.files}
                        filesUploading={this.state.filesUploading}
                        saveAudio={this.state.saveAudio}
                        audioFile={this.state.audioFile}
                        recording={this.state.recording}
                        startTimer={this.state.startTimer}
                        canSellCoins={this.state.canSellCoins}
                        showModule={this.state.showModule}
                        triggerFileInput={this.triggerFileInput}
                        startRecording={this.startRecording}
                        cancelRecording={this.cancelRecording}
                        stopRecording={this.stopRecording}
                        removeFile={this.removeFile}
                        getChoosen={this.getChoosen}
                        sendMessage={this.sendMessage}
                        idUser={this.state.idUser}
                        sendRequireMedia={this.sendRequireMedia}
                        setRequestMedia={this.setRequestMedia}
                        notAvailable={this.state.notAvailable}
                        buyCoins={this.state.buyCoins}
                        closeNotAvailable={this.closeNotAvailable}
                        closeBuyCoins={this.closeBuyCoins}
                        menuItems={this.state.menuItems}
                        uploadingPost={this.state.uploadingPost}
                        openMediaBox={this.openMediaBox}
                        idMediaBox={this.state.idMediaBox}
                        mediaBoxHandler={this.state.mediaBoxHandler}
                        post={this.props.post}
                        donate={this.donate}
                        modalBuy={this.modalBuy}
                        coinsToDonate={this.state.coinsToDonate}
                        doDonationHandler={this.doDonation}
                        doDonation={this.state.doDonation}
                        changeCoinsHandler={this.changeCoinsHandler}
                        modalNotAvailableHandler={this.modalNotAvailable}
                        socket={this.props.socket}
                        limitMediaBox={this.state.limitMediaBox}
                        loadMediaBox = {this.state.loadMediaBox}
            />
            <GetCoins
                config={this.props.config}
                dictionary={this.props.dictionary}
                open={this.state.buyCoins}
                close={this.closeBuyCoins}
            />
            {(reqMessages || this.state.showModule["media-messages"] || this.state.isModel) ?
                (this.props.isMobile) ?
                    <input
                        type="file"
                        className="v2-chat-sender-input-file"
                        id="v2-chat-sender-input-file"
                        onChange={(e) => {
                            this.fileChangedHandler(e)
                        }}
                        ref={this.inputFile}
                        accept={this.state.fileAccepted}
                        hidden
                    />
                    :
                    <input
                        type="file"
                        className="v2-chat-sender-input-file"
                        id="v2-chat-sender-input-file"
                        onChange={(e) => {
                            this.fileChangedHandler(e)
                        }}
                        multiple
                        ref={this.inputFile}
                        accept={this.state.fileAccepted}
                        hidden
                    />
                : null
            }
            <DeleteModal
                config={this.props.config}
                dictionary={this.props.dictionary}
                open={this.state.openDeleteModal}
                close={this.triggerDeleteModal}
                messageToDelete={this.state.messageToDelete}
                deleteMessage={this.deleteMessage}
            />

            <MarkAsResolve
                config={this.props.config}
                dictionary={this.props.dictionary}
                open={this.state.openDeleteMessageResolveModal}
                close={this.triggerMarkMessageAsResolve}
                messageToMarkResolved={this.state.messageToMarkResolved}
                markMessageAsResolve={this.markMessageAsResolve}
            />

            <ModalConfirmMedia
                config={this.props.config}
                dictionary={this.props.dictionary}
                objMessage={this.state.confirmMessage}
                messageToSend={this.state.confirmMessageSocket}
                media={this.state.confirmMessageMedia}
                open={this.state.confirmMediaModalOpen}
                close={this.triggerConfirmModalMedia}
                sendMessageAction={this.sendUserMessage}
                coins={this.state.sendMediaMessageUser}
                totalCoins={this.state.coins}
                getCoins={this.props.toElevate.getCoins}
                openBuyCoins={this.openBuyCoins}
            />
            <ModalConfirmBroadcast
                config={this.props.config}
                dictionary={this.props.dictionary}
                open={this.state.confirmBroadcastModalOpen}
                close={this.triggerConfirmBroadcastMedia}
                getBroadcast={this.getBroadcast}
                totalCoins={this.state.coins}
                getCoins={this.props.toElevate.getCoins}
                openBuyCoins={this.openBuyCoins}
                messageBroadcast={this.state.messageBroadcast}
            />
            {mediaViewer}
        </div>;
    }
}

export default Chat;
