import React, {Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import Cropper from 'react-easy-crop';
import Ionicon from 'react-ionicons';
import cookie from 'js-cookie';
import ReactCountryFlag from "react-country-flag";
import {Oval, Preloader} from "react-preloader-icon";
import Modal from '../../Modal';
import Checkbox from "../../../components/Checkbox";
import getCroppedImg from "../../../components/Cropper/cropImage";
import PersonIcon from '../../../components/Svg/PersonIcon';
import ImageIcon from '../../../components/Svg/ImageIcon';
import PhoneIcon from '../../../components/Svg/PhoneIcon';
import './style.css';

class EditProfileModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zoom: 1,
            setZoom: 1,
            crop: {x: 0, y: 0},
            password: '',
            imageSrc: null,
            croppedArea: null,
            croppedImage: null,
            nameImputFile: null,
            croppedAreaPixels: null,
            disabled: false,
            showcrop: false,
            disabledPwd: true,
            selectedFile: '',
            permission: cookie.get('permission'),
            email: this.props.profileFeatures.email,
            alias: this.props.profileFeatures.alias,
            description: this.props.profileFeatures.description,
            checkedValue: this.props.profileFeatures.mail_notification_message,
            checkedNotifPhone: this.props.profileFeatures.phone_notification,
            modalAction: this.props.modalAction ? this.props.modalAction : null,
            phoneNumber: this.props.profileFeatures.phoneNumber,
            country: this.props.profileFeatures.countryCode ? this.props.profileFeatures.countryCode : "US",
            code: this.props.profileFeatures.phoneCode ? this.props.profileFeatures.phoneCode : "1",
            cancelError: false,
            canceled: false,
            canceling: false,
            countries: [],
            errorPhone: false,
            loading: false,
            membershipType: ""
        }
        this.numberRegex = /^[0-9]+$/;
        this.handleCheck = this.handleCheck.bind(this);
        this.handleCheckPhone = this.handleCheckPhone.bind(this);
        this.changeHandlerCode = this.changeHandlerCode.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.justNumbers = this.justNumbers.bind(this);
        this.uploadHandler = this.uploadHandler.bind(this);
        /////////////////   Crop   /////////////////////
        this.getImage = this.getImage.bind(this);
        this.onZoomChange = this.onZoomChange.bind(this);
        this.onCropChange = this.onCropChange.bind(this);
        this.onCropComplete = this.onCropComplete.bind(this);
        this.onZoomChangeBar = this.onZoomChangeBar.bind(this);
        this.showCroppedImage = this.showCroppedImage.bind(this);
    }

    componentDidMount() {
        this.setState({
            modalAction: this.props.modalAction
        });
        if (this.props.modalAction && this.props.modalAction !== "membershipCancel")
            this.getCountries();
        else {
            this.setState({
                loading: true
            });
            this.props.getPiCodes()
                .then(resp => {
                    const membershipType = resp && resp.length && resp[0].payment_processor === 2
                        ? "https://support.ccbill.com"
                        : "https://epoch.com";
                    this.setState({
                        loading: false,
                        membershipType
                    });
                });
        }
    }

    changeHandler(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    changeHandlerCode(e) {
        let values = e.target.value.split("+");
        this.setState({
            country: values[0],
            code: values[1]
        });
    }

    justNumbers(e) {
        let value = e.target.value;
        let name = e.target.name;
        if (!this.numberRegex.test(value)) {
            value = value.replace(/\D/g, '')
            this.setState({[name]: value});
        }
    }

    getCountries() {
        axios.get('/api/v2/countries').then(async (res) => {
            if (res.data.Success) {
                this.setState({
                    countries: res.data.countries
                })
            }
        }).catch(e => {
            this.setState({
                countries: []
            })
        })
    }

    drawCountries() {
        let countries = [...this.state.countries];
        return countries.map((el, index) => {
            return (
                <option key={index} value={el.code + "+" + el.phone}>+{el.phone} {el.name}</option>
            )
        })
    }

    handleCheck = async (e) => {
        await this.setState({
            checkedValue: !this.state.checkedValue
        })
    }

    handleCheckPhone = async (e) => {
        await this.setState({
            checkedNotifPhone: !this.state.checkedNotifPhone
        })
    }

    changeAlias = async () => {
        const alias = {
            alias: this.state.alias
        };
        return await axios.post('/api/v2/newAlias', alias)
            .then(async res => {
                return res.data;
            })
            .catch(err => {
                throw Error(err);
            });
    }

    changeDesc = async () => {
        const description = {
            description: this.state.description
        };
        return await axios.post('/api/v2/newDescription', description)
            .then(async res => {
                return res.data;
            })
            .catch(err => {
                throw Error(err);
            });
    }

    changeCheckbox = async (checkedValueFlag, type) => {
        const checkedValue = {
            checkedValue: checkedValueFlag,
            typeNotification: type
        };
        return await axios.post('/api/v2/MailNotifications', checkedValue)
            .then(async res => {
                return res.data;
            })
            .catch(err => {
                throw Error(err);
            });
    }

    changePhone = async (phoneModel) => {
        const phone = {
            phone: phoneModel,
            country: this.state.country,
            code: this.state.code
        };
        return await axios.post('/api/v2/newphoneNumber', phone)
            .then(async res => {
                return res.data;
            })
            .catch(err => {
                throw Error(err);
            });
    }

    uploadHandler = async () => {
        let error = false;
        var checkedValue;
        var checkedNotifPhone;
        var phonemodel = document.getElementById("input_phone");
        if (phonemodel) {
            phonemodel.classList.remove("sign-error");
        }
        this.setState({
            errorPhone: false
        });
        //Alias
        if (this.state.alias !== this.props.profileFeatures.alias) {
            await this.changeAlias();
        }

        //Description
        if (this.state.description !== this.props.profileFeatures.description) {
            await this.changeDesc();
        }

        //Checkbox mail notifications
        if (this.state.checkedValue !== this.props.profileFeatures.mail_notification_message) {
            checkedValue = this.state.checkedValue ? 1 : 0;
            await this.setStateAsync({checkedValue: checkedValue});
            await this.changeCheckbox(checkedValue, "mailNotifications");
        }

        //phoneNumber
        if ((this.state.phoneNumber !== this.props.profileFeatures.phoneNumber) || (this.state.code !== this.props.profileFeatures.phoneCode)) {
            if (this.state.phoneNumber !== null && this.state.phoneNumber.length < 3) {
                document.getElementById("input_phone").classList.add("sign-error");
                document.getElementById("input_phone").focus();
                await this.setState({errorPhone: this.props.dictionary.phoneError});
                error = true;
            } else {
                await this.changePhone(this.state.phoneNumber);
            }
        }

        if (error) return;

        //Checkbox model phone notifications
        if (this.state.checkedNotifPhone !== this.props.profileFeatures.phone_notification) {
            checkedNotifPhone = this.state.checkedNotifPhone ? 1 : 0;
            await this.setStateAsync({checkedNotifPhone: checkedNotifPhone});
            await this.changeCheckbox(checkedNotifPhone, "phoneNotifications");
        }

        //Image Profile
        if (this.state.imageSrc) {
            const croppedImage = await getCroppedImg(
                this.state.imageSrc,
                this.state.croppedAreaPixels
            );
            let file = new File([croppedImage], this.state.nameImputFile + ".png", {
                type: "image/png",
                lastModified: Date.now()
            });
            if (file && this.validateFormat(file.type)) {
                const formData = new FormData();
                formData.append("file", file, file.name);
                formData.append('username', cookie.get('username'));
                formData.append('hostname', window.location.hostname === 'localhost' ? 'myladydusha.com' : window.location.hostname);
                formData.append('token', cookie.get('token'));
                await this.setState({loading: true});
                await axios.post("https://socialtechapps.com/api/v2/changeImageProfile", formData)
                    .then((resp) => {
                        this.setState({loading: false});
                        return resp.data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
        setTimeout(() => {
            this.setState({disabled: false});
            window.location = '/profile';
        }, 300);
    }

    validateFormat(format) {
        const mediaType = format.split('/')[0];
        return mediaType === 'image';
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    getImage = async e => {
        const file = e.target.files[0];
        const name = e.target.name;
        const imageDataUrl = await readFile(e.target.files[0]);
        this.setState({
            imageSrc: imageDataUrl,
            showcrop: true,
            nameImputFile: name,
            selectedFile: file,
            zoom: 1,
            crop: {x: 0, y: 0},
            aspect: 3 / 3,
        });
    }

    onCropChange = crop => {
        this.setState({crop})
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({croppedAreaPixels})
    }

    onZoomChange = zoom => {
        this.setState({zoom})
    }

    showCroppedImage = async () => {
        const croppedImage = await getCroppedImg(
            this.state.imageSrc,
            this.state.croppedAreaPixels
        );
        const inputFile = document.getElementsByName(this.state.nameImputFile)[0];
        const img = inputFile.closest(".card-body").previousSibling;
        let file = new File([croppedImage], this.state.nameImputFile + ".png", {
            type: "image/png",
            lastModified: Date.now()
        });
        this.state.updloadedFiles[this.state.nameImputFile] = file;
        img.setAttribute("src", URL.createObjectURL(croppedImage));
        //await this.setState({showcrop : false})

    }

    onZoomChangeBar = (e, zoom) => {
        e.preventDefault();
        this.setState({zoom: e.target.value})
    }

    viewPassword() {
        this.setState({viewPass: !this.state.viewPass})
    }

    unsubscribe = async () => {
        if (this.state.canceling)
            return false

        this.setState({
            canceling: true
        });

        await axios.post('/api/v2/membership/cancel').then((res) => {
            if (res.data.Success)
                this.setState({
                    cancelError: false,
                    canceling: false,
                    canceled: true,
                    messageResponse: null
                });

            if (res.data.Error) {
                this.setState({
                    cancelError: true,
                    messageResponse: this.props.dictionary[res.data.Error] || this.props.dictionary.cantCancelSubs
                })
                setTimeout(() => {
                    this.setState({
                        canceling: false,
                        canceled: false
                    })
                }, 500)
            }
        }).catch((e) => {
            this.setState({
                cancelError: true,
                messageResponse: this.props.dictionary.cantCancelSubs
            })
            setTimeout(() => {
                this.setState({
                    canceling: false,
                    canceled: false

                })
            }, 500)
        })
    }

    drawModalEditProfile() {
        let dictionary = this.props.dictionary;
        let modalOptions = {
            editProfile: {
                button: dictionary.saveChange,
                head: dictionary.editProfile,
                action: this.uploadHandler,
                body:
                    <div>
                        <div className="input-profile">
                            <label className="profileLabel" htmlFor="input_1">
                                <PersonIcon
                                    color={"rgb(92, 92, 92)"}
                                    width={"20px"}
                                    height={"20px"}
                                />
                                Alias
                            </label>
                            <input
                                style={{color: this.props.config.color.principalText}}
                                type="text"
                                onChange={this.changeHandler}
                                className="register-inputs validate"
                                name="alias"
                                id="input_1"
                                minLength="4"
                                maxLength="40"
                                defaultValue={this.state.alias}
                                placeholder="alias"
                                validate="true"
                                disabled={this.state.disabled}>
                            </input>
                        </div>
                        {this.props.profileFeatures.permission === 1 ?
                            <div>
                                <div className="input-profile">
                                    <label className="profileLabel" htmlFor="input_3">
                                        <Ionicon
                                            icon='ios-paper'
                                            fontSize="25px"
                                            color="rgb(92, 92, 92)"
                                        />
                                        {dictionary.description}
                                    </label>
                                    <textarea
                                        style={{color: this.props.config.color.principalText}}
                                        onChange={this.changeHandler}
                                        className="register-inputs validate"
                                        name="description"
                                        id="input_3"
                                        maxLength="255"
                                        defaultValue={this.state.description}
                                        placeholder="description"
                                        validate="true"
                                        disabled={this.state.disabled}>
                                    </textarea>
                                </div>
                                <label className="profileLabel" htmlFor="input_phone">
                                    <PhoneIcon
                                        color={"rgb(92, 92, 92)"}
                                        width={"20px"}
                                        height={"20px"}
                                    />
                                    {dictionary.phone}
                                </label>
                                <div className="input-profile phone-group">
                                    <div className="select-code">
                                        <ReactCountryFlag
                                            className="emojiFlag"
                                            countryCode={this.state.country}
                                            style={{
                                                fontSize: '1.5em',
                                                lineHeight: '1.5em',
                                            }}
                                        />
                                        {" +" + this.state.code}
                                    </div>
                                    <select
                                        name="country"
                                        id="country"
                                        onChange={this.changeHandlerCode}
                                        value={this.state.country + "+" + this.state.code}
                                    >
                                        {this.drawCountries()}
                                    </select>
                                    <input
                                        onKeyUp={this.justNumbers}
                                        style={{color: this.props.config.color.principalText}}
                                        type="text"
                                        onChange={this.changeHandler}
                                        className="register-inputs validate"
                                        name="phoneNumber"
                                        id="input_phone"
                                        maxLength="15"
                                        value={this.state.phoneNumber}
                                        placeholder={dictionary.maxXVlimit}
                                    >
                                    </input>
                                    {this.state.errorPhone ?
                                        <div className="redesign-error-class">{this.state.errorPhone}</div>
                                        : null}
                                </div>
                                <div className="input-profile">
                                    <Checkbox
                                        id="checkbox-phone"
                                        name='phone_notification'
                                        defaultChecked={this.state.checkedNotifPhone === 1 ? true : false}
                                        onChange={this.handleCheckPhone}
                                        label={this.props.dictionary.notifPhone}
                                    />
                                </div>
                            </div>
                            : null}
                        <div className="input-profile">
                            <div className="changePwd-model">
                                <Link
                                    to='/changePassword'
                                    style={{
                                        color: this.props.config.color.secondary
                                    }}
                                >
                                    {this.props.dictionary.changePassword}
                                </Link>
                            </div>
                        </div>
                        <div className="input-profile">
                            <label className="imgProfileChange" htmlFor="mainImage">
                                <ImageIcon
                                    color={"rgb(92, 92, 92)"}
                                    width={"45px"}
                                    height={"35px"}
                                />
                                &nbsp; * {dictionary.changeProfilePic}
                            </label>
                            <input
                                type="file"
                                onChange={this.getImage}
                                name="file"
                                id="mainImage"
                                className="register-inputs inputSelectImgProfile"
                                disabled={this.state.disabled}
                            />
                        </div>
                        {this.state.showcrop ?
                            <div className='profileCrop'>
                                <div className='slidecontainer'>
                                    <Cropper
                                        dictionary={this.props.dictionary}
                                        image={this.state.imageSrc}
                                        crop={this.state.crop}
                                        zoom={this.state.zoom}
                                        aspect={this.state.aspect}
                                        cropShape='round'
                                        onCropChange={this.onCropChange}
                                        onCropComplete={this.onCropComplete}
                                        onZoomChange={this.onZoomChange}
                                    />
                                </div>
                                <div className="slideFix">
                                    <input type="range" min="1" max="3" step="0.1" name='zoomScroll'
                                           value={this.state.zoom} className="slider" id="myRange"
                                           onChange={(e, zoom) => this.onZoomChangeBar(e, zoom)}/>
                                </div>
                            </div>
                            : null}
                        {(this.props.profileFeatures.mail_notification_message !== false) && (this.props.profileFeatures.mail_notification_message !== undefined) && (this.props.profileFeatures.mail_notification_message !== null) ?
                            <div className="input-profile checkboxProfile">
                                <Checkbox
                                    id="checkbox-profile"
                                    name='mail_notification_message'
                                    defaultChecked={this.state.checkedValue === 1 ? true : false}
                                    onChange={this.handleCheck}
                                    label={this.props.dictionary.receiveMails}
                                />
                            </div>
                            : null}
                    </div>
            },
            membershipCancel: {
                button: dictionary.cancel,
                head: dictionary.cancelMembership,
                action: this.unsubscribe,
                body: <div>
                    {!this.state.canceled ?
                        <p>
                            {dictionary.LoseBenefits}.
                        </p>
                        :
                        null
                    }
                    {this.state.cancelError ?
                        <p className="error-cancel">
                            {this.state.messageResponse} <a
                            href={this.state.membershipType}>{this.state.membershipType}</a>
                        </p>
                        :
                        null
                    }
                    {this.state.canceled ?
                        <p className="error-cancel">
                            {dictionary.canCancelSubs} <a
                            href={this.state.membershipType}>{this.state.membershipType}</a>
                        </p>
                        :
                        null
                    }
                </div>
            }
        }
        let option = this.state.modalAction;
        let config = this.props.config;
        return (
            option ?
                <Modal
                    id={"ModalEditProfile"}
                    open={this.props.showModal}
                    closeAction={this.props.closeModalEditProfile}
                    head={<h4>{modalOptions[option].head}</h4>}
                    config={config}
                    footer={
                        <div className='center-align actions'>
                            {option === "buyCoins" && this.state.catalogCoin.length === 0 ?
                                null
                                :
                                this.state.canceled ?
                                    null
                                    :
                                    <button
                                        style={{
                                            backgroundColor: config.color.secondary,
                                            color: config.color.secondaryText,
                                            marginTop: 0,
                                            marginBottom: 20
                                        }}
                                        disabled={this.state.disabled}
                                        onClick={(e) => modalOptions[option].action(e)}
                                        className="create-account-button log-btn"
                                    >
                                        {(this.state.canceling || this.state.loading) ?
                                            <Preloader
                                                use={Oval}
                                                size={26}
                                                strokeWidth={8}
                                                strokeColor={config.color.secondaryText}
                                                duration={800}
                                            />
                                            :
                                            modalOptions[option].button
                                        }
                                    </button>
                            }
                        </div>
                    }
                >
                    {modalOptions[option].body}
                </Modal>
                :
                null
        )
    }

    render() {
        return (
            <div className="modal-EditProfile">
                {this.props.showModal ?
                    this.drawModalEditProfile()
                    : null}
            </div>

        )
    }
}

function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    })
}

export default EditProfileModal;
