import React, { Component } from "react";

class Resume extends Component {

    // constructor(props) {
    //     super(props);
    // }

    render() {
        let label = this.props.label;
        let ammount = this.props.ammount;
        let dot = this.props.dot;
        return(
            <p>
                <span
                    className="dot"
                    style={{backgroundColor: dot}}
                ></span>
                {label}
                <span>
                    ${ammount}
                </span>
            </p>
        )
    }
}

export default Resume;
